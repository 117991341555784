import React,{useEffect,useState} from 'react'
import axios from "./axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from "./Spinner.js";

export default function Redirect(props) {
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse]=useState([])
  const { t} = useTranslation();

useEffect(() => {
    (async () => {
        try {
        const userPosts = await axios.get("/api/redirect?slug="+props.match.params.id)
        setResponse(userPosts.data)
        setLoading(false)
        window.location.href = userPosts.data.data.url;

      
        } catch (err) {
          console.error(err.message);
        }
    })()
}, [])



return (
   
   <div >
  
</div>
  );
}

