import React,{useEffect,useState} from 'react'
import axios from "../axios.js";
import Spinner from "../Spinner.js";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import RegisterLP from "../RegisterLP";
import RegisterForm from "../RegisterForm";

import Popup from "reactjs-popup";
import $ from 'jquery'


export default function CourseInfo(props) {
   
   const token = localStorage.getItem('token')
   
 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
 const id = props.match.params.id;

 const query = new URLSearchParams(props.location.search);
	let utm_campaign = query.get('utm_campaign');

	if(utm_campaign!=null)
	{
		localStorage.setItem('utm_campaign', utm_campaign);
	}
	if(utm_campaign==null || utm_campaign=='')
	{
	utm_campaign=localStorage.getItem('utm_campaign');
	}

   let utm_source = query.get('utm_source');
   if(utm_source!=null)
	{
		localStorage.setItem('utm_source', utm_source);
	}
	if(utm_source==null || utm_source=='')
	{
      utm_source=localStorage.getItem('utm_source');
	}
   



  const [isLoading, setLoading] = useState(true);
  const [response, setResponse]=useState([])
  const [isAuthenticated, setAuthenticated]=useState(localStorage.getItem('token')==null ? false : true)
  

  const { t} = useTranslation();


  //$(".languageToggle").hide();
useEffect(() => {
   (async () => {
      try {
      const userPosts = await axios.get("/api/courses/"+id+"?language="+language+"&utm_source="+utm_source)
     if(userPosts.status==200)
     {
      setResponse(userPosts.data);
       setLoading(false)
      }
      else
      {
			props.history.push('/courses')
			window.location.reload();
      } 
       
      } catch (err) {
         console.error(err.message);
       }
   })()
}, [])

async function handlePick(){
   const userPosts=   await axios.post('/api/courses/enroll', {id: id,name:response.name,language:language}, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }
  })
  console.log(userPosts)
   props.history.push('/view/course/'+id)
}




if (isLoading) {
  return <Spinner />
}

return (
  <div>
			<Helmet>
        <title>Educately | Courses</title>
      </Helmet>
      <div class="cdivh">
<div class="container">
<div class="row">
   <div class="col-md-12">
      <div class="float-left">
         <ol class="breadcrumb">
            <li><a href="/">{t('navigation.home')}</a></li>
            <li><a href={"/courses"}>{t('navigation.courses')}</a></li>
            <li class="active">{response.name}</li>
         </ol>
         <p><span class="label label-important arrowed-in-right arrowed-in"><i class="fa fa-certificate"></i> INCLUDES CERTIFICATE</span></p>
      </div>
      <div class="float-right text-right" style={{"padding-top":"15px"}}>
         <a class="btn btn-social social-facebook" href={"https://www.facebook.com/sharer/sharer.php?u=https://educately.org/info/course/"+id+"&quote=I am taking "+response.name+" programme on educately.org Check it out"}  ><i class="fa fa-facebook"></i></a>
         <a class="btn btn-social social-twitter" href={"https://twitter.com/intent/tweet?text=I am taking "+response.name+" programme with @EducatelyOrg Check it out%21%20https://educately.org/info/course/"+id} ><i class="fa fa-twitter"></i></a>
         <a class="btn btn-social social-linkedin" href={"https://www.linkedin.com/sharing/share-offsite/?url=https://educately.org/info/course/"+id} ><i class="fa fa-linkedin"></i></a>
         <a class="btn btn-social social-whatsapp" data-action={"https://educately.org/info/course/"+id} href={"whatsapp://send?text=I am taking "+response.name+" programme on educately.org Check it out. https://educately.org/info/course/"+id} ><i class="fa fa-whatsapp"></i></a>
      </div>
   </div>
</div>
<div class="row">

   <div class="col-md-8">
      <h3 class="course_heading" >{response.name}</h3>
      <p>{response.shortDesc}</p>
      {response.offered_by!==""?  
      <p>{t('heading.offered_by')} <img src={response.offered_by} alt="" /> </p>
      :
      <p>{t('heading.offered_by')} <img src='/img/offered_by_default_logo.png' alt="" /> </p>
      }						
      <br />
      <div>
      <div class="col-md-4 nopadding">
            {
           isAuthenticated?<button class="btn btn-block btn-primary " onClick={handlePick}>{t('heading.launch_now')}</button> 
            :''
            }



         </div>
         
         <div class="col-md-4 nopadding">
            {
            !isAuthenticated? <Popup className="reactPopup"  modal trigger={<button class="btn btn-block btn-primary ">{t('heading.enroll_now')}</button>}>
            <p style={{"text-align":"right"}}>< a href={"/info/course/"+id}><i class="fa fa-times-circle fa-2x"></i></a></p>
            <div  class="signup-popup">
            <h2 class="signup-page-title">{t('signup.create_a_free_account')}</h2>
            <p class="divider-text"><span class="bg-white">{t('signup.alrady_have_an_account')}? 
  <a class="signup-log-in" href="/login" > {t('signup.login')} »</a></span></p>
   
            <RegisterForm path={id} history={props.history} /> 
            </div>
            </Popup>
            :''
            }
         </div>
      </div>
      <br />
      <p>
        
         <br />
      <div class="row">
         <div class="col-md-2 col2">
            <div class="subheading light">{t('heading.price') }</div>
            <p style={{"font-size":"16px","color":"#000"}}><i class="fa fa-tag"></i> {response.price}</p>
         </div>
         <div class="col-md-3 col2">
            <div class="subheading light">{t('heading.langugage') }</div>
            <p style={{"font-size":"16px","color":"#000"}}><i class="fa fa-globe"></i> {response.available_in}</p>
         </div>
         <div class="col-md-3 col2">
            <div class="subheading light">{t('heading.duration')}</div>
            <p style={{"font-size":"16px","color":"#000"}}><i class="fa fa-clock-o"></i> {response.duration}</p>
         </div>
         <div class="col-md-4 col2">
            <div class="subheading light">{t('heading.review')}</div>
            <div class="ratings" title={'Average Rating: '+response.avg_rating}>
            <p class="star-rating" data-rating={response.avg_rating}>
               {response.avg_rating>0.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
               {response.avg_rating>1.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
               {response.avg_rating>3.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
               {response.avg_rating>3.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
               {response.avg_rating>4.75?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full "></i>}
               <span style={{"font-size":"16px","color":"#000"}}> ({response.total_rating} Reviews)</span> 
            </p>
         </div>
      </div>
   </div>
   <br />
   <div class="row">
      <div class="col-md-12">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
               <a class="nav-link active tab" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{t('navigation.about_the_programme')}</a>
            </li>
            <li class="nav-item">
               <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{t('navigation.about_the_curriculum')}</a>
            </li>
            <li class="nav-item">
               <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">{t('navigation.how_it_works')}</a>
            </li>
            <li class="nav-item">
               <a class="nav-link" id="faqs-tab" data-toggle="tab" href="#faqs" role="tab" aria-controls="faqs" aria-selected="false">{t('navigation.faqs')}</a>
            </li>
         </ul>
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
               <div class="row tab-content-sec">
                  <div class="col-md-12">
                     <br />
                     <h3 class="heading2"> {response.name}</h3>
                     <div dangerouslySetInnerHTML={{__html: response.desciption}} />
                     </div>
                  </div>
               </div>
               <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div class="row tab-content-sec">
                     <div class="col-md-12">
                        <br />
                        <h3 class="heading2">{t('heading.curriculum')}</h3>
                        <p>
                        <div dangerouslySetInnerHTML={{__html: response.syllabus}} /></p> 
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                     <div class="row tab-content-sec">
                        <div class="col-md-12">
                           <br />
                           <h3 class="heading2">{t('heading.how_it_works')}</h3>
                           <hr />
                           <div dangerouslySetInnerHTML={{__html: response.how_it_works}} /> 
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="faqs" role="tabpanel" aria-labelledby="faqs-tab">
                        <div class="row tab-content-sec">
                           <div class="col-md-12">
                              <br />
                              <h3 class="heading2">{t('heading.faqs')}</h3>
                              <hr />
                              <div dangerouslySetInnerHTML={{__html: response.faqs}} /> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               </p>
            </div>
			<div class="col-md-4">
   <div class="bg-white border-shadow nopadding">
      <div data-toggle="modal" data-target="#myModal" title={response.name}>
         <div class='video-element'>
            <div class='video-background'>
               <img class="img-fluid nopadding" src={response.intro_image}  />
               <div class='video-overlay'>
                  <img 
                     class="play-button2" 
                     src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyAKCXZlcnNpb249IjEuMSIgCgl4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIAoJeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgl4PSIwIgoJeT0iMCIKCXZpZXdCb3g9IjAgMCA3MiA3MiIgCglzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA3MiA3MjsiIAoJeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgCgl3aWR0aD0iIiAKCWhlaWdodD0iIiAKPgogICAgICAgIDxwYXRoIGQ9Ik0zNiw3MiBDNTUuOSw3MiA3Miw1NS45IDcyLDM2IEM3MiwxNi4xIDU1LjksMCAzNiwwIEMxNi4xLDAgMCwxNi4xIDAsMzYgQzAsNTUuOSAxNi4xLDcyIDM2LDcyIFogTTM2LDggQzUxLjQsOCA2NCwyMC42IDY0LDM2IEM2NCw1MS40IDUxLjQsNjQgMzYsNjQgQzIwLjYsNjQgOCw1MS40IDgsMzYgQzgsMjAuNiAyMC42LDggMzYsOCBaIiBpZD0iU2hhcGUiIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDxwYXRoIGQ9Ik0yNiw1MS43IEMyNi4zLDUxLjkgMjYuNyw1MiAyNyw1MiBDMjcuMyw1MiAyNy43LDUxLjkgMjgsNTEuNyBMNTIsMzcuNyBDNTIuNiwzNy4zIDUzLDM2LjcgNTMsMzYgQzUzLDM1LjMgNTIuNiwzNC42IDUyLDM0LjMgTDI4LDIwLjMgQzI3LjQsMTkuOSAyNi42LDE5LjkgMjYsMjAuMyBDMjUuNCwyMC43IDI1LDIxLjMgMjUsMjIgTDI1LDUwIEMyNSw1MC43IDI1LjQsNTEuNCAyNiw1MS43IFoiIGlkPSJTaGFwZSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+Cjwvc3ZnPg=="
                     width="80px" 
                     />
               </div>
            </div>
         </div>
      </div>
      <br />
      <p class="c-b-pad"><strong>{t('heading.what_you_learn')}</strong></p>
      <div dangerouslySetInnerHTML={{__html: response.learning_objective}} />
         <br />
         <div class="">
            <div class="col-md-11 ">
              
            </div>
            <div class="col-md-11 ">
              
            </div>
            <div class="col-md-11 ">
               
            </div>
         </div>
         <br />
      </div>
   </div>
         </div>
      </div>
   </div>
   <div id="myModal" class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title">{response.name}</h5>
               <button type="button" class="close"  data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" id="yt-player">
               <div class="embed-responsive embed-responsive-16by9">
                  <iframe id="cartoonVideo" class="embed-responsive-item"  src={"//"+response.intro_video} allowfullscreen></iframe>
               </div>
            </div>
         </div>
      </div>
   </div>
           
           </div>
           
  );
}
