import React,{useEffect,useState} from 'react'
import axios from "./axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from "./Spinner.js";

export default function TermsOfServices() {
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse]=useState([])
  const { t} = useTranslation();

 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

useEffect(() => {
    (async () => {
        try {
        const userPosts = await axios.get("/api/static_page?p=Terms&language="+language)
        setResponse(userPosts.data)
        setLoading(false)
        } catch (err) {
          console.error(err.message);
        }
    })()
}, [])


if (isLoading) {
  return <Spinner />;
}
return (

   <div>
   <div class="page-header">
 <div class="container">
    <div class="row">
       <div class="col-md-8">
          <h1>{t('navigation.terms')}</h1>
       </div>
       <div class="col-md-4">
          <ol class="breadcrumb pull-right">
          
             <li><a href="/">{t('navigation.home')}</a></li>
                  <li class="active">{t('navigation.terms')}</li>

          </ol>
       </div>
    </div>
 </div>
</div>
<div class="content content-white">
   <div class="container">
   
   <div class="row">
      <div class="col-md-12">
         
      <div dangerouslySetInnerHTML={{__html: response.data.content}} />
      </div>
   </div>

</div>
</div>
</div>

  
  );
}
