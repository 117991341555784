import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'


class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
 
    render() {
		 const {t} = this.props
        return (
            <div>
           <footer className="main bg-dark-img">
                <section className="widgets" id="footerwidgets">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h4>{t('navigation.pfi')}</h4>
                            <p>
							{t('navigation.about_pfi_short')}
							
                          </p>
                          <h4>
                          {t('navigation.mobile_app')}
                             </h4>
                          <p>
                              

                          <a href="https://play.google.com/store/apps/details?id=com.educately.org&utm_source=website"><img src="/img/google_play.png" /></a> &nbsp; <a href="https://apps.apple.com/app/educately-app/id1604382749"><img src="/img/apple_store.png" /></a>
                          
                          </p>
                        </div>

                        <div className="col-md-5">
                           
                            <p>
                            <img src="/img/pfi_logo_yellow.png" width="130px" alt="Population Foundation of India" />
                            </p>
                            <p>{t('navigation.address')} 
                           <br /><br /><i className="fa fa-phone"></i> +91-11-43894100 
                           <br /><i className="fa fa-envelope"></i> educately@populationfoundation.in
                            </p>
                            <p>
                            <a className="btn btn-social social-facebook" href="https://www.facebook.com/educatelyorg"><i className="fa fa-facebook"></i></a>
                            <a className="btn btn-social social-youtube" href="https://www.youtube.com/channel/UCwO5FLpOqQNfdsxe5X11hPg"><i className="fa fa-youtube"></i></a>
                            <a className="btn btn-social social-twitter" href="https://twitter.com/EducatelyOrg"><i className="fa fa-twitter"></i></a>
                            <a className="btn btn-social social-instagram" href="https://www.instagram.com/educatelyorg/"><i className="fa fa-instagram"></i></a>
                             
                            </p>
                        </div>

                       
                        <div className="col-md-2 footer-qlink">
                            <h4>{t('portal.quick_links')}</h4>
                            <nav>
                                <ul>
                                    <li><a href="/courses">{t('navigation.courses')}</a></li>
                                    
									<li><a href="/discussions">{t('navigation.discussions')}</a></li>
									<li><a href="/how-it-works">{t('navigation.how_it_works')}</a></li>
                                    <li><a href="/about-us"> {t('navigation.aboutus')}</a></li>
                                    <li><a href="/media"> {t('navigation.media')}</a></li>
                                   
                                </ul>
                            </nav>
                        </div>
                        

                       
                    </div>
                </div>
                </section>
                <section className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p>&copy;{t('navigation.copyright')} 2021 <b>educately.org</b> &nbsp; | &nbsp; {t('navigation.all_rights_reserved')}</p>
                            </div>
                            <div className="col-md-6 text-right">
                           
                                    
                                   
                                    <a href="/privacy-policy">{t('navigation.privacy_policy')}</a> | &nbsp;
                                   <a href="/terms-and-condition">{t('navigation.terms_of_services')}  </a>
                               
                            </div>
                        </div>
                      
                    </div>
                </section>
				
				<div className="scroll-top-wrapper ">
  <span className="scroll-top-inner">
    <i className="fa fa-2x fa-arrow-circle-up"></i>
  </span>
</div>


<div className="alert text-center cookiealert" role="alert">

Educately.org uses anonymous cookies and various 3rd-party services to offer you a better, more personalized, browsing experience. By continuing to browse Educately.org you agree to Educately's <a href="/cookie-policy">Cookie Policy</a> and <a href="/privacy-policy">Privacy Policy</a>

    <button type="button" className="btn btn-primary btn-sm acceptcookies">
        I agree
    </button>
</div>

            </footer>
		   
		   
			</div>
        )
    }
}
export default withTranslation()(Footer);