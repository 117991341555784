import React, {Component} from 'react'
import RegisterLPState from "../partner_lp/register_lp/RegisterLPState";
import { Helmet } from 'react-helmet';
import Popup from "reactjs-popup";
import $ from "jquery";

import { withTranslation} from 'react-i18next'
import axios from "../axios.js";
class StateCourseInfoRJ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            mycourseID:false,
            myCourseInfo:[],
			courseRating:[],
			userLoggedIn: false,
			course_id:'5dbbf82d7a803502886a2592'
        };
    }


    async componentDidMount() {
		 $('.btnSignup').hide();
    localStorage.setItem('utm_campaign', 'rjedu');
	localStorage.setItem('rdir', '/info/course/'+this.state.course_id)

	  var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        axios.post('/api/checkAuth', {username: username}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            if (res.data.status)
                this.setState({
                    userLoggedIn: true,
                });
        });
		
       
        axios.get('/api/courses/'+this.state.course_id+'?language='+language, {})
                .then(res2 => {
                    this.setState({
                        data: res2.data
                    });
					
					
					axios.get('/api/courses/getcourse_rating/'+this.state.course_id, {})
								.then(res3 => {
									this.setState({
										courseRating: res3.data
									});
							
									
					
                    axios.post('/api/users/get-my-courses-info/'+this.state.course_id, {}, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                           
                        if (res.data.message!=='unauthorized.')
                            
                             this.setState({
                              myCourseInfo:res.data,
                              //  mycourseID:res.data.courses.find(course => (course.course_id === this.state.course_id))?true:false,
                                });
								
								
								 });	 			
								
                            }); 
                    
                        });
                        
                    
}

    toggleStart= (id,name) => (e) =>{

        if(localStorage.getItem('token')==null || localStorage.getItem('token').length<5)
        {
        localStorage.setItem('rdir', '/info/course/'+this.state.course_id)
        this.props.history.push('/login')
        window.location.reload();
    }

        const token = localStorage.getItem('token');
  
   var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
   axios.post('/api/courses/enroll', {id: this.state.course_id,name:name,language:language}, {
       headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
       }
   }).then(res => {
        if (res.data.message !== 'unauthorized.')
        console.log(res.data)
            this.setState({
                courseAssigned:true
            });
           this.props.history.push('/view/course/'+this.state.course_id)
    });

    }
    


    render() {
 const {t} = this.props
      
       var lblEnroll=''
       if(this.state.myCourseInfo.status)
       {
        lblEnroll=''
     
          }
       else
       {
        lblEnroll=<span class="">{this.state.data.enrollments} </span>
     
          }


        return (
          <div>
<Helmet>
   <title>{this.state.data.name +' | '+this.state.data.shortDesc}</title>
   <meta name="description" content={this.state.data.name +' is a '+this.state.data.shortDesc}/>
</Helmet>

 <div class="cdivh">
	
	  
 <div class="container">
 <div class="row">
         <div class="col-md-12">
		 <div class="float-left">
            <ol class="breadcrumb">
               <li><a href="/">{t('navigation.home')}</a></li>
               <li><a href={"/programme"}>{t('navigation.programme')}</a></li>
               <li class="active">{this.state.data.name}</li>
            </ol>
			
			</div>
        
         <div class="float-right text-right" style={{"padding-top":"15px"}}>
           <a class="btn btn-social social-facebook" href={"https://www.facebook.com/sharer/sharer.php?u=https://educately.org/info/course/"+this.state.course_id+"&quote=I am taking "+this.state.data.name+" programme on educately.org Check it out"}  ><i class="fa fa-facebook"></i></a>
            <a class="btn btn-social social-twitter" href={"https://twitter.com/intent/tweet?text=I am taking "+this.state.data.name+" programme with @EducatelyOrg Check it out%21%20https://educately.org/info/course/"+this.state.course_id} ><i class="fa fa-twitter"></i></a>
            <a class="btn btn-social social-linkedin" href={"https://www.linkedin.com/sharing/share-offsite/?url=https://educately.org/info/course/"+this.state.course_id} ><i class="fa fa-linkedin"></i></a>
            <a class="btn btn-social social-whatsapp" data-action={"https://educately.org/info/course/"+this.state.course_id} href={"whatsapp://send?text=I am taking "+this.state.data.name+" programme on educately.org Check it out. https://educately.org/info/course/"+this.state.course_id} ><i class="fa fa-whatsapp"></i></a>
         </div>
		  </div>
      </div>
	  

<div class="row">
		<div class="col-md-4 order-1 order-lg-2 ">
		<div class="bg-white border-shadow nopadding">
		 <div data-toggle="modal" data-target="#myModal" title={this.state.data.name}>
        <div class='video-element'>
          <div class='video-background'>
            <img class="img-fluid nopadding" src={this.state.data.intro_image}  />
              <div class='video-overlay'>
            <img 
			class="play-button2" 
		src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyAKCXZlcnNpb249IjEuMSIgCgl4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIAoJeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgl4PSIwIgoJeT0iMCIKCXZpZXdCb3g9IjAgMCA3MiA3MiIgCglzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA3MiA3MjsiIAoJeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgCgl3aWR0aD0iIiAKCWhlaWdodD0iIiAKPgogICAgICAgIDxwYXRoIGQ9Ik0zNiw3MiBDNTUuOSw3MiA3Miw1NS45IDcyLDM2IEM3MiwxNi4xIDU1LjksMCAzNiwwIEMxNi4xLDAgMCwxNi4xIDAsMzYgQzAsNTUuOSAxNi4xLDcyIDM2LDcyIFogTTM2LDggQzUxLjQsOCA2NCwyMC42IDY0LDM2IEM2NCw1MS40IDUxLjQsNjQgMzYsNjQgQzIwLjYsNjQgOCw1MS40IDgsMzYgQzgsMjAuNiAyMC42LDggMzYsOCBaIiBpZD0iU2hhcGUiIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDxwYXRoIGQ9Ik0yNiw1MS43IEMyNi4zLDUxLjkgMjYuNyw1MiAyNyw1MiBDMjcuMyw1MiAyNy43LDUxLjkgMjgsNTEuNyBMNTIsMzcuNyBDNTIuNiwzNy4zIDUzLDM2LjcgNTMsMzYgQzUzLDM1LjMgNTIuNiwzNC42IDUyLDM0LjMgTDI4LDIwLjMgQzI3LjQsMTkuOSAyNi42LDE5LjkgMjYsMjAuMyBDMjUuNCwyMC43IDI1LDIxLjMgMjUsMjIgTDI1LDUwIEMyNSw1MC43IDI1LjQsNTEuNCAyNiw1MS43IFoiIGlkPSJTaGFwZSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+Cjwvc3ZnPg=="
			width="80px" 
			/>
          </div>
            </div>
        </div>
      </div>
            <br />
            
			 <p class="c-b-pad"><strong>{t('heading.what_you_learn')}</strong></p>
			  
			<div dangerouslySetInnerHTML={{__html: this.state.data.learning_objective}} />
			
			<br />
						
			<div class="">
<div class="col-md-11 ">
{
               this.state.myCourseInfo.status && this.state.userLoggedIn?<a class="btn btn-block btn-primary" href={"/view/course/"+this.state.course_id}><strong>{t('heading.launch_now')}</strong><span><br /><small></small></span></a> 
			   :''
               }
			   
</div>

            
			
			<div class="col-md-11 ">
               {
               !this.state.myCourseInfo.status && this.state.userLoggedIn?<button class="btn btn-block btn-primary" onClick={this.toggleStart(this.state.data._id,this.state.data.name)}>{t('heading.enroll_now')}</button> 
			   :''
               }
          </div>
			
			
			<div class="col-md-11 ">
               {
               !this.state.userLoggedIn? <Popup className="reactPopup"  modal trigger={<button class="btn btn-block btn-primary ">{t('heading.enroll_now')}</button>}><p style={{"text-align":"right"}}>< a href={"/rjedu"}><i class="fa fa-times-circle fa-2x"></i></a></p> <RegisterLPState state='Rajasthan' state_id='29' path={this.state.course_id} utm_source='rjedu' />
			   </Popup>
				:''
               }
          </div>
		  
		<br />  
		
</div>		

		</div>
		
			
		</div>
		
		
		
		
		<div class="col-md-8 order-2 order-lg-1">
		<p><span class="label label-large label-important arrowed-in-right arrowed-in"><i class="fa fa-certificate"></i> INCLUDES CERTIFICATE</span></p>
		<h3 class="course_heading" >{this.state.data.name}</h3>
            <p>{this.state.data.shortDesc}</p>
           
          <div class="row">
			<div class="col-md-6 align-right">
			 <p>{t('heading.offered_by')} <img src="img/rjedu.png"  alt="" /> </p>
			</div>
			
		</div>		  
         
									
          <br />
<div>
<div class="col-md-4 nopadding">
{
               this.state.myCourseInfo.status && this.state.userLoggedIn?<a class="btn btn-block btn-primary" href={"/view/course/"+this.state.course_id}><strong>{t('heading.launch_now')}</strong><span><br /><small></small></span></a> 
			   :''
               }
</div>

            
			
			<div class="col-md-4 nopadding">
               {
               !this.state.myCourseInfo.status && this.state.userLoggedIn?<button class="btn btn-block btn-primary " onClick={this.toggleStart(this.state.data._id,this.state.data.name)}>{t('heading.enroll_now')}</button> 
			   :''
               }
          </div>
			
			
			<div class="col-md-4 nopadding">
               {
               !this.state.userLoggedIn? <Popup className="reactPopup"  modal trigger={<button class="btn btn-block btn-primary ">{t('heading.enroll_now')}</button>}><p style={{"text-align":"right"}}>< a href={"/rjedu"}><i class="fa fa-times-circle fa-2x"></i></a></p> <RegisterLPState state='Rajasthan' state_id='29' path={this.state.course_id} utm_source='rjedu' />
			   </Popup>
				:''
               }
          </div>
		  


		  
</div>			
			
			

            <p>
			
			
			
			<br />
			<div class="row">
			
			<div class="col-md-4">
			<div class="subheading light">{t('heading.langugage') }</div>
			<p style={{"font-size":"16px","color":"#000"}}><i class="fa fa-globe"></i> {this.state.data.available_in}</p>
			
			</div>
			
			<div class="col-md-4">
			<div class="subheading light">{t('heading.duration')}</div>
			
			<p style={{"font-size":"16px","color":"#000"}}><i class="fa fa-clock-o"></i> {this.state.data.duration}</p>
			</div>
			
			<div class="col-md-4">
			<div class="subheading light">{t('heading.review')}</div>
			
									
	<div class="ratings" title={'Average Rating: '+this.state.courseRating.rating}>
    <p class="star-rating" data-rating={this.state.courseRating.rating}>
	{this.state.courseRating.rating>0.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
	{this.state.courseRating.rating>1.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
	{this.state.courseRating.rating>3.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
	{this.state.courseRating.rating>3.5?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full"></i>}
	{this.state.courseRating.rating>4.75?<i class="fa fa-star" data-prev-rating-class="fa fa-star"></i>:<i class="fa fa-star-half-full" data-prev-rating-class="fa fa-star-half-full "></i>}
        
      <span style={{"font-size":"16px","color":"#000"}}> ({this.state.courseRating.total} Reviews)</span> 
       
    </p>
</div>
									
									
									
	
			</div>
			
			
			
			</div>
			<br />
			<div class="row">
			
			<div class="col-md-12">
			
			
<ul class="nav nav-tabs" id="myTab" role="tablist">
   <li class="nav-item">
      <a class="nav-link active tab" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{t('navigation.about_the_programme')}</a>
   </li>
   <li class="nav-item">
      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{t('navigation.about_the_curriculum')}</a>
   </li>
   <li class="nav-item">
      <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">{t('navigation.how_it_works')}</a>
   </li>
   <li class="nav-item">
      <a class="nav-link" id="faqs-tab" data-toggle="tab" href="#faqs" role="tab" aria-controls="faqs" aria-selected="false">{t('navigation.faqs')}</a>
   </li>
</ul>
<div class="tab-content" id="myTabContent">
<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
   <div class="row tab-content-sec">
      <div class="col-md-12">
	  <br />
         <h3 class="heading2"> {this.state.data.name}</h3>
		
         <div dangerouslySetInnerHTML={{__html: this.state.data.desciption}} />
         </div>
         
      </div>
   </div>
   <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      <div class="row tab-content-sec">
         <div class="col-md-12">
		 <br />
            <h3 class="heading2">{t('heading.curriculum')}</h3>
          
			
			
	
            <p>
            <div dangerouslySetInnerHTML={{__html: this.state.data.syllabus}} /></p> 
            </div>
         </div>
      </div>
      <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
         <div class="row tab-content-sec">
         <div class="col-md-12">
		 <br />
            <h3 class="heading2">{t('heading.how_it_works')}</h3>
           <hr />
		 
			
            <div dangerouslySetInnerHTML={{__html: this.state.data.how_it_works}} /> 
            </div>
            </div>
         </div>
         <div class="tab-pane fade" id="faqs" role="tabpanel" aria-labelledby="faqs-tab">
            <div class="row tab-content-sec">
            <div class="col-md-12">
			 <br />
               <h3 class="heading2">{t('heading.faqs')}</h3>
               <hr />
               <div dangerouslySetInnerHTML={{__html: this.state.data.faqs}} /> 
               </div>
               </div>
            </div>
         </div>
     


 

			</div>
			
			</div>
		
            </p>
		</div>
		
		</div>
 
 </div>
 </div>
 
 
 


 <div id="myModal" class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{this.state.data.name}</h5>
                    <button type="button" class="close"  data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body" id="yt-player">                    
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe id="cartoonVideo" class="embed-responsive-item"  src={"//"+this.state.data.intro_video} allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

        )
    };
}

export default withTranslation()(StateCourseInfoRJ);