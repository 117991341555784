import React,{useEffect,useState} from 'react'
import TopicIndex from "./article/TopicIndex";
import HeaderSlider from "./home/HeaderSlider";
import EducatelyAdvantage from "./home/EducatelyAdvantage";
import EducatelyPartners from "./home/EducatelyPartners";
import Testimonials from "./home/Testimonials";
import { useTranslation } from 'react-i18next';

function Home(props) {
  const { t} = useTranslation();
    return (
      <div className="home">
	
    <HeaderSlider history={props.history}  />

   <div className="content content-bg4">
      <div className="container">
         <p className="text-center header">
            {t('navigation.core_issues')}
         </p>
         <TopicIndex path="/api/courses" />
      </div>
   </div>
   <div className="content content-yellow">
      <div className="container">
         <EducatelyAdvantage path="" />
      </div>
   </div>
   <div className="content content-bg4 ">
      <div className="container">
         <EducatelyPartners path="" />
      </div>
   </div>
   <div className="content content-light d-none1">
      <div className="container">
         <Testimonials path="" />
      </div>
   </div>
</div>
)

}

export default Home;