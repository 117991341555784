import React,{useEffect,useState} from 'react'
import { useTranslation } from 'react-i18next';
import Spinner from "../Spinner.js";
import axios from "../axios.js";
import { Helmet } from 'react-helmet';

export default function ArticleList(props) {
  const { t} = useTranslation();

  const name = props.match.params.id;
  const slug = props.match.params.slug;
  const [isLoading, setLoading] = useState(true);
  const [banners, setBanners] = useState({})
  var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

  useEffect(() => {
      (async () => {
          const banner1 = await axios.get('/api/topic/'+name+'/'+slug+'?language='+language)
          const banner2 = await axios.get('/api/topic?language='+language)
          setBanners({banner1, banner2})
          setLoading(false)
      })()
  }, [])


  if (isLoading) {
      return <Spinner />
    }



return (
<div>
<Helmet>
   <title>{ banners.banner1.data.data.title}</title>
</Helmet>
<div class="page-header">
   <div class="container">
      <div class="row">
         <div class="col-md-7">
            <h1 class="brcheading">{banners.banner1.data.data.title}</h1>
         </div>
         <div class="col-md-5">
            <ol class="breadcrumb capitalize pull-right">
               <li><a href="/">{t('navigation.home')}</a></li>
               <li><a href="/topic">{t('navigation.core_issues')}</a></li>
               <li class="active capitalize">{banners.banner1.data.message}</li>
            </ol>
         </div>
      </div>
   </div>
</div>
<div class="content content-bg4">
   <div class="container">
      <div class="row article">
         <div class="col-md-9">
          
            <div dangerouslySetInnerHTML={{__html: banners.banner1.data.data.content}} />
               <br />
            </div>
            <div class="col-md-3">
               <h5>CORE ISSUES</h5>
               <div class="tags">
                  {
                  banners.banner2.data.map((row, i) => (
                        
                     <a href={'/topic/'+row.slug}>{row.name}</a>
                     
                  
                  ))
                  }
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-9">
               <hr />
               <div class="well well-sm">
                  <p>        
                     <span class="label label-default">Credit</span>  <span dangerouslySetInnerHTML={{__html: banners.banner1.data.data.credit_note}} /> 
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
  );
};