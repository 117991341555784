import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import axios from "./axios.js";
import { toast } from 'react-toastify';
import { LoginSvc,setSession } from '../services/userService.js';
class deleteAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageContent:[]
        };
    }

    componentDidMount() {
      
}
async componentWillMount() {
	
  }

  deleteAccount = (e) => {    
    e.preventDefault();
    const token = localStorage.getItem('token');
    let email = e.target.children[0].children[0].value;
    let password = e.target.children[1].children[0].value;


    const user = {username: email, password: password}
    LoginSvc(user)
    .then(res => {
       if (res.data.token)
       {
          
       
        axios.delete('/api/users/account', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + res.data.token
            },
        data: {
          password: password
        }
            
        }).then(res => {
                 let err = ''
                 if(res.data.success)
                 {
                 toast.success(res.data.message, {
                     position: toast.POSITION.TOP_CENTER
                 });
                
                 
             }
             else
             {
                 toast.error(res.data.message, {
                     position: toast.POSITION.TOP_CENTER
                 }); 
                
             }
                 this.setState({
                     err: err
                 });
             });

             
       }
     }).catch(function (error) {
       if(error.response.status==401){
         
         toast.error('Login Failed: '+error.response.data.message, {
              position: toast.POSITION.TOP_CENTER
          });
         
        }
 })

   //e.target.reset();
}
    

render() {
    const {t} = this.props
     return (
        <div>
        <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1 class="brcheading">Delete your account</h1>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('heading.home')}</a></li>
                  <li class="active">Delete your account</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
  
   <div class="content content-white">
           
                  
           <div class="container">
            
               <div class="row category-box-row1">
               <div class="col-md-4 mx-auto">
                        <p class="text-left header">Delete your account</p>
                        <p>This action is permanent and cannot be undone! after deleting your account, you will no longer have access to the courses and its progress.</p>
                        <p>To delete your account, please enter your current password.</p>
                        <form id="resetpwd" class="requires-validation" novalidate="true" onSubmit = {this.deleteAccount}>
                        
                        <div class="form-group">
                              <input type="email" placeholder="Your registered email" required name="email" id="email" class="form-control input-md"  />
                              <div class="invalid-feedback">
                               Email is required!
                            </div>
                          </div>

                        <div class="form-group">
                              <input type="password" placeholder="Current password" required name="password1" id="password1" class="form-control input-md"  />
                              <div class="invalid-feedback">
                                Current password is required!
                            </div>
                          </div>
                          <div class="form-group text-left">
                          <button type='submit' className='btn btn-dark btn-sm'>Yes, delete my account permanently</button>
                          </div>
                          <div class="form-group">
                           </div>
                        </form>
                         </div> 

                </div>
        </div>
</div>

   
    </div>
         )
         }
         }

export default withTranslation()(deleteAccount);