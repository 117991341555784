
import React, {Component} from 'react';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';


export default class Demo extends Component {
    constructor(props,context) {
        super(props,context);
         
      
        }


     componentDidMount() {     
    }


printDocument(id){
    const input = document.getElementById('printarea');

    var file_name="Educately_Certificate_"+id+".pdf";
		  
    html2canvas(input,{
      
      logging: true, letterRendering: 1, allowTaint: true, useCORS: false,
      quality: 0.5
    } )
                .then((canvas) => {
                  const imgData = canvas.toDataURL('image/jpeg');
                  const doc = new jsPDF("landscape", "mm", "a4",true);
                  doc.scaleFactor = 1;
                  var width = doc.internal.pageSize.getWidth();
                   var height = doc.internal.pageSize.getHeight();
                  doc.addImage(imgData, 'JPEG', 3, 3);
                  //pdf.output('dataurlnewwindow');
                  doc.save(file_name);
                });
}

render() {

      return (
        <div>
        <div id="printarea">
      <p>Helllo</p>
   </div>
   <a onClick={()=>this.printDocument('e325c94e-88ca-11ed-94c8-0ac5aabe8bca')} title="Download your digital certificate" class="btn btn-social social-print"><i class="fa fa-file-pdf-o"></i></a>
   </div>
         
      )
  };
}