import React, {Component} from 'react'
import axios from "./axios.js";
export default class VerifyEmail extends Component {
constructor(props) {
super(props);
this.state = {
emailVerify:[],
email:'',
};
}
 componentDidMount() {
    let token=this.props.match.params.token
    let id=this.props.match.params.id
    axios.post('/api/users/email-verify', {token:token,id:id})
            .then(res => {
                this.setState({
                    emailVerify: res.data
                });
            }); 
}

render() {
return (
<div>
   <div class="content content-white">       
                   <h5 class="text-center">Email Verification</h5>
                   <p class={"text-center "}><i class={"fa "+this.state.emailVerify.icon+" "+this.state.emailVerify.status}></i> {this.state.emailVerify.message}</p>
<p>{this.state.email}</p>
           </div>
</div>
)
}
}