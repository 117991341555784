import React, {Component} from 'react'
import axios from "../axios.js";
import Popup from "reactjs-popup";
import CourseCertificate from './CourseCertificate'
import Modal from 'react-modal';
import { withTranslation, Trans } from 'react-i18next'

import Moment from 'moment';
class MyCourses extends Component {
constructor(props) {
super(props);
this.state = {

courses: [],
courseAssigned: false,
firstName:localStorage.getItem('firstName'),
isLoading: true
};
}

componentDidMount() {
   const token = localStorage.getItem('token');
   const username = localStorage.getItem('username');
  var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
   axios.post('/api/users/my-courses?language='+language, {username: username}, {
       headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
       }
   }).then(res => {
       
        if (res.data.message!=='unauthorized.')
      {
          console.log(res.data.success)
        if (res.data.success){
            this.setState({
              
                courses:res.data.data,
                courseAssigned:true,
                isOpen:false,
                
            });
         }
        }
        else
        {
           this.props.history.push('/login')
           window.location.reload();
        }
    });

this.state.render=true;    
this.setState({isLoading: false})
}

toggleStart= (id) => (e) =>{
    this.setState({
        isOpen: true,
        course_id:id
      });
}

closePopup = () => {
    this.setState({
      isOpen: false
    });
  }

  redirect = () => {
    this.props.history.push('/courses');
}




render() {
      const {t} = this.props
   var courses=null
    courses= <div class="col-md-6 col-md-offset-3"><h2 align="center">Hey {this.state.firstName}!</h2><br /><p align="center">Welcome to the <strong>Knowledge Hub</strong> - a digital one-stop shop for information on adolescent reproductive and sexual health also known as ARSH.</p><p align="center">Knowledge Hub offers online courses to train teachers and enablers to understand and deliver CSE to adolescents.</p><p align="center"><img src={'/desk.png'} /></p><br /><h4 align="center">Looks like you aren't
    part of any Learning Courses yet.</h4><p align="center"><button class="btn btn-theme navbar-btn btn-green  sign-up" onClick={this.redirect} ><strong>Click to see list of Courses</strong></button></p></div>
  
  if(this.state.courses.length>0)
  {
   courses = this.state.courses.map(course => {
      
      var btn=<a class="btn btn-primary " href={'/view/course/'+course.slug}> {t('portal.view')} </a>
      var status= <span class="label label-large label-light arrowed-in-right"><i class="fa fa-tachometer"></i> {course.status} 
      



      </span> 
      if(course.status=='Completed')
      {
        btn=<a class="btn btn-dark " href={'/view/course/'+course.slug}> {t('portal.view')} </a>
        status= <div><span class="label label-large label-light arrowed-in-right"><i class="fa fa-tachometer"></i> {course.status}</span></div>
    }
      return (
         
        

         

<div class="item col-xs-4 col-lg-4">
<div class="thumbnail card">

<label class="dropdown text-left " style={{"background":"#62CBD9","margin-bottom":"0px","color":"#fff"}}>
<div class="dd-button ">
  <i class="fa fa-cog"></i>
</div>

<input type="checkbox" class="dd-input" id="test" />

<ul class="dd-menu">
  <li><a href={"/course/unenroll/"+course.slug}>Unenroll</a></li>
  
</ul>

</label>

 
        
   

    <div class="caption card-body text-center">
	<img class="" width="22%" src={"/img/"+course.icon} alt="" />
        <h2 class="group card-title inner list-group-item-heading">
        {course.name}</h2>
      <p class="">{t('portal.started')} - {Moment(course.enrolled_date).format('d MMM Y')} 
      
      
<hr />
      </p>
           
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <p class="lead">
               
               {status}
                </p>
            </div>
           
            <div class="col-xs-12 col-md-6 text-right">
                {btn}
            </div>
        </div>
    </div>
</div>
</div>


   
      )
  });

}
else
{
    courses=  <div dangerouslySetInnerHTML={{__html: "<p>Not enrolled in any Programme. <a href='/programme'>View available programmes on Educately</a>.</p>"}} />
}
  
    return (
        <div>
		
		
           <div class="page-header">
              <div class="container">
                 <div class="row">
                    <div class="col-md-8">
                    
					<span style={{"font-size":"32px","padding":"0px"}}>{t('navigation.my_courses')}</span> 


                    </div>
                    <div class="col-md-4">
                       <ol class="breadcrumb pull-right">
                          <li><a href="/">{t('heading.home')}</a></li>
                          <li class="active">{t('navigation.my_courses')}</li>
                       </ol>
                    </div>
                 </div>
              </div>
           </div>
           <div class="content content-bg4">
              <div class="container">
                 <div class="row">
                 
               {courses}
                
               
                
              </div>

            
           </div>
        </div>

      

        </div>
        )
        }
        }
export default withTranslation()(MyCourses);