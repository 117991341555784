import React,{useEffect,useState} from 'react'
import axios from "./axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from "./Spinner.js";

export default function ContactUs() {
  const [isLoading, setLoading] = useState(false);
  
  const { t} = useTranslation();

 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

if (isLoading) {
  return <Spinner />;
}
return (
   <div>
   <div class="page-header">
 <div class="container">
    <div class="row">
       <div class="col-md-8">
          <h1>{t('navigation.contact')}</h1>
       </div>
       <div class="col-md-4">
          <ol class="breadcrumb pull-right">
          
             <li><a href="/">{t('navigation.home')}</a></li>
                  <li class="active">{t('navigation.contact')}</li>

          </ol>
       </div>
    </div>
 </div>
</div>
<div class="content content-white">
   <div class="container">
   
   <div class="row">
      <div class="col-md-10">
         
   <p> <img src="img/pfi_logo_yellow.png" /></p>
   <p>POPULATION FOUNDATION OF INDIA</p>
   <p>B-28, Qutab Institutional Area, Opposite to Rockland Hospital, Katwaria Sarai, New Delhi- 110016</p>
   <p><a class="btn btn-social social-facebook" href="https://www.facebook.com/educatelyorg"><i class="fa fa-facebook" aria-hidden="true"></i></a><a class="btn btn-social social-youtube" href="https://www.youtube.com/channel/UCwO5FLpOqQNfdsxe5X11hPg"><i class="fa fa-youtube" aria-hidden="true"></i></a><a class="btn btn-social social-twitter" href="https://twitter.com/EducatelyOrg"><i class="fa fa-twitter" aria-hidden="true"></i></a><a class="btn btn-social social-instagram" href="https://www.instagram.com/educatelyorg/"><i class="fa fa-instagram" aria-hidden="true"></i></a></p>

      </div>
   </div>

</div>
</div>
</div>
  );
}