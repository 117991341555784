import React, {Component} from 'react'
import { withTranslation, Trans } from 'react-i18next'
import axios from "./axios.js";
import { toast } from 'react-toastify';
class Settings extends Component {
constructor(props) {
super(props);
this.state = {

};
}
//Check if user authenticated
 componentWillMount() {

}

deleteAccount = (e) => {    
    e.preventDefault();
    const token = localStorage.getItem('token');
    let password = e.target.children[0].children[0].value;

    if(password=='')
    {
        // toast.error("Email Id and Password cant be blank", {
        //     position: toast.POSITION.TOP_CENTER
        // });
    }
    else{
    axios.delete('/api/users/account', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    data: {
      password: password
    }
        
    }).then(res => {
             let err = ''
             if(res.data.success)
             {
             toast.success(res.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
            
             
         }
         else
         {
             toast.error(res.data.message, {
                 position: toast.POSITION.TOP_CENTER
             }); 
            
         }
             this.setState({
                 err: err
             });
         });

         
        }
   //e.target.reset();
}

//Handling user log in
resetPassword = (e) => {    
    e.preventDefault();
    let password1 = e.target.children[0].children[0].value;
    let password2 = e.target.children[1].children[0].value;
    const token = localStorage.getItem('token');
   const username = localStorage.getItem('username');

    if(password1=='' || password2=='')
    {
          
    }
    else if(password1.length<5){
        toast.warning('Password cannot be less than 5 character', {
            position: toast.POSITION.TOP_CENTER
        });    
    }
    else
    {
        axios.post('/api/users/change-password', {password1:password1,password2:password2}, {
       headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
       }
   }).then(res => {
            
            let err = ''
            if(res.data.status)
            {
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else
        {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
        }
            this.setState({
                err: err
            });
        });

        document.getElementById("resetpwd").reset();
    }
}


redirect = () => {
    this.props.history.push('/login');
}

render() {
	  const {t} = this.props 
//Error messages
const errMessage = !Array.isArray(this.state.err) ? (
<div className='red-text center'>{this.state.err}</div>
)
: (this.state.err.map(err => {
return (
<div className='red-text center'>{err}</div>
)
}));
return (
<div>
   <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1 class="brcheading">{t('navigation.settings')}</h1>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('navigation.home')}</a></li>
                  <li class="active">{t('navigation.settings')}</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
   <div class="content content-white">
           
                  
               <div class="container">
                
                   <div class="row category-box-row1">
                   


    <div class="col-md-4">
    <p class="text-center header">{t('heading.change_your_password')}</p>
   

    <form id="resetpwd" class="requires-validation" novalidate="true" onSubmit = {this.resetPassword}>
                        
                        <div class="form-group">
                              <input type="password" required placeholder={t('heading.your_new_password')} name="password1" id="password1" class="form-control input-md"  />
                          
                            <div class="invalid-feedback">
                            Password is required!
                            </div>
                          </div>
   
                          <div class="form-group">
                              <input type="password" required placeholder={t('heading.your_new_password_again')} name="password2" id="password2" class="form-control input-md"  />
                          
                              <div class="invalid-feedback">
                            New password is required!
                            </div>
                          </div>
   
                        
   
                          <div class="form-group text-right">
                          <button type='submit' className='btn btn-primary'>{t('heading.submit')}</button>
                          </div>
                          <div class="form-group">
                         
                           </div>
                          
                        </form>
                        </div>                    
                        <div class="col-md-4">
                            </div>
                        <div class="col-md-4">
                        <p class="text-left header">Delete your account</p>
                        <p>This action is permanent and cannot be undone! after deleting your account, you will no longer have access to the courses and its progress.</p>
                        <p>To delete your account, please enter your current password.</p>
                        <form id="resetpwd" class="requires-validation" novalidate="true" onSubmit = {this.deleteAccount}>
                        
                        <div class="form-group">
                              <input type="password" placeholder="Current password" required name="password1" id="password1" class="form-control input-md"  />
                              <div class="invalid-feedback">
                                Current password is required!
                            </div>
                          </div>
                          <div class="form-group text-right">
                          <button type='submit' className='btn btn-dark btn-sm'>Yes, delete my account permanently</button>
                          </div>
                          <div class="form-group">
                           </div>
                        </form>
                         </div> 

                    
               </div>        
           </div>
           </div>
</div>
)
}
}
export default withTranslation()(Settings);