import { useState, useEffect } from 'react';
import axios from 'axios';

//axios.defaults.baseURL = 'https://api.educately.org/';
//axios.defaults.baseURL = 'https://educately.herokuapp.com/';
axios.defaults.baseURL = '/';

export const useAxios = (axiosParams) => {
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = async (params) => {
      try {
       const result = await axios.request(params);
       setResponse(result.data);
       } catch( error ) {
         setError(error);
       } finally {
         setLoading(false);
       }
    };

    useEffect(() => {
        fetchData(axiosParams);
    }, []);
    return { response, error, loading };
};
