import React, {Component} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';
export default class Unsubscribe extends Component {
constructor(props) {
super(props);
this.state = {
emailVerify:[],
email:'',
};
}
 componentDidMount() {
    let token=this.props.match.params.token
    let id=this.props.match.params.id
    axios.post('/api/users/unsubscribe', {token:token,id:id})
            .then(res => {
                this.setState({
                    emailVerify: res.data
                });
            }); 
}

userSubscribe = (e) => {
	
	let token=this.props.match.params.token
    let id=this.props.match.params.id
    axios.post('/api/users/resubscribe', {token:token,id:id})
            .then(res => {
                toast.success('You have successfully subscribed', {
         position: toast.POSITION.TOP_CENTER
     });
            }); 
			
	
}

render() {
return (
<div>
   <div class="content content-white">       
                   <h4 class="text-center">Unsubscribe</h4>
                   <p class={"text-center "}><i class="fa fa-check-circle"></i> You have successfully unsubscribed from list.</p>
				   <br />
				   <h3 class="text-center">You can Join US! Back</h3>
				   
				   <p class="text-center"><button onClick = {this.userSubscribe} class="btn btn-dark">click to subscribe</button></p>
				   

           </div>
</div>
)
}
}