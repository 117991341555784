import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import axios from "./axios.js";
class Hclf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageContent:[]
        };
    }

    componentDidMount() {
      
}
async componentWillMount() {
	var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')     
    axios.get("/api/static_page?p=hclf&language="+language, {}).then(res2 => {
      this.setState({
        pageContent: res2.data.data.content
      });
    });
  }
    

render() {
    const {t} = this.props
     return (
        <div>
        
 
 <br />
 <div class="row">
  <div class="col-md-2">
  </div>
 <div class="col-md-8">

<div dangerouslySetInnerHTML={{__html: this.state.pageContent}} />

	
	

 </div>
 </div>
 <br />
 
   
    </div>
         )
         }
         }

export default withTranslation()(Hclf);