import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
//import axios from 'axios'
import axios from './axios.js'

class ShortSlug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            course_id:null
        };
    }
	
	 async componentWillMount() {
		 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')       

    axios.get("/api/courses/get_id/"+this.props.match.params.id, {}).then(res2 => {
      this.setState({
        course_id: res2.data.course_id
      });
        this.props.history.push('/info/course/'+this.state.course_id)
        window.location.reload();
    });
  }
  

    componentDidMount() {
      document.title = "About Us | Educately"
}

    

render() {
  
     return (
        <div>
        
    </div>
         )
         }
         }


export default ShortSlug;