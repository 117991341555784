import React,{useEffect,useState} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import SocialLogin from "./SocialLogin";

export default function RegisterForm(props) {
  const [response, setResponse]=useState([])
 
  const { t} = useTranslation();
  
  
function handleChange(event){
   let state=event.target.value;
   let state_id=state.split('-')[0]
    loadDistricts(state_id)
 }

 function loadDistricts(state_id)
{
   axios.post("/api/getDistricts", {state_id:state_id}).then(res2 => {
    setResponse(res2.data.data);
      
    });
}
  
function userRegister(e) {
   e.preventDefault();
   var language = localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language')
   const search = window.location.search;
   const query = new URLSearchParams(search);
   let utm_campaign = query.get('utm_campaign');

   if (utm_campaign != null) {
       localStorage.setItem('utm_campaign', utm_campaign);
   }
   if (utm_campaign == null || utm_campaign == '') {
       utm_campaign = localStorage.getItem('utm_campaign');
   }

   let utm_source = query.get('utm_source');
   if (utm_source != null) {
       localStorage.setItem('utm_source', utm_source);
   }
   if (utm_source == null || utm_source == '') {
       utm_source = localStorage.getItem('utm_source');
   }


   let firstName = e.target.firstName.value;
   let lastName = e.target.lastName.value;
   let username = e.target.ctrlEmail.value.toLowerCase();
   let mobile = e.target.mobile.value;
   let profession = e.target.profession.value;
   let age = e.target.age.value;
   let gender = e.target.gender.value;
   let state_id_name = e.target.state.value;
   let state_id = state_id_name.split('-')[0];
   let state = state_id_name.split('-')[1];
   let district = e.target.district.value;
   let password = e.target.password.value;
   let confirm_password = e.target.confirm_password.value;
   if (firstName === '' || lastName === '' || username === '' || state === '' || district === '' || password === '' || confirm_password === '' || mobile === '' || age === '' || gender === '' || profession === '' ) {
       toast.error('Please fill all required fields marked by *', {
           position: toast.POSITION.TOP_CENTER
       });
   } else if (password.length < 5) {
       toast.warn('Password length cannot be less than 5 character', {
           position: toast.POSITION.TOP_CENTER
       });
   } else if (password !== confirm_password) {
       toast.warn('Password and confirm password should be the same', {
           position: toast.POSITION.TOP_CENTER
       });
   } else if (mobile.length !== 10) {
       toast.error('Please enter a valid mobile number ', {
           position: toast.POSITION.TOP_CENTER
       });
   } else {

       var rdir = '/courses'

       const user = {
           username,
           password,
           firstName,
           lastName,
           state,
           state_id,
           district,
           language,
           mobile,
           utm_campaign,
           utm_source,
           age,
           gender,
           profession
      };
      console.log(user)
       axios.post("/api/users/register", user)
           .then(res => {
               if (res.data.status) {
                   localStorage.setItem('token', res.data.token);
                   localStorage.setItem('username', res.data.username);
                   localStorage.setItem('firstName', res.data.firstName);
                   localStorage.setItem('_id', res.data._id);
                   document.getElementById("register").reset();
                   toast.success('You have successfully registered', {
                       position: toast.POSITION.TOP_CENTER
                   });


                   if (props.path!== '') {
                       axios.post('/api/users/assign-course', {
                           id: props.path,
                           name: res.data.firstName,
                           language: language
                       }, {
                           headers: {
                               'Content-Type': 'application/json',
                               'Authorization': 'Bearer ' + res.data.token
                           }
                       }).then(res => {    
                       });
                       
                       rdir = '/view/course/' + props.path
                     }


                   props.history.push(rdir)
                   window.location.reload();
               }
           })
           .catch(function(error) {
               console.log(error)
               toast.error('Registration Failed: Account already Exists ', {
                   position: toast.POSITION.TOP_CENTER
               });
           })
   }
}

return (
  <div>
  
 
   <form id="register" onSubmit = {userRegister}>
   <div>
      <div class="form-row">
         <div class="col-md-6 form-group">
       <input type="text" id="firstName" class="form-control reginput" placeholder={t('signup.first_name')+'*'} />
         </div>
         <div class="col-md-6 form-group">
        <input type="text" id="lastName" class="form-control reginput" placeholder={t('signup.last_name')+'*'} />
         </div>
      </div>
      <div class="form-row">
         <div class="col-md-6 form-group">
            <input type="email" id="ctrlEmail" class="form-control reginput" placeholder={t('signup.email')+'*'} />
         </div>
         <div class="col-md-6 form-group">
            <input type="text" id="mobile" class="form-control reginput"  maxLength="10"  placeholder={t('signup.mobile')+'*'}
               onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
            }
            }} 
            />
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-6 ">
            <select class="form-control reginput" id="state" onChange={handleChange} >
               <option value="">{t('signup.state')}*</option>
               <option value="1-Andhra Pradesh">Andhra Pradesh</option>
               <option value="2-Arunachal Pradesh">Arunachal Pradesh</option>
               <option value="3-Assam">Assam</option>
               <option value="4-Andaman & Nicobar Islands">Andaman &amp; Nicobar Islands</option>
               <option value="5-Bihar">Bihar</option>
               <option value="6-Chandigarh">Chandigarh</option>
               <option value="7-Chattisgarh">Chattisgarh</option>
               <option value="8-Dadra & Nagar Haveli">Dadra &amp; Nagar Haveli</option>
               <option value="9-Daman & Diu">Daman &amp; Diu</option>
               <option value="10-Goa">Goa</option>
               <option value="11-Gujarat">Gujarat</option>
               <option value="12-Haryana">Haryana</option>
               <option value="13-Himachal Pradesh">Himachal Pradesh</option>
               <option value="14-Jammu & Kashmir">Jammu &amp; Kashmir</option>
               <option value="15-Jharkhand">Jharkhand</option>
               <option value="16-Karnataka">Karnataka</option>
               <option value="17-Kerala">Kerala</option>
               <option value="18-Lakshadweep">Lakshadweep</option>
               <option value="19-Madhya Pradesh">Madhya Pradesh</option>
               <option value="20-Maharashtra">Maharashtra</option>
               <option value="21-Manipur">Manipur</option>
               <option value="22-Meghalaya">Meghalaya</option>
               <option value="23-Mizoram">Mizoram</option>
               <option value="24-Nagaland">Nagaland</option>
               <option value="25-NCT OF Delhi">NCT OF Delhi</option>
               <option value="26-Odisha">Odisha</option>
               <option value="27-Puducherry">Puducherry</option>
               <option value="28-Punjab">Punjab</option>
               <option value="29-Rajasthan">Rajasthan</option>
               <option value="30-Sikkim">Sikkim</option>
               <option value="31-Tamil Nadu">Tamil Nadu</option>
               <option value="32-Telangana">Telangana</option>
               <option value="33-Tripura">Tripura</option>
               <option value="34-Uttar Pradesh">Uttar Pradesh</option>
               <option value="35-Uttarakhand">Uttarakhand</option>
               <option value="36-West Bengal">West Bengal</option>
            </select>
         </div>
         <div class="form-group col-md-6">
            <select  class="form-control reginput" id="district" >
               <option value="">{t('signup.district')}*</option>
               {
               response.map((row) => 
               <option key={row.district} value={row.district}>{row.district}</option>
               )}
            </select>
         </div>
      </div>
      
      <div class="form-row">
         <div class="form-group col-md-6">
            <input type="number" class="form-control reginput" id="age" placeholder={t('signup.age')+'*'} />
         </div>
         <div class="form-group col-md-6 ">
            <select class="form-control reginput" id="gender"  >
               <option value="">{t('signup.gender')}*</option>
               <option>Male</option>
               <option>Female</option>
               <option>Others</option>
            </select>
         </div>
      </div>
      <div class="form-row"> 
         <div class="form-group col-md-12 ">
            <select class="form-control reginput" id="profession"  >
               <option value="">{t('signup.profession')}*</option>
               <option>Educators: School teachers/social workers </option>
               <option>Counsellors: mental health/career counsellors/ child and adolescent counsellors </option>
               <option>Health care providers: doctors/nurses/community health workers/midwives</option>
               <option>NGO staff: Program officers, Project managers, Community health workers</option>
               <option>Govt officials: policy makers/health department officials</option>
               <option>Adolescents/young people</option>
               <option>Guardians: parents/caregivers</option>
               <option>Self-employed</option>
               <option>Professionals</option>
               <option>Researchers</option>
               <option>Others</option>
            </select>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-6 ">
            <input class="form-control reginput" id="password" type="password" placeholder={t('signup.create_password')+'*'} />
         </div>
         <div class="form-group col-md-6 ">
            <input class="form-control reginput" id="confirm_password" type="password" placeholder={t('signup.confirm_password')+'*'} />
         </div>
      </div>
   </div>
   <div class="form-group">
   </div>
   <div class="form-group">
      <button type="submit" class="btn btn-primary btn-block"> {t('signup.create_account')}  </button>
   </div>
   <div class="row">
      <div class="col-md-12">
         <p class="divider-text">
            <span class="bg-white">{t('signup.or_create_an_with')}</span>
         </p>
         <SocialLogin path="" history={props.history} />
      </div>
   </div>
   <small>
   <span>{t('signup.registration_disclaimer1')} </span>
   <span><a href="terms-and-condition" target="_blank">{t('navigation.terms_of_services')} </a> </span> {t('portal.and')} 
   <a href="privacy-policy" target="_blank"> {t('navigation.privacy_policy')}</a>.
   </small>
</form>
</div>
  );
}