import React, {Component} from 'react'
import TopicIndex from './TopicIndex'
import { withTranslation} from 'react-i18next'


class Topic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lessons: [],
        };
    }

    async componentWillMount() {
       
    }

    

    render() {
      const {t} = this.props
        
        return (
            <div>
                <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <h1>{t('navigation.core_issues')}</h1>
                </div>
                <div class="col-md-5">
                    <ol class="breadcrumb pull-right">
                       <li><a href="/">{t('navigation.home')}</a></li>
                      
                        <li class="active">{t('navigation.core_issues')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>

            <div class="content content-bg4">
            <div class="container">
              
            <div class="row">
           
                <div class="col-md-12">
                <p class="text-center header">{t('navigation.core_issues')}</p>
               
                <TopicIndex path='/api/courses' />
                     
                   
                </div>
             
            </div>
              

            </div>
        </div>
        </div>
        )
    };
}

export default withTranslation()(Topic);