import React,{useEffect,useState} from 'react'
import axios from "../axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from "../Spinner.js";

export default function Testimonials() {
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse]=useState([])
  const { t} = useTranslation();

 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

useEffect(() => {
    (async () => {
        try {
        const userPosts = await axios.get("/api/testimonials")
        setResponse(userPosts.data.data)
        setLoading(false)
        } catch (err) {
          console.error(err.message);
        }
    })()
}, [])


if (isLoading) {
	return <Spinner />
}

return (

	<div>
   <div class="row">
      <div class="col-md-8 col-center m-auto">
         <h2 align="center">{t('portal.testimonials')}</h2>
         <div id="myCarousel2" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
               <li data-target="#myCarousel2" data-slide-to="0" class="active"></li>
               <li data-target="#myCarousel2" data-slide-to="1"></li>
               <li data-target="#myCarousel2" data-slide-to="2"></li>
               <li data-target="#myCarousel2" data-slide-to="3"></li>
               <li data-target="#myCarousel2" data-slide-to="4"></li>
            </ol>
            {
            response.map((row, i) => (
            <div class={i ==1 ? "item carousel-item active" : "item carousel-item"} >
            <div class="img-box"><img src={row.picture_url} alt="" /></div>
            <p class="testimonial">{row.testimonial}</p>
            <p class="overview">
            <div dangerouslySetInnerHTML={{__html: row.person_name}} /></p></div>
            ))
            }
            <a class="carousel-control left carousel-control-prev" href="#myCarousel2" data-slide="prev">
            <i class="fa fa-angle-left"></i>
            </a>
            <a class="carousel-control right carousel-control-next" href="#myCarousel2" data-slide="next">
            <i class="fa fa-angle-right"></i>
            </a>
         </div>
      </div>
   </div>
</div>

  );
}