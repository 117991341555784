import React, {Component} from 'react'
import axios from "./axios.js";
export default class Resources extends Component {
constructor(props) {
super(props);
this.state = {

};
}
//Check if user authenticated
 componentWillMount() {

}


redirect = () => {
    this.props.history.push('/login');
}
render() {
//Error messages
const errMessage = !Array.isArray(this.state.err) ? (
<div className='red-text center'>{this.state.err}</div>
)
: (this.state.err.map(err => {
return (
<div className='red-text center'>{err}</div>
)
}));
return (
<div>
   <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1></h1>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">Home</a></li>
                  <li class="active">Resources</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
   <div class="content content-white">
            <p class="text-center header">Additional  <strong>Resources</strong></p>
                   <p class="text-center">Additional Resource Center</p>
              <br />
               <div class="container">
                
                   <div class="row category-box-row1">
                   
                     
                   

                    <p align="center"><img src="/img_resource_center.png" /></p> 
                    
               </div>        
           </div>
           </div>
</div>
)
}
}