import axios from 'axios';
import { PATH_LOGIN } from '../utils/constants';

export const LoginSvc = payload => {
  return axios(PATH_LOGIN, {
    method: 'POST',
    data: payload
  })
    .then(response => response)
    .catch(error => {
      throw error;
    });
};


export const setSession = payload => {
  localStorage.setItem('token', payload.token);
  localStorage.setItem('username', payload.username);
  localStorage.setItem('firstName', payload.firstName);
  localStorage.setItem('_id', payload._id);
};
