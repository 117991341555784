import React, {Component} from 'react'
import axios from "./axios.js";

import { toast } from 'react-toastify';


import {withTranslation } from 'react-i18next'
import {withRouter} from 'react-router-dom'

import SocialLogin from "./SocialLogin";
import RegisterForm from "./RegisterForm";


class RegisterLP extends Component {
constructor(props) {
super(props);
this.state = {
   districts:[],
   userLoggedIn: false,
};
}
//Check if user authenticated
 componentWillMount() {

}
 componentDidMount() {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');

       
    }
	

loadDistricts(state_id)
{
   axios.post("/api/getDistricts", {state_id:state_id}).then(res2 => {
      this.setState({
         districts: res2.data.data
      });
    });
}

enroll= (token,name) => (e) =>{
   var rdir='/view/course/'+this.props.path;
   axios.post('/api/courses/enroll', {id: this.props.path,name:name}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + token
       }
   }).then(res => {
	 
		this.props.history.push(rdir)
		window.location.reload();		
		this.props.userLoggedIn(true, name);	
    });
   
}
	

userRegister = (e) => {
    e.preventDefault();
	var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')
	
    //let firstName=e.target.children[0].children[0].children[0].value;
    //let lastName=e.target.children[0].children[1].children[0].value;	
	//let username=e.target.children[1].children[0].children[0].value;
	//let mobile=e.target.children[1].children[1].children[0].value;
	//let state=e.target.children[2].children[0].children[0].value;
    //let district=e.target.children[2].children[1].children[0].value;
	//let current_institute=e.target.children[3].children[0].children[0].value;
    //let employment_sector=e.target.children[3].children[1].children[0].value;
    //let password=e.target.children[4].children[0].children[0].value;
    //let confirm_password=e.target.children[4].children[1].children[0].value;
	
	const query = new URLSearchParams(this.props.location.search);
	let utm_campaign = query.get('utm_campaign');

	if(utm_campaign!=null)
	{
		localStorage.setItem('utm_campaign', utm_campaign);
	}
	if(utm_campaign==null || utm_campaign=='')
	{
	utm_campaign=localStorage.getItem('utm_campaign');
	}

   let utm_source = query.get('utm_source');
   if(utm_source!=null)
	{
		localStorage.setItem('utm_source', utm_source);
	}
	if(utm_source==null || utm_source=='')
	{
      utm_source=localStorage.getItem('utm_source');
	}
   
	
	let firstName=e.target.firstName.value;
    let lastName=e.target.lastName.value;
	let username=e.target.ctrlEmail.value;
	let mobile=e.target.mobile.value;

   let state_id_name=e.target.state.value;
   let state_id=state_id_name.split('-')[0];
   let state=state_id_name.split('-')[1];
    let district=e.target.district.value;
	let current_institute=e.target.current_institute.value;
    let employment_sector=e.target.employment_sector.value;
    let password=e.target.password.value;
    let confirm_password=e.target.confirm_password.value;
	
	

   if(firstName==='' || lastName==='' || username ==='' || state === '' || district === '' || password === '' || confirm_password === '' || current_institute === '' || mobile === '')
   {
      toast.error('Please fill all required fields marked by *', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else if(password.length<5)
   {
      toast.warn('Password length cannot be less than 5 character', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else if(password!==confirm_password)
   {
      toast.warn('Password and confirm password should be the same', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
    else if(mobile.length!==10)
   {
      toast.error('Please enter a valid mobile number ', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else
   {
    let isInstructor = 'true';
	 var rdir='/view/course/'+this.props.path;
    const user = {username, password, firstName, lastName,state,state_id,district,employment_sector,current_institute,language,mobile,utm_campaign,utm_source};

    axios.post("/api/users/register", user)
    .then(res => {
    if (res.data.status)
    {
			localStorage.setItem('token', res.data.token);
   localStorage.setItem('username', res.data.username);
   localStorage.setItem('firstName', res.data.firstName);
   localStorage.setItem('_id', res.data._id);
           
		  
		   
        document.getElementById("register").reset();
        toast.success('You have successfully registered', {
         position: toast.POSITION.TOP_CENTER}); 
     
	
	 
		axios.post('/api/users/assign-course', {id: this.props.path,name:res.data.firstName,language:language}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + res.data.token
       }
   }).then(res => {
		window.conversion()
		this.props.history.push(rdir)
		window.location.reload();		
		
    });	 
    }
   
    })
	.catch(function (error) {
   
	 toast.error('Registration Failed: '+error.response.data.message, {
         position: toast.POSITION.TOP_CENTER
     });
	 
   
 })
	
	

   }
}


redirect = () => {
    this.props.history.push('/login');
}




rating (path) {
   this.props.history.push(path);
}

handleChange = (event) => {
   console.log(event.target.text)
   this.setState({value: event.target.value});
   this.loadDistricts(event.target.value)
}



render() {
const {t} = this.props
const path=this.props.path;

const responseFacebook = (response) => {
   this.socialLogin(response.name,response.email,response.accessToken,'https://graph.facebook.com/me?access_token=','Facebook')
 }

 const responseGoogle = (response) => {
   this.socialLogin(response.profileObj.name,response.profileObj.email,response.accessToken,'https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=','Google')
 }

const errMessage = !Array.isArray(this.state.err) ? (
	<div className='red-text center'>{this.state.err}</div>
	)
	: (this.state.err.map(err => {
	return (
	<div className='red-text center'>{err}</div>
	)
	}));

var code=<div><div class="card"><div class="card-body video-card"><div class="video-container-home"><iframe src="https://www.youtube.com/embed/8Wd2_d3FIeA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div></div></div></div>
if(!this.state.userLoggedIn)
{
	code=<div  class="signup">
	<h2 class="signup-page-title">ENROLL NOW</h2>
   
   <p class="divider-text"><span class="bg-white">{t('signup.alrady_have_an_account')}? <a class="signup-log-in" href="javascript:void(0)" onClick={this.redirect}> {t('signup.login')} »</a> </span></p>
   <p> {errMessage}</p>
   <div class="frm-popup">
   <form id="register" onSubmit = {this.userRegister}>
     
   <RegisterForm />

	  
      <div class="form-group">
      </div>
      <div class="form-group">
      </div>
      <div class="form-group">
         <button type="submit" class="btn btn-primary btn-block"> {t('signup.create_account_and_enroll')}  </button>
      </div>
      <div class="row">
         <div class="col-md-12">
            <p class="divider-text">
               <span class="bg-white">{t('signup.or_create_an_with_enroll')}</span>
            </p>
            <div class="form-group row">
               <div class="col-md-12 text-center">
                  
				  <SocialLogin path={this.props.path} history={this.props.history} />
				  
               </div>
            </div>
         </div>
      </div>
      <p class="small">  {t('signup.registration_disclaimer1')}  
         <a href="terms-and-condition" target="_blank"> {t('navigation.terms_of_services')} </a>  {t('portal.and')} 
         <a href="privacy-policy" target="_blank"> {t('navigation.privacy_policy')} </a>.
		 
		 
      </p>
   </form>
	</div>
	</div>
}


return (
<div>

{code}
   
</div>
)
}
}
//export default withTranslation()(RegisterLP);
export default withRouter(withTranslation()(RegisterLP));