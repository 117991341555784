import React, {Component} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';

export default class ResetPasswordToken extends Component {
constructor(props) {
super(props);
this.state = {
topic: [],
course: [],
};
}

componentDidMount() {
   
}

 //Handling user log in
 resetPassword = (e) => {    
    e.preventDefault();
    let password1 = e.target.children[0].children[0].value;
    let password2 = e.target.children[1].children[0].value;
    let token=this.props.match.params.token
    let id=this.props.match.params.id
    if(password1==='' || password2==='')
    {
        toast.error('New password and confirm password should be same', {
            position: toast.POSITION.TOP_CENTER
        });    
    }
    else if(password1.length<5){
        toast.warning('Password cannot be less than 5 character', {
            position: toast.POSITION.TOP_CENTER
        });    
    }
    else
    {
        axios.post("/api/users/reset-password-by-token", {password1:password1,password2:password2,token:token,id:id})
        .then(res => {
            let err = ''
            if(res.data.status)
            {
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            document.getElementById("resetpwd").reset();
			}
        
			}).catch(function (error) {
    
     console.log(error.response.data.error) //Please Authenticate or whatever returned from server
   if(error.response.status==400){
     //redirect to login
	
	 toast.error('Failed: '+error.response.data.message, {
         position: toast.POSITION.TOP_CENTER
     });
	 
   }
 })


        
    }
}


redirect = () => {
    this.props.history.push('/login');
}

render() {

    return (
        <div>
           
           <div class="content content-bg2">
              <div class="container">
              <div class="row">
            <div class="col-md-4">
            </div>
            <div class='col-md-4'>
          
            
               <div class="card">
                  <div class="card-header">
                  <h2 class="title-form text-center">RESET PASSWORD</h2>
                  <div align="center"><small><a href="/login"> I just remembered the password.</a></small></div>
                     
                      <div align="center"></div></div>
                  <div class="card-body">
                     <form id="resetpwd" onSubmit = {this.resetPassword}>
                        
                     <div class="form-group">
                           <input type="password" placeholder="Your new password" name="password1" id="password1" class="form-control input-md"  />
                       </div>

                       <div class="form-group">
                           <input type="password" placeholder="Your new password again" name="password2" id="password2" class="form-control input-md"  />
                       </div>

                     

                       <div class="form-group text-right">
                       <button type='submit' className='btn btn-primary'>Change My Password</button>
                       </div>
                       <div class="form-group">
                       <small><a href="/login" > Back to login page</a></small>
                       </div>
                     
                       
                     </form>
                     
                  </div>
               </div>
            </div>
            <div class='col-md-3'> 
            </div>
         </div>

                 
              </div>
              <br />
              <br />
              <br />
              <br />
            
           </div>
        </div>
        )
        }
        }