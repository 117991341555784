
import React, {Component} from 'react';
import axios from "../axios.js";
import Moment from "moment";
import  { css } from 'styled-components'


import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


export default class CourseCertificate extends Component {
    constructor(props,context) {
        super(props,context);
          this.state = {
         
          course: [],
          userInfo:[],
          certData:[],
          certUser:[],

         
          };
      
        }
    
        
print(){
        window.print();
    }

     componentDidMount() {


     


      document.title = "Educatley Certificate"

      const token = localStorage.getItem('token');
      localStorage.setItem('course_id', this.props.match.params.id)
      
      const username = localStorage.getItem('username');
      axios.post('/api/users/profile/', {username: username}, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
      }).then(res => {
           if (res.data.message !== 'unauthorized.')
          
               this.setState({
                 
                userInfo:res.data
               });
       });
       
       axios.get('/api/certificate/'+this.props.match.params.id, {})
                   .then(res2 => {
                       console.log(res2.data)
                       this.setState({
                        certData: res2.data.data,
                       
                       });
                   }); 
   
    }

   redirect (path) {
        this.props.history.push(path);
    }

prinDocument(id){
	 var printContents = document.getElementById('printarea').innerHTML;
     var originalContents = document.body.innerHTML;

     document.body.innerHTML = printContents;

     window.print();

     document.body.innerHTML = originalContents;
}
printDocument (id){

         
      const input = document.getElementById('printarea');
		  var file_name="Educately_Certificate_"+id+".pdf";
		  
html2canvas(input,{
  
  logging: true, letterRendering: 1, allowTaint: true, useCORS: true,
 
  dpi: 144,
} )
            .then((canvas) => {
              const imgData = canvas.toDataURL('image/jpeg',1);
              const doc = new jsPDF("landscape", "mm", "a4",false);
              doc.scaleFactor = 1;

			 
        
              var width = doc.internal.pageSize.getWidth();
               var height = doc.internal.pageSize.getHeight();
              doc.addImage(imgData, 'JPEG', 10, 10, 300,250);
              // pdf.output('dataurlnewwindow');
              doc.save(file_name);
            });
          
		  
		  
		  
        }
		
    
    

  print() { 
   var printContents = document.getElementById('printarea').innerHTML;
   var originalContents = document.body.innerHTML;
   document.body.innerHTML = "<html><head><title></title></head><body style='margin: 0px;'>" + printContents + "</body>";
   window.print();
   document.body.innerHTML = originalContents;
}


    render() {

     
      
      let headerTxt ='';
            if ((localStorage.getItem('token') && localStorage.getItem('_id')== this.state.certData.id) || localStorage.getItem('username')=== "course-coordinator@educately.org")
            {
               headerTxt=<div class="page-header">
               <div class="container">
                  <div class="row">
                 
                    
                  <div class="col-md-6">
                 <br />
                 <h4> Congratulations</h4> 
                 <h5> You have earned your certificate from Educately — share it with colleagues, friends, and family to get the word out</h5>
       <br />
                     </div>
       
                     <div class="col-md-6 text-right">
                       
                       <br />
       
                     <p> 
                   
                        <a onClick={()=>this.printDocument(this.state.certData.enrollment_id)} title="Download your digital certificate" class="btn btn-social social-print"><i class="fa fa-file-pdf-o"></i></a>
                             <a class="btn btn-social social-facebook" href={"https://www.facebook.com/sharer/sharer.php?u=https://educately.org/certificate/"+this.props.match.params.id+"&quote=I just completed a course on Educately.org Check it out"}  ><i class="fa fa-facebook"></i></a>
                             
                             <a class="btn btn-social social-twitter" href={"https://twitter.com/intent/tweet?text=I just completed a course on @EducatelyOrg Check it out%21%20https://educately.org/certificate/"+this.props.match.params.id} ><i class="fa fa-twitter"></i></a>
                            
                                
                             </p>
                     </div>
                  </div>
               </div>
            </div>
            }
     // {}

        return (

          <div class="certbody">
          {headerTxt}
     
      <div class="content content-bg21">
     
         <div class="container">
        <div id="printarea" style={{"width": "60%"}}
        
        className="mt4" >
           


      
        <table width="100%" style={{"border":"3px solid #000000"}}>
                    <tr>
                     

    <td width="60px"  style={{"vertical-align":"top"}}>

<table border="0" width="100%" height="100%">
  <tr>
    <td height="100px" width="62%" style={{"background":"#fcaf17"}}> </td>
    <td></td>
    </tr> 

    <tr>
    <td height="1px" > </td>
    <td></td>
    </tr> 

    <tr>
      
    <td></td>
    <td height="660px" style={{"background":"#fcaf17"}}> </td>
   
    </tr> 

</table>

  </td>
                   
                     
                     
                      
					  <td>

<table border="0" width="100%">
<tr>

  <td align="left" width="33%" style={{"padding-top":"8px","vertical-align":"top"}}  ><img style={{"max-height":"100px"}} src={this.state.certData.logo!=''?this.state.certData.logo:this.state.certData.course_partner_logo }  alt="" /> </td>
 
  <td align="center" width="34%" style={{"padding-top":"8px","vertical-align":"top"}} ><img  src="/img/logo.png" alt="" /></td>
  
  <td align="right" width="33%" style={{"padding-top":"8px","vertical-align":"top"}} > <img  src="/img/population-foundation-logo.png" height="45px" alt="" /> &nbsp;</td>

</tr>
</table>



					
            <h1 align="center" style={{"color":"#fcaf17"}} dangerouslySetInnerHTML={{__html: this.state.certData.certificate_headline}} />

            <p align="center" style={{"margin-top":"0px","margin-bottom":"0px"}}>This is to certify that</p>
            <h2  align="center" style={{'text-transform': 'capitalize',"margin-top":"0px"}}>{this.state.certData.firstName} {this.state.certData.lastName}</h2>
            <p align="center" style={{"margin-top":"0px","margin-bottom":"0px"}}>has successfully completed</p>

            <h6 align="center">____________________________________</h6>
            <h2 align="center" style={{"color":"#fcaf17"}}>{this.state.certData.name}</h2> 
        <p align="center" style={{"margin-top":"0px",'text-transform': 'lowercase'}} dangerouslySetInnerHTML={{__html: this.state.certData.certificate_description}} />
					  
            <p align="center" style={{"margin-bottom":"0px"}}>Certified by<br />{this.state.certData.title!=''?this.state.certData.title:this.state.certData.certified_by}</p>
					
          
            <h6 align="center">_____________________________________________________________________________</h6>
       
<table width="90%" style={{"margin": "auto"}}>
<tr>

<td><p align="center" dangerouslySetInnerHTML={{__html: this.state.certData.signatory1}} /></td> 
<td><p align="center" dangerouslySetInnerHTML={{__html: this.state.certData.signatory2}} /></td> 
<td><p align="center" dangerouslySetInnerHTML={{__html: this.state.certData.signatory3}} /></td> 
      


<td>
<p align="center"> <img src={"/img/"+this.state.certData.signature_img} alt="" height="60px" /><br />

<span dangerouslySetInnerHTML={{__html: this.state.certData.signatory_name}} />
</p>		
</td>

<td>
<p align="center"> <img src={"/img/"+this.state.certData.signature_img2} alt="" height="60px" /><br />

<span dangerouslySetInnerHTML={{__html: this.state.certData.signatory_name2}} />
</p>		
</td>


</tr>
</table>
	   
 <p style={{"text-align":"center"}}><img src={this.state.certData.certificate_footer_logo} /></p>      

<br />
<table border="0" width="100%">
<tr>

  <td align="left">&nbsp; Issued <strong>{Moment(this.state.certData.completion_date).format('MMM, Y')}</strong></td>
  <td>
  
  <p align="right" style={{"margin-bottom":"0px","vertical-align":"bottom"}}> 
                  
                  Verify: <strong><a href={"/certificate/"+this.props.match.params.id}>{this.props.match.params.id}</a></strong>

                  </p>
  </td>
  <td align="right"></td>

</tr>
<tr>

  <td align="center" colspan="3"><small dangerouslySetInnerHTML={{__html: this.state.certData.certificate_footer_note}} /></td>
  
</tr>
</table>

            </td>

<td width="60px"  style={{"vertical-align":"top"}}>

<table border="0" width="100%" height="100%">
  <tr>
    <td height="660px"  width="38%" style={{"background":"#fcaf17"}}> </td>
    <td></td>
    </tr> 

    <tr>
    <td height="1px" > </td>
    <td></td>
    </tr> 

    <tr>
      
    <td></td>
    <td height="100px" style={{"background":"#fcaf17"}}> </td>
   
    </tr> 

</table>

  </td>
                    </tr>
					
				
                 
</table>

                

           

          


     </div>
	  
     </div>
     
	 
     </div>

     </div>
           
        )
    };
}