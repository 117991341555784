import React, {Component} from 'react'
import axios from "../axios.js";
import Moment from 'moment'
import $ from 'jquery'
import {toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { withTranslation, Trans } from 'react-i18next'

class SingleDiscussion extends Component {
   constructor(props) {
      super(props);
      this.state = {
        data1:[],
        comments: [],
        currentPage: 1,
        commentsPerPage: 5,
        upperPageBound: 5,
        lowerPageBound: 0,
        isPrevBtnActive: 'disabled',
        isNextBtnActive: '',
        pageBound: 5
};
this.handleClick = this.handleClick.bind(this);
this.btnDecrementClick = this.btnDecrementClick.bind(this);
this.btnIncrementClick = this.btnIncrementClick.bind(this);
this.btnNextClick = this.btnNextClick.bind(this);
this.btnPrevClick = this.btnPrevClick.bind(this);
this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
}

componentDidMount() {
 
 let id= this.props.match.params.id
   var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
   axios.get('/api/courses/discussions/'+id+'?language='+language, {})
      .then(res => {

            this.setState({
               data1: res.data,
            });
    });
 
 
 
   this.loadComments();
}

componentDidUpdate() {
    $("ul li.active").removeClass('active');
    $('ul li#'+this.state.currentPage).addClass('active');
}

loadPage() {
   
}

loadComments() {
    let id= this.props.match.params.id
    axios.get('/api/courses/discussions/'+id+'/comments', {})
       .then(res => {
             this.setState({
                comments:res.data
             });
       });
 }

submitComment = (e) => {
e.preventDefault();
let id= this.props.match.params.id
let comment = e.target.children[0].children[1].value;
const userid = localStorage.getItem('_id');
   axios.post('/api/discussions/'+id+'/comments', {comment:comment,userid:userid}, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
  }).then(res5 => {
        if(res5.data.status)
        {
            toast.success(res5.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
              document.getElementById("frmcomment").reset();
              this.loadComments();
            }
        else
        {
            toast.error(res5.data.message=='unauthorized.'?'You must login in order to comment':res5.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
        }
      });  
 }


 deletecom(comid)
 {
let id= this.props.match.params.id
   axios.delete('/api/discussions/'+id+'/comments/'+comid, {}, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
  }).then(res => {
        if(res.data.status)
        {
            toast.info(res.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
              this.loadComments();
            }
        else
        {
            toast.error(res.data.message, {
            position: toast.POSITION.TOP_CENTER
        });
        }
      }); 
 }

    deleteComment (id) {
     confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>  this.deletecom(id)
          },
          {
            label: 'No',
            onClick: () => ''
          }
        ]
      })
    }

handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
        currentPage: listid
    });
    $("ul li.active").removeClass('active');
    $('ul li#'+listid).addClass('active');
    this.setPrevAndNextBtnClass(listid);
    }
    setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.state.comments.length / this.state.commentsPerPage);
    this.setState({isNextBtnActive: 'disabled'});
    this.setState({isPrevBtnActive: 'disabled'});
    if(totalPage === listid && totalPage > 1){
        this.setState({isPrevBtnActive: ''});
    }
    else if(listid === 1 && totalPage > 1){
        this.setState({isNextBtnActive: ''});
    }
    else if(totalPage > 1){
        this.setState({isNextBtnActive: ''});
        this.setState({isPrevBtnActive: ''});
    }
}
    btnIncrementClick() {
        this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
        let listid = this.state.upperPageBound + 1;
        this.setState({ currentPage: listid});
        this.setPrevAndNextBtnClass(listid);
}
    btnDecrementClick() {
    this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
    this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid});
    this.setPrevAndNextBtnClass(listid);
}
btnPrevClick() {
    if((this.state.currentPage -1)%this.state.pageBound === 0 ){
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
}
btnNextClick() {
    if((this.state.currentPage +1) > this.state.upperPageBound ){
        this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
}
      
render() {
   const {t} = this.props
    const _id = localStorage.getItem('_id');
	
	
    const rowdata = this.state.comments.map(row => {
        return (
            <li class="list-group-item">
            <div class="row">
                <div class="col-xs-2 col-md-1">
                    <img src={'/user.png'} class="img-circle img-responsive" alt="" /></div>
                <div class="col-xs-10 col-md-11">
                <div class="comment-text"><p>{row.comment}</p></div>
                    <div class="row">
                        <div class="col-md-8">
      
                        <div class="mic-info">
        <i class="fa fa-user"></i>                     
                       
                        </div>
                    </div>
                   
                    </div>
               
                </div>
            </div>
        </li>
        )
    });

    const { comments, currentPage, commentsPerPage,upperPageBound,lowerPageBound,isPrevBtnActive,isNextBtnActive } = this.state;
            // Logic for displaying current comments
            const indexOfLastTodo = currentPage * commentsPerPage;
            const indexOfFirstTodo = indexOfLastTodo - commentsPerPage;
            const currentcomments = comments.slice(indexOfFirstTodo, indexOfLastTodo);

    const rendercomments = currentcomments.map((row, index) => {
        var mycomm='';
      return (
      <li key={index} class={"list-group-item "+mycomm}>
          <div class="row">
                <div class="col-xs-2 col-md-1">
                    <img src={'/user.png'} class="img-circle img-responsive" alt="" /></div>
                <div class="col-xs-10 col-md-11">
                <div class="comment-text"><p>{row.comment}</p></div>
                    <div class="row">
                        <div class="col-md-8 comment-source">
                        <p>By {row.username.split('@')[0]} on {Moment(row.discussion_updated_on).format('MMM DD, YYYY hh:mm A ')}</p>
                        <div class="mic-info">
       
       
                        </div>
                    </div>
                    {
                        row.userid==_id?
                    <div class="col-md-4 text-right">
                    <span><button onClick={()=>this.deleteComment(row.id)} class="btn btn-default btnDelete"><i class="fa fa-trash"></i></button> </span>
                    {/* <span><button onClick={()=>this.deleteComment(row._id)} class="btn btn-default"><i class="fa fa-edit"></i></button> </span> */}
                    </div>
                    :''
                     }
                    </div>
               
                </div>
            </div>          
          </li>
      )
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(comments.length / commentsPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
        if(number === 1 && currentPage === 1){
            return(
                <li key={number} className='active' id={number}><a class="page-link" href='javascript:void(0)' id={number} onClick={this.handleClick}>{number}</a></li>
            )
        }
        else if((number < upperPageBound + 1) && number > lowerPageBound){
            return(
                <li key={number} id={number}><a class="page-link" href='javascript:void(0)' id={number} onClick={this.handleClick}>{number}</a></li>
            )
        }
    });
    let pageIncrementBtn = null;
    if(pageNumbers.length > upperPageBound){
        pageIncrementBtn = <li className=''><a class="page-link" href='javascript:void(0)' onClick={this.btnIncrementClick}> &hellip; </a></li>
    }
    let pageDecrementBtn = null;
    if(lowerPageBound >= 1){
        pageDecrementBtn = <li className=''><a class="page-link" href='javascript:void(0)' onClick={this.btnDecrementClick}> &hellip; </a></li>
    }
    let renderPrevBtn = null;
    if(isPrevBtnActive === 'disabled') {
        renderPrevBtn = <li className={isPrevBtnActive}><span class="page-link" id="btnPrev"> Prev </span></li>
    }
    else{
        renderPrevBtn = <li className={isPrevBtnActive}><a class="page-link" href='javascript:void(0)' id="btnPrev" onClick={this.btnPrevClick}> Prev </a></li>
    }
    let renderNextBtn = null;
    if(isNextBtnActive === 'disabled') {
        renderNextBtn = <li className={isNextBtnActive}><span class="page-link" id="btnNext"> Next </span></li>
    }
    else{
        renderNextBtn = <li className={isNextBtnActive}><a class="page-link" href='javascript:void(0)' id="btnNext" onClick={this.btnNextClick}> Next </a></li>
    }


return (   
<div>
<div class="page-header">
   <div class="container">
      <div class="row ">
         <div class="col-md-8">
            <h3 class="heading">{t('heading.discussion_forum')}</h3>
         </div>
         <div class="col-md-4">
            <ol class="breadcrumb pull-right">
               <li><a href="/">{t('navigation.home')}</a></li>
               <li><a href="/discussions">{t('heading.discussion_forum')}</a></li>
            </ol>
         </div>
      </div>
   </div>
</div>
<div class="content content-bg4">
   <div class="container discussion">
      <div class="row">
         <div class="col-md-12">
		 
            <h5>  <div dangerouslySetInnerHTML={{__html:this.state.data1.discussion}} /></h5>
			
           
            </div>
         </div>
         <div class="row">
            <div class="col-md-12">
               <br />
               </div>
         </div>
      </div>
      <div class="container">
         <div class="row commentsec">
            <br />
            <br />
            <div class="col-md-12">
               <div class="panel panel-default widget">
                  <div class="panel-heading">
                     <span class="glyphicon glyphicon-comment"></span>
                     

                    {
                        this.state.comments.length>0?
                        <h5 class="panel-title">{t('heading.recent_comments')} </h5>
                        :<div class="col-md-4 offset-4 no-comments"><h5 class="panel-title text-center">0 {t('heading.comments')} <br /><i class="fa fa-comments"></i> {t('heading.be_the_first_one_to_comment')}</h5></div>
                    }

                        
                     
                  </div>
                  <div class="panel-body">
                     <ul class="list-group">
                        {rendercomments}
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-12 text-right">
               {this.state.comments.length >this.state.commentsPerPage?
               <ul id="page-numbers" className="pagination">
                  {renderPrevBtn}
                  {pageDecrementBtn}
                  {renderPageNumbers}
                  {pageIncrementBtn}
                  {renderNextBtn}
               </ul>
               :''    
               }
            </div>
         </div>
         <div class="col-md-12 text-right">
         </div>
         <div class="row" id="comments">
            <br />
            <div class="col-md-12">
			
			<h5><a href={"/course/module/"+this.props.match.params.id+"/discussion"}>{t('heading.write_your_comments_here')}</a></h5>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
}
export default withTranslation()(SingleDiscussion);