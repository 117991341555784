import React, {Component} from 'react'
import { withTranslation} from 'react-i18next'
import axios from "./axios.js";
import Moment from "moment";
import $ from "jquery";


class Chatbot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatData:[],
			chatbotMenu:[]
        };
    }

componentWillMount() {
	axios.get("/api/chatbot/menu/0", {}).then(chatMenu => {
      this.setState({
        chatbotMenu: chatMenu.data.data
      });
    });
}
componentWillUnmount()
{
  this.setState({
    
  })
}
componentDidMount() {
	
	 $(document).ready(function(){
     hideChat(1);
$('#prime').click(function() {
  toggleFab();
  
  

});
$('#talkToUs').click(function() {
  toggleFab();
});


//Toggle chat and links
function toggleFab() {
	
	if ( $('.chat').css('display') === 'none' || $('.chat').css("visibility") === "hidden"){
    $('.chat').css('display', 'block');
	}
	else
	{
		 $('.chat').css('display', 'none');
	}
	
  $('.prime').toggleClass('zmdi-comment-outline');
  $('.prime').toggleClass('zmdi-close');
  $('.prime').toggleClass('is-active');
  $('.prime').toggleClass('is-visible');
  $('#prime').toggleClass('is-float');
  $('.chat').toggleClass('is-visible');
  $('.fab').toggleClass('is-visible');
  $('.cta').toggleClass('done');
  
  
  
}

  $('#chat_first_screen').click(function(e) {
        hideChat(1);
  });
  
  $('#fab_send').click(function(e) {
	  var text=document.getElementById("chatSend").value
       if(text.length>1){
	   hideChat(1);
	   
		$('#chat_converse').append('<span class="chat_msg_item chat_msg_item_user">'+text+'</span><span class="status">Just now</span>');
		$('#chat_converse').scrollTop($('#chat_converse')[0].scrollHeight);
	   }
  });
  
  $('#chat_fullscreen_loader').click(function(e) {
      $('.fullscreen').toggleClass('zmdi-window-maximize');
      $('.fullscreen').toggleClass('zmdi-window-restore');
      $('.chat').toggleClass('chat_fullscreen');
      $('.fab').toggleClass('is-hide');
      $('.header_img').toggleClass('change_img');
      $('.img_container').toggleClass('change_img');
      $('.chat_header').toggleClass('chat_header2');
      $('.fab_field').toggleClass('fab_field2');
      $('.chat_converse').toggleClass('chat_converse2');
     
  });

function hideChat(hide) {
	$('#loading-image').hide();
    switch (hide) {
      case 0:
            $('#chat_converse').css('display', 'none');
            $('#chat_body').css('display', 'none');
			
            $('#chat_form').css('display', 'none');
            $('.chat_login').css('display', 'block');
            $('.chat_fullscreen_loader').css('display', 'none');
             $('#chat_fullscreen').css('display', 'none');
            break;
      case 1:
            $('#chat_converse').css('display', 'block');
            $('#chat_body').css('display', 'none');
		
            $('#chat_form').css('display', 'none');
            $('.chat_login').css('display', 'none');
            $('.chat_fullscreen_loader').css('display', 'block');
            break;
		default:
			
    }
}
    })
	
	
	
	
	const token = localStorage.getItem('token');
			 axios.post('/api/chatbot/get', {}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token
				}
			}).then(res1 => {
				this.setState({
			   chatData:res1.data.data
			   });
	});
	
}	
   
sendMessage = (e) => {
    e.preventDefault();
	var msg=e.target.children[0].children[2].value
	const token = localStorage.getItem('token');
	if(msg.length>1)
	{
         axios.post('/api/chatbot/post/', {msg:msg}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res2 => {  
	document.getElementById("chatMsg").reset(); 
 
});
 
	}
}

getQueryResponse = (e) => {
	var query=e.target.title;
   var query_id=e.target.id;
   
   $('#chat_converse').append('<span class="chat_msg_item chat_msg_item_user">'+query+'</span><span class="status">Just now</span>');
	$('#chat_converse').scrollTop($('#chat_converse')[0].scrollHeight);
	$('#loading-image').show();

setTimeout(
  function() 
  {
	   axios.get("/api/chatbot/sr/"+query_id, {}).then(chatdata => {
     var msg=chatdata.data.message;
	$('#chat_converse').append('<div><span class="chat_msg_item chat_msg_item_admin"><div class="chat_avatar"><img src="/img/avatar.png"/> </div>'+msg+' <br /> <span class="status_2">Just now</span></span></div>');
   $('#chat_converse').scrollTop($('#chat_converse')[0].scrollHeight);	
   $('#loading-image').hide();
   
    });
	
  
  }, 1500);
}
    

render() {

    const {t} = this.props
	const chatData = this.state.chatData.map(row => {
      return (
	  (row.type===1)?<div><span class="chat_msg_item chat_msg_item_user">{row.msg}<br /><span class="status_2">{Moment(row.sent_on).format('d MMM Y')}</span></span></div>
	  :<div><span class="chat_msg_item chat_msg_item_admin">
		<div class="chat_avatar"><img src="/img/avatar.png" alt="" /> </div>
		{row.msg} 
		<br />
		<span class="status_2">{Moment(row.sent_on).format('d MMM Y')}</span>
		</span>
		
        </div>             
                  
				  
      )});
	  
	  const chatMenu = this.state.chatbotMenu.map(row => {
      return (
	   <li id={row.id} onClick={this.getQueryResponse} title={row.question} >{row.question}</li>
				  
      )});
	  
	  
	
     return (
        <div class="fabs">
            <div class="chat">
               <div class="chat_header">
                  <div class="chat_option">
                     <div class="header_img">
                        <img src="/img/avatar.png"/>
                     </div>
                     <span id="chat_head">{t('heading.talk_to_us')}</span> <br /> <span class="agent">Course Coordinator</span> <span class="online">(Online)</span>
                     <span id="chat_fullscreen_loader" class="chat_fullscreen_loader"><i class="fullscreen zmdi zmdi-window-maximize"></i></span>
                  </div>
               </div>
               <div class="chat_body chat_login">
                  
                  <p>Do you need help or have any query?<br />Ask us anytime. </p>
				  <a id="chat_first_screen" class="fab"><i class="zmdi zmdi-arrow-right"></i></a>
				  <br />
				  
				 
          
      
               </div>
			   
			   
               
			   <div id="chat_converse" class="chat_conversion chat_converse">
                  <span class="chat_msg_item chat_msg_item_admin">
                     <div class="chat_avatar">
                        <img src="/img/avatar.png" alt="" />
                     </div>
                     Hi {localStorage.getItem('firstName')}! <br />Do you have any question?<br />You can select from the options below or you can type in the space provided.
					 
					 <ul class="tags">
					{chatMenu}
					</ul>	
                  </span>
				  
				   
                  
				  {chatData}
				  
				 <br /> 
				  
               </div>
			   
			   <div id="loading-image">
                        <img src="/img/loader.gif" alt="" width="30" />
               </div>
			  
			    <form id="chatMsg" onSubmit={this.sendMessage}>
               <div class="fab_field">
                  <a id="fab_camera" class="fab"><i class="fa fa-comment"></i></a>

 <button id="fab_send" style={{"border":"none","outline":"none"}} class="fab"  ><i class="zmdi zmdi-mail-send"></i></button>
                  <textarea  id="chatSend" name="chat_message" placeholder="Send a message" class="chat_field chat_message"></textarea>
               </div>
			  
			   </form>
            </div>
            <a id="prime" class="fab"><i class="prime dark zmdi zmdi-comment-outline"></i><br />{t('heading.talk_to_us')}</a>
         </div> 
         )
         }
         }

export default withTranslation()(Chatbot);