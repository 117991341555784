import React, {Component} from 'react'
import axios from "../axios.js";
import Pagination from '../common/Pagination'
import Moment from 'moment';
import { withTranslation, Trans } from 'react-i18next'


class Discussion extends Component {
   constructor(props) {
      super(props);
      this.state = {
data1:[],
page: 1,
cure_page:1,
total_pages:0,
banner:null,
};
}


 componentDidMount() {
this.loadPage(); 
}

componentDidUpdate() {
 //  this.loadPage();
}


loadPage() {
var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')
   let page= this.props.match.params.page
   axios.get('/api/discussions?language='+language+'&page='+page, {})
      .then(res => {
            this.setState({
               data1: res.data.records,
               cure_page:1,
               total_pages: 10
            });
			
			 axios.get('/api/banner?id=1', {})
				.then(res2 => {
            this.setState({
               banner: res2.data.data.code,
            });
			
      });
			
			
      });
}

loadmore = (e) => {
   console.log('next called')
  this.state.page=this.state.page+1
  this.loadPage();
}

handleClick = (e) => {
  this.state.page=this.state.page+1
  this.loadPage();
}




render() {
const {t} = this.props
console.log(this.state.banner)
   const rowdata = this.state.data1.map(row => {
           return (
		   
		    <div class="bg-white border-shadow pad15 mb10">
		 <h5 class="qs"> <a href={"/discussions/view/"+row.slug}>{row.title}</a></h5>
		 <p class="comdesc">{row.description}</p>
		 <hr />
		 <div class="row">
		 
		 <div class="col-md-6">
		 <span class="comment_details"> <a class="color-dark comment_details" href={"/discussions/view/"+row.slug}> {row.total_comments} {t('heading.comments')} </a></span>
		 <span class="comment_details"> <a class="myCourses comment_details" href={"/discussions/view/"+row.slug}><i class="fa fa-comment"></i>  {t('heading.add_a_comment')} </a></span> 
		 </div>
		 
		 <div class="col-md-6 text-right">
		 
		 
		 <span class="comment_details"> <i class="fa fa-calendar"></i> {Moment(row.created_on).format('MMM DD, YYYY hh:mm A ')}</span>
		 
		 </div>
		 </div>
		 
		 </div>
		 

           

        


           )
       });

return (   
<div>
   <div class="page-header">
      <div class="container" >
         <div class="row">
            <div class="col-md-8">
            <h3 class="headingr">{t('heading.discussion_forum')}</h3> 
			 <p class="text-left ">{t('heading.discussion_desc')}</p>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('navigation.home')}</a></li>
                  <li class="active">{t('navigation.discussions')}</li>
               </ol>
            </div>
         </div>
       
      </div>
   </div>
   <div class="content content-bg4" style={{"background": "#fcfcfc"}}>
     

     

    <div class="container">
   
        <div class="row">
         <div class="col-md-10">
		
          
        {rowdata}
		
		</div>
		
		<div class="col-md-2">
		
	
		<span dangerouslySetInnerHTML={{__html: this.state.banner}} />
		</div>
          
        </div>
    </div>

   
      
   </div>
   </div>
)
}
}
export default withTranslation()(Discussion);