import React, {Component} from 'react'
import axios from "../axios.js";
import '../../assets/QuizStyle.css';
import { withTranslation} from 'react-i18next'

class QuizBoxNew extends Component {
    constructor(props,context) {
        super(props,context);

        this.updateAnswer = this.updateAnswer.bind(this);
        this.checkAnswer = this.checkAnswer.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.getResults = this.getResults.bind(this);
        this.startOver = this.startOver.bind(this);
        this.reWatch = this.reWatch.bind(this);
        this.AnswerView=this.AnswerView.bind(this);
        this.nextSection = this.nextSection.bind(this);

        
        

    this.state = {
      currentQuestion: 0,
      QsUserAnswer: [],
      correct: 0,
      inProgress: true,
      viewAnswer:true,
      isStart:false,
      topic:[],
      currentCheckedAnswer:[],
      quiz_passing_percent:80,
      questions1:[],
      questions: [{
        question: "a",
        options: [{
          option: "a",
          correct: true },
        {
          option: "a",
          correct: false },
        {
          option: "a",
          correct: false }],
        feedback: "a",
        }
    ] 
    };

    this.addAnsState = this.addAnsState.bind(this);
        }
        

         componentWillMount() {
		const {t} = this.props
   
			 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
          const path = this.props.path;
          const next_topic = this.props.next_topic;
         axios.get('/api/quiz/'+path+'?language='+language, {}) 
          .then(quizdata => {
           
              this.setState({
                questions: quizdata.data
                
              });
          }); 

          axios.get('/api/quiz/'+path+'/passing_percent', {}) 
          .then(qpsdata => {
           
              this.setState({
                quiz_passing_percent: qpsdata.data.quiz_passing_percent 
              });
          }); 
      }

     
    
  updateAnswer(e) {
    //record whether the question was answered correctly
    let answerValue = e.target.value;
    
    //this.setState((prevState, props) => {
   //   let stateToUpdate = prevState.questions;
      
    //  let res=false;

      //convert boolean string to boolean with JSON.parse()
   //   stateToUpdate[prevState.currentQuestion].answerCorrect = JSON.parse(answerValue);

  //    return { questions: stateToUpdate };
    //});
    
  }

  addAnsState(e) {  
    let arr = this.state.QsUserAnswer;
    if(e.currentTarget.checked && e.currentTarget.type=='checkbox')
    {
      arr.push(e.currentTarget.value);
      this.setState({QsUserAnswer: arr});
    }

    if(!e.currentTarget.checked && e.currentTarget.type=='checkbox')
    {
      var index =arr.indexOf(e.currentTarget.value)
      arr.splice(index, 1);
      arr.sort()
      this.setState({QsUserAnswer: arr});
    }

    if(e.currentTarget.type=='radio')
    {
      arr=[]
      arr.push(e.currentTarget.value);
      this.setState({QsUserAnswer: arr});
    }

  }

  checkAnswer(e) {
    var rightAnswers = []

    this.state.questions[this.state.currentQuestion].options.map((item, index) => {
    
    if(item.correct)
    {
      rightAnswers.push(item.option)
    }

    });

    rightAnswers.sort()
   
    
    let arr1 = this.state.QsUserAnswer;
    let arr2 = rightAnswers;
    
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    let result=false;
   if( arr1.every(item => set2.has(item)) && arr2.every(item => set1.has(item)))
   {
    result=true;
   }
   this.setState({QsUserAnswer: []});

    console.log(result)

    this.setState((prevState, props) => {
      let stateToUpdate = prevState.questions;
      stateToUpdate[prevState.currentQuestion].checked = true;
      stateToUpdate[prevState.currentQuestion].answerCorrect = JSON.parse(result);

      return { questions: stateToUpdate };
    });
  }

  nextQuestion(e) {
    //advance to the next question
    this.setState((prevState, props) => {
      let stateToUpdate = prevState.currentQuestion;

      return { currentQuestion: stateToUpdate + 1 };
    }, () => {
      this.radioRef.current.reset();
    });
  }

  getResults() {
    //loop through questions and calculate the number right
    let correct = this.state.correct;
    console.log(this.state.questions)
    this.state.questions.forEach((item, index) => {
      if (item.answerCorrect) {
        ++correct;
      }

      if (index === this.state.questions.length - 1) {
        this.setState({
          correct: correct,
          inProgress: false });

      }
      
    });
     
      if(parseInt(correct / this.state.questions.length * 100)>=this.state.quiz_passing_percent)
      {
        const path = this.props.path;
		let quiz_percent=parseInt(correct / this.state.questions.length * 100);
        axios.put('/api/courses/module_topic/'+path+'/quiz', {score: quiz_percent}, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
      }).then(res5 => {
      
		//window.location.reload(false)

          });
        }

  }

  startOver() {
   
    //reset form and state back to its original value
    this.setState((prevState, props) => {
      let questionsToUpdate = prevState.questions;

      questionsToUpdate.forEach(item => {
        //clear answers from previous state
        delete item.answerCorrect;
        delete item.checked;
      });

      return {
        inProgress: true,
        correct: 0,
        currentQuestion: 0,
        questions: questionsToUpdate };

    });
  }

  AnswerView()
  {
   
    this.setState({  
      viewAnswer: true 
    });

    console.log(this.state.viewAnswer)
  }


  reWatch() {
    const path = this.props.path;
    //window.location.reload();   
    window.location.href='/course/module/'+path+'/primary'
  }

  nextSection  = () => {
    

      window.location.href=window.location.href.split('?')[0]+"?tab=2"
  }

  componentDidMount() {

    this.radioRef = React.createRef();

  
  }


    
   render() {
	 const {t} = this.props


   const RawHTML = props => React.createElement("span", { dangerouslySetInnerHTML: { __html: props.html } });




    const QuizProgress = props => {
        return (
          React.createElement("div", { className: "progress" },
          React.createElement("p", { className: "counter" },
          React.createElement("span", null, props.lblQuestion, props.currentQuestion + 1, props.lblof, props.questionLength)),
      
          React.createElement("div", { className: "progress-bar", style: { 'width': (props.currentQuestion + 1) / props.questionLength * 100 + '%' } })));
      
      
      };
       
      const Results = props => {
        
        if(parseInt(props.correct / props.questionLength * 100)<this.state.quiz_passing_percent)
            return (

              <div class="results fade-in">
                <h1>{props.msg+" "+(props.correct / props.questionLength * 100).toFixed()}%</h1>
                <p class="quizFailMsg">{props.unsuccess_msg}</p>
               
                <button onClick={props.startOver}>{ t('quiz.take_this_quiz_again')}</button>
                <button onClick={props.reWatch}>{ t('quiz.retake_lesson_again')}</button>
                
             
      <div class="col-md-12 text-left">
        <h4>Review answers</h4>
           {
              this.state.questions.map(row=>(
                
                <p><i class={row.answerCorrect?"fa fa-check-circle color-green":"fa fa-times-circle color-red"}></i> <span dangerouslySetInnerHTML={{__html: row.question}}  /> </p>
                ))
           }
            </div>
     

              </div>

              )
          else
          return (
            
            <div class="row">
              <div class="col-md-12 text-center">
              <h3> {props.msg}:{(props.correct / props.questionLength * 100).toFixed()}% </h3>
            <img src="/img/congratulations-icon.png" />
			
            <p>{props.success_msg}</p>

            
			</div>
   
      <div class="col-md-12 text-left">
        <h4>Review answers</h4>
           {
              this.state.questions.map(row=>(
                
                <p><i class={row.answerCorrect?"fa fa-check-circle color-green":"fa fa-times-circle color-red"}></i> <span dangerouslySetInnerHTML={{__html: row.question}}  /> </p>
                ))
           }
            </div>
       
            </div>
          
            )
      
      
      };
	  
        if (!this.state.inProgress) {
          return (
            React.createElement("section", { className: "quiz" },
            React.createElement(Results, { msg: t('quiz.your_score') ,success_msg: t('quiz.success_msg'),unsuccess_msg: t('quiz.unsuccess_msg'), next_lbl: t('heading.next'), rfj: t('heading.reflective_journal'),retake_quiz: t('quiz.take_this_quiz_again'),retake_lesson: t('quiz.retake_lesson_again'), correct: this.state.correct, questionLength: this.state.questions.length, startOver: this.startOver,reWatch:this.reWatch,nextSection:this.nextSection }))
            );
    
           

        }
        if (!this.state.isStart) {
          return (
            React.createElement("div", { className: "post_test" },
            React.createElement("h3", { className: "question" }, React.createElement(RawHTML, { html: t('post_test.heading') })),
            React.createElement("li", { className: "question" }, React.createElement(RawHTML, { html: t('post_test.line1') })),
            React.createElement("li", { className: "question" }, React.createElement(RawHTML, { html:  t('post_test.line2a') + this.state.questions.length + t('post_test.line2b') })),
            React.createElement("li", { className: "question" }, React.createElement(RawHTML, { html: t('post_test.line3') })),
            React.createElement("li", { className: "question" }, React.createElement(RawHTML, { html: t('post_test.line4a') +this.state.questions.length+ t('post_test.line4b') })),
            React.createElement("li", { className: "question" }, React.createElement(RawHTML, { html: t('post_test.line5') })),
            React.createElement("li", { className: "question" }, React.createElement(RawHTML, { html: t('post_test.line6a') +this.state.quiz_passing_percent+'% '+t('post_test.line6b') })),
            React.createElement(
              "button",
              { 
                className: "start-quiz-btn btn btn-primary",
               onClick: () => {this.setState({
                isStart: true
              })}
                
                
                  
              },
              [t('heading.start_now')]
          )

            
              
             
              )
            );
    
    
        }
        

    
        return (
         
          
          React.createElement("section", { className: "quiz fade-in", "aria-live": "polite" },
          
          React.createElement(QuizProgress, { lblQuestion:t('quiz.question'), lblof:t('quiz.lblof'), currentQuestion: this.state.currentQuestion, questionLength: this.state.questions.length }),
        
          React.createElement("div", { className: "question-container" },
      
          React.createElement("p", { className: "question" }, React.createElement(RawHTML, { html: this.state.questions[this.state.currentQuestion].question })),
    
          React.createElement("form", { ref: this.radioRef },
          this.state.questions[this.state.currentQuestion].options.map((item, index) => {
            return React.createElement("div", { key: index,
              className: "option" + (this.state.questions[this.state.currentQuestion].checked  ? ' ' : ' ') + (this.state.questions[this.state.currentQuestion].checked && item.correct ? ' ' : '') },
            React.createElement("input", { id: "radio-" + index, onClick: this.addAnsState, type: this.state.questions[this.state.currentQuestion].op_type, name: "option", value: item.option,
              disabled: this.state.questions[this.state.currentQuestion].checked }),
            React.createElement("label", { htmlFor: "radio-" + index }, React.createElement(RawHTML, { html: item.option })));
    
          })),
    
    
          React.createElement("div", { className: "bottom" },
          this.state.questions[this.state.currentQuestion].feedback && this.state.questions[this.state.currentQuestion].checked &&
          React.createElement("div", { className: "fade-in" },
          React.createElement("p", null,
          React.createElement(RawHTML, { html: this.state.questions[this.state.currentQuestion].feedback }),
          
        //   this.state.questions[this.state.currentQuestion].moreUrl &&
        //   React.createElement(React.Fragment, null, "\xA0",
        //   React.createElement("a", { target: "_blank", href: this.state.questions[this.state.currentQuestion].moreUrl }, "Learn more"), ".")
          
          )),
    
    
    
    
    
    
          !this.state.questions[this.state.currentQuestion].checked &&
          React.createElement("button", { type: "button", onClick: this.checkAnswer,
            disabled: (this.state.QsUserAnswer.length==0 ) }, t('quiz.check_answer')),
    
    
          this.state.currentQuestion + 1 < this.state.questions.length && this.state.questions[this.state.currentQuestion].checked &&
          React.createElement("button", { className: "fade-in next", type: "button", onClick: this.nextQuestion },  t('quiz.next'), React.createElement("i", { className: "fa fa-arrow-right" }))),
    
    
    
          this.state.currentQuestion + 1 === this.state.questions.length && this.state.questions[this.state.currentQuestion].checked &&
          React.createElement("button", { type: "button", className: "get-results pulse", onClick: this.getResults }, t('quiz.get_results')))));
    
    
    
    
      };
}
export default withTranslation()(QuizBoxNew);