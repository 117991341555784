import React,{useEffect,useState} from 'react'
import RegisterForm from "./RegisterForm";
import { useTranslation } from 'react-i18next';
export default function Register(props) {
  const [isLoading, setLoading] = useState(false);  
  const { t} = useTranslation();

return (
   <div>
   
   <div class="content register-bg">
      <div class="container">
         <div class="row">
            <div class="col-md-3">
               
            </div>
            <div class='col-md-6 signup'>
            <h2 class="signup-page-title">{t('signup.create_a_free_account')}</h2>
            <p class="divider-text"><span class="bg-white">{t('signup.alrady_have_an_account')}? 
  <a class="signup-log-in" href="/login" > {t('signup.login')} »</a></span></p>
   
                            <RegisterForm path="" history={props.history} /> 
            </div>
            <div class='col-md-3'> 
            </div>
         </div>
      </div>
   </div>
</div>
  );
}