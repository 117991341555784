import React, {Component} from 'react'
import axios from "../axios.js";

import { toast } from 'react-toastify';

import { withTranslation } from 'react-i18next'

class Unenroll extends Component {
constructor(props) {
super(props);
this.state = {

courseDesc: [],

show: "none",
showPanel: "block",
showFinal: "none"

};
}



componentDidMount() {
  
   var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
   axios.get('/api/courses/'+this.props.match.params.id+'?language='+language, {})
                .then(res2 => {
                    console.log(res2.data)
                    this.setState({
                        courseDesc: res2.data
                    });
                }); 


}

//Handling user log in
unEnrollConfirmation = (e) => {    
    e.preventDefault();
    let feedback = e.target.children[0].children[0].value;
    if(feedback==='')
    {
        toast.error('Please enter your resons for the unenrollment', {
            position: toast.POSITION.TOP_CENTER
        });    
    }
    else
    {
        const token = localStorage.getItem('token');
  
        axios.post('/api/users/unenroll', {id: this.props.match.params.id,feedback:feedback}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            
             if (res.data.message!=='unauthorized.')
           {
            toast.info("Thank you for sharing your reasons for unenrolling. You are unenrolled from this programme", {
                position: toast.POSITION.TOP_CENTER
            });      
           
            this.setState({ showPanel: "none",showFinal:"block" });
            
        }
             else
             {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                }); 
             }
         });
       // document.getElementById("feedback").reset();
    }
}


Unenroll = () => {
    this.setState({ show: "block" });
 };

render() {
     
 const {t} = this.props
  
    return (
        <div>
         <div class="content content-bg4">
         <div class="container">
    <p class="text-center header">  <strong> {t('heading.unenroll')}</strong></p>

      <div class="" style={{"display":this.state.showPanel}}>      
      
             <p align="center"> {t('heading.unenroll_confirmation_msg1')} <strong>{this.state.courseDesc.name} </strong> {t('heading.unenroll_confirmation_msg2')}?</p>
             <p align="center">
               
                 <a href="javascript:void(0)" class="btn btn-default" onClick={this.Unenroll}  >{t('heading.yes')}</a>
                 
                 <a href="/dashboard" class="btn btn-default">{t('heading.no')}</a></p>
            
            
             
           

        <div class="row">
        <div class='col-md-3'>
          </div>

          <div class='col-md-6' style={{"display":this.state.show}}>
         
          <p align="center"><img src="/404.png" width="120px" alt="" /></p>
          <p align="center">{t('heading.we_are_sorry_to_see_you_go')} </p>
          <form id="" onSubmit = {this.unEnrollConfirmation}>
                        
                        <div class="form-group">
                              
                              <textarea rows="3" placeholder={t('heading.please_share_your_main_reson_for_unenrolling')} name="feedback" id="feedback" class="form-control input-md"></textarea>
                          </div>

                          <div class="form-group text-right">
                          <button type='submit' className='btn btn-dark'>{t('heading.unenroll')}</button>
                          </div>
                         
                        </form>



          </div>
        </div>
            
        </div>

        <div class='row'>
        <div class='col-md-2'>
          </div> 
          
          <div class='col-md-8' style={{"display":this.state.showFinal}}>
          <p>Thank you for sharing your reasons for unenrolling.
You are unenrolled from <strong>{this.state.courseDesc.name} </strong> programme.</p>

<p align="center">

<a href="/dashboard" class="btn btn-dark">Return To Dashboard</a>

<a href="/programme" class="btn btn-primary">Browse Programmes </a>


</p>
          </div> 

          </div>




        </div>
          
   
            
             </div>

      

        </div>
        )
        }
        }
export default withTranslation()(Unenroll);