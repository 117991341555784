import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import axios from "./axios.js";

import { withTranslation} from 'react-i18next'

class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userLoggedIn: false,
            firstName: '',
			verifiedEmail:'',
			profileInfo:true,
            userAvatarOpen: false,
            loggedIn: false,
			value: "en"
        }


	
var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')
const query = new URLSearchParams(this.props.location.search);
const lang = query.get('language');
console.log(lang)
const utm_campaign = query.get('utm_campaign');
if(utm_campaign!=null)
{
	localStorage.setItem('utm_campaign', utm_campaign);
}


const utm_source = query.get('utm_source');
if(utm_source!=null)
{
	localStorage.setItem('utm_source', utm_source);
}




if(lang!=null)
{
	if(lang==='hi' || lang==='en')
	{
	language=lang
	localStorage.setItem('language', lang);
	}
}
this.props.i18n.changeLanguage(language)
    }
   
    componentDidMount() {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
    
        if(token !==null)
        {
          this.setState({
            userLoggedIn: true
            
        });
        }
    }
    //If user logged in, show hello message on the navbar, if logged out, hide it
    userLoggedIn = (bool, firstName) => {
        this.setState({
            userLoggedIn: bool,
            firstName: firstName
        });
    }

    //Handling user log out
    userLogOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        this.setState({
            loggedIn: false,
            open: false
        });
      //  this.props.history.push('/')
      this.props.history.push('/')
      window.location.reload();
        this.props.userLoggedIn(false, '');

    }


    search = (e) => {
        e.preventDefault();
        const keyword = e.target.children[0].value;
        this.props.history.push('/search?query=' + keyword);
    }

    openModal = () => {
        this.setState({ userAvatarOpen: true });
    }
      closeModal = () => {
        this.setState({ userAvatarOpen: false });
    }
    redirect (path) {
		
		

     
      //this.props.history.push(path)
	  //this.context.history.push(path,null)
	  
        this.props.history.push(path);
    }



onLanguageHandle = (event) => {
    let newLang = event.target.value;
	localStorage.setItem('language', newLang);
    this.setState({value: newLang})
  window.location = window.location.href.split("?")[0];

	//this.props.i18n.changeLanguage(newLang)
  }
 
  renderRadioButtons = () => {
    return (
      <li style={{"display":"none1"}}><input
      checked={localStorage.getItem('language') === 'en'}
      name="language" onChange={(e) => this.onLanguageHandle(e)} value="en" type="radio" /> English &nbsp; 
      <input name="language" value="hi" 
      checked={localStorage.getItem('language') === 'hi'}
      type="radio" onChange={(e) => this.onLanguageHandle(e)} /> हिन्दी</li>
    )
  }
  
	
    render() {
		
		 const {t} = this.props
    
        var btnuser='';
        var btn1=  <li className="btnSignup"><a  href="/register" className="btn btn-login" >{t('navigation.signup')}</a></li> 
           
        
        var btn2=  <li><a  href="/login" className="btn btn-login" >{t('navigation.login')}</a></li>
		
		var mobileLogedInNavigation=''
       
      
        var socialbtn=<span> <a href="https://www.facebook.com/educatelyorg"><i className="fa fa-facebook"></i></a>
        <a href="https://www.youtube.com/channel/UCwO5FLpOqQNfdsxe5X11hPg"><i className="fa fa-youtube"></i></a>
        <a href="https://twitter.com/EducatelyOrg"><i className="fa fa-twitter"></i></a>
        <a href="https://www.instagram.com/educatelyorg"><i className="fa fa-instagram"></i></a></span>
        

       if(this.state.userLoggedIn)
       {
        btnuser= <div className="language-option">
        <img src="/img/user.png" alt="" />
        <span>{ localStorage.getItem('firstName')==null?'':localStorage.getItem('firstName') } <i className="fa fa-angle-down"></i></span>
        <div className="flag-dropdown">
        <ul>
        <li><a href="/dashboard" onClick={()=>this.redirect('/dashboard')}  >{t('navigation.my_courses')}</a></li>
        <li><a href="javascript:void(0)" onClick={()=>this.redirect('/profile')}>{t('navigation.profile')}</a></li>
        <li><a href="javascript:void(0)" onClick={()=>this.redirect('/settings')}>{t('navigation.settings')}</a></li>
        <li><a href="/logout" >{t('navigation.logout')}</a></li>
        </ul>
        </div>
        </div>
             btn1='';
             btn2='';
			 mobileLogedInNavigation=<ul>
        <li><a href="/dashboard"  className="bg-primaryc" >{t('navigation.my_courses')}</a></li>
        <li><a href="/profile" >{t('navigation.profile')}</a></li>
        <li><a href="/settings" >{t('navigation.settings')}</a></li>
        <li><a href="/logout">{t('navigation.logout')}</a></li>
       </ul>
             socialbtn=<span><a href="/dashboard" className="myCourses">{t('navigation.my_courses')}</a></span>
         }

        return (
            <div>
            <div className="offcanvas-menu-wrapper">
<div className="canvas-close">
<i className="icon_close"></i>
</div>

<div className="header-configure-area">
 <p><li className="mobilesm languageToggle"><a href="?language=en"><strong>English</strong></a> | <a href="?language=hi">हिन्दी</a> </li></p>
 
{btn1}
{btn2}

</div>


<nav className="mainmenu mobile-menu">
<ul>
       
	    
		
            <li className=""><a href="/" >{t('navigation.home')}</a></li>
            <li><a href="/courses">{t('navigation.courses')}</a></li>
            <li><a href="/topic" >{t('navigation.core_issues')}</a></li>	
            <li><a href="/discussions" >{t('navigation.discussions')}</a></li>
           
            </ul>
			
			{mobileLogedInNavigation}
       
</nav>
<div id="mobile-menu-wrap">

</div>

</div>
<div className="offcanvas-menu-overlay"></div>
<div className="mobile-language-switch languageToggle">

<a href="?language=en"><strong>English</strong></a> | <a href="?language=hi"><strong>हिन्दी</strong></a>   | 
{!this.state.userLoggedIn?<a href="/login"> {t('navigation.login')}</a>:<a href="/logout"> {t('navigation.logout')}</a>}




</div>
<div className="canvas-open">
<i className="icon_menu"></i><div className="mobilemenulbl">{t('navigation.menu')}</div>
</div>


     
            <header className="header-section">
            <div className="top-nav">
            <div className="container">
            <div className="row">
            <div className="col-lg-8">
            <ul className="tn-left languageToggle" >
        
            { this.renderRadioButtons() }
			{this.state.verifiedEmail==='0'?<span className="resend_email_activation"><i className="fa fa-times-circle"></i> Your email is not yet verified. <a href="/profile">Know more</a></span>:''}
			
             </ul>
            </div>
			
			
            <div className="col-lg-4">
            <div className="tn-right">
            <div className="top-social">
           {socialbtn}
            </div>
{btnuser}            

            

            </div>
            </div>
            </div>
            </div>
            </div>
            <div className="menu-item">
            <div className="container">
            <div className="row">
            <div className="col-lg-2">
            <div className="logo">
            <a href="/" >
            <img src="/img/logo.png" alt="" />
            </a>
            </div>
            </div>
            <div className="col-lg-10">
            <div className="nav-menu">
            <nav className="mainmenu">
            <ul>
            <li className=""><a href="/" >{t('navigation.home')}</a></li>
            {/* <li><a href="/programme">Programmes</a></li> */}
            <li><a href="/courses">{t('navigation.courses')}</a>
           
</li>

               

            <li><a href="/topic" >{t('navigation.core_issues')}</a></li>
           
            <li><a href="/discussions" >{t('navigation.discussions')}</a></li>
            {btn1}
            {btn2}

            </ul>
            </nav>
           
            </div>
            </div>
            </div>
            </div>
            

			
			
			</div>
			
			
            </header>

{this.state.profileInfo?''
:<div className="nav-alert">
<div className="container">
<div className="row">
<div className="col-md-12">
	<label className="lbl lbl-warning lbl-block"><span>{t('signup.incomplete_profile_msg')} <strong><a href="/usr/UpdateInfo">{t('heading.update')}</a></strong></span></label>
</div>
</div>
</div>
</div>

}


<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> Educately Assist</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
       <h4><i className="fa fa-phone"></i> <a href="call:911143894100">+91-11-43894100</a></h4>
       <h4><i className="fa fa-envelope"></i> <a href="mailto:educately@populationfoundation.in">educately@populationfoundation.in</a></h4>
            
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

  
            </div>
        )
    }
}

//export default withRouter(Navbar);
//export default withTranslation()(Navbar);
export default withRouter(withTranslation()(Navbar));
