import React,{useEffect,useState} from 'react'
import axios from "./axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from "./Spinner.js";

import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login';

import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';


export default function SocialLogin(props) {
  const [isLoading, setLoading] = useState(true);
  const { t} = useTranslation();

 


useEffect(() => {
    setLoading(false)
    function start() {
    gapi.client.init({
    clientId:"286038290260-u7cbtabqnrvhp9gehrrou39she4pjg40.apps.googleusercontent.com",
    scope: 'email',
      });
       }
      gapi.load('client:auth2', start);
       }, []);


const onFacebookSuccess = response => {
    socialLogin(response.name,response.email,response.accessToken,'facebook','Facebook')
    
};

const onGoogleSuccess = response => {
    socialLogin(response.profileObj.name,response.profileObj.email,response.accessToken,'google','Google')
};

const onFailure = response => {
      console.log('FAILED', response);
};

async function socialLogin(name,email,accessToken,platform,signup_via){
    var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language');
	let utm_campaign=localStorage.getItem('utm_campaign');
   var rdir=localStorage.getItem('rdir')==null?'/info/course/5dbbf82d7a803502886a2592':localStorage.getItem('rdir');
   //var rdir='/';
   const user = {accessToken:accessToken,platform:platform,language:language,utm_campaign:utm_campaign}
   

    //const userPosts =  await axios.post("/api/users/socialLogin",data)
    //console.log(userPosts)
    console.log(props.path)
    axios.post("/api/users/socialLogin", user)
   .then(res => {


    if (res.data.token)
       {
           localStorage.setItem('token', res.data.token);
           localStorage.setItem('username', res.data.username);
           localStorage.setItem('firstName', res.data.firstName);
           localStorage.setItem('_id', res.data._id);
           
           //this.history.replace(rdir)
           //this.history.pushState(null, 'login');
		   
	 if(props.path!=="")
		   {
			   	axios.post('/api/users/assign-course', {id: props.path,name:res.data.firstName,language:language}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + res.data.token
       }
   }).then(res => {
	 
	 
    });
	
		   }
		   
		
		if(res.data.profileInfo)
		{
			
			props.history.push(rdir)
			window.location.reload();
			props.userLoggedIn(true, res.data.firstName);			
		}
		else
		{
		localStorage.setItem('rdir', rdir)
		props.history.push('/usr/UpdateInfo')
		window.location.reload();
		props.userLoggedIn(true, res.data.firstName);		
		}
           
       }
       else {
           //Login failed, show error message
           localStorage.removeItem('token');
           localStorage.removeItem('username');
           
       }

    });

}

if (isLoading) {
  return <Spinner />;
}
return (
  <div>  
    <table align="center">
    <tbody>
                                    <tr>
                                       <td>
                                       <GoogleLogin
   clientId={"286038290260-u7cbtabqnrvhp9gehrrou39she4pjg40.apps.googleusercontent.com"}
  onSuccess={onGoogleSuccess}
  onFailure={onFailure}
  userInfoLabel="Google"
    />
                                        </td>  
       <td style={{"padding-left":"10px"}}>
        <FacebookLogin
        appId="391545178459284" //APP ID NOT CREATED YET
        fields="name,email,picture"
        callback={onFacebookSuccess}
		
        textButton="Facebook"
        cssClass="btn btn-info round-button socialsignupbtnfb"
        icon="fa fa-facebook"
      />

</td>
</tr>
</tbody>
</table>
    </div>
  );
}