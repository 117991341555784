import React,{useEffect,useState} from 'react'
import axios from "./axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from "./Spinner.js";

export default function ResumeLearning(props) {
  const [isLoading, setLoading] = useState(true);
  const [isloggedin, setloggedin] = useState(false);
  
  const [response, setResponse]=useState([])
  const { t} = useTranslation();

  const token = props.match.params.token;
  const id = props.match.params.id;
  const page = props.match.params.page;

 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

useEffect(() => {
    (async () => {
        try {
        
        const userPosts=   await axios.post('/api/users/login-by-token', {token: token}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        setResponse(userPosts.data)
        setLoading(false)
        } catch (err) {
          console.error(err.message);
        }
    })()
}, [])


if (isLoading) {
  return <Spinner />;
}
else
{
    console.log(response)
    let url='/courses';
    if(response.status)
    {
        let token = response.token
        localStorage.setItem('token', response.token);
        localStorage.setItem('username', response.username);
        localStorage.setItem('firstName', response.firstName);
        localStorage.setItem('_id', response._id);

        setloggedin(false);
    
        const query = new URLSearchParams(props.location.search);
        let utm_source = query.get('utm_source');
        let utm_campaign = query.get('utm_campaign');
        
        if(page=='course_info')
        {
        url='/info/course/'+id+'?utm_source='+utm_source+'&utm_campaign='+utm_campaign
        }
        if(page=='module')
        {
        url='/view/course/'+id+'?utm_source='+utm_source+'&utm_campaign='+utm_campaign
        }
        if(page=='topic')
        {
        url='/course/module/'+id+'/primary/?utm_source='+utm_source+'&utm_campaign='+utm_campaign
        }
    }
    else
    {
        url='/courses'
    }
    
    props.history.push(url)
	window.location.reload();
}
return (
  <div>     
       {token}{id}{page}
    </div>
  );
}