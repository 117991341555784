import React,{useEffect,useState} from 'react'
import axios from "../axios.js";
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner.js';

export default function EducatelyPartners() {
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse]=useState([])
  const { t} = useTranslation();

 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

useEffect(() => {
    (async () => {
        try {
        const userPosts = await axios.get("/api/static_page?p=EducatelyPartners&language="+language)
        setResponse(userPosts.data)
        setLoading(false)
        } catch (err) {
          console.error(err.message);
        }
    })()
}, [])


if (isLoading) {
  return <Spinner />;
}
return (
  <div>     
       <div dangerouslySetInnerHTML={{__html: response.data.content}} />
    </div>
  );
}