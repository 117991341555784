import React, {Component} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';
import $ from 'jquery'
import Moment from "moment";
import EasyEdit from 'react-easy-edit';
import { withTranslation } from 'react-i18next'

class Profile extends Component {
constructor(props) {
super(props);
this.state = {
profile: [],
courses: [],
verify:'',
title: "Resend activation link"
};


}



componentDidMount() {
	
	this.setState({ verify: "verify" });
   const token = localStorage.getItem('token');
   const username = localStorage.getItem('username');
   axios.get('/api/users/profile', {username: username}, {
       headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
       }
   }).then(res => {
      
        if (res.data.success)
        
            this.setState({
                profile: res.data.data,
               
            });
    });

 
    $(document).ready(function () {
        var imgSrc = $('#imgProfile').attr('src');
        function readURL(input) {

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $('#imgProfile').attr('src', e.target.result);
                };

                reader.readAsDataURL(input.files[0]);
            }
        }
        $('#btnChangePicture').on('click', function () {
            // document.getElementById('profilePicture').click();
            if (!$('#btnChangePicture').hasClass('changing')) {
                $('#profilePicture').click();
            }
            else {
                // change
            }
        });
        $('#profilePicture').on('change', function () {
            readURL(this);
            $('#btnChangePicture').addClass('changing');
            $('#btnChangePicture').attr('value', 'Confirm');
            $('#btnDiscard').removeClass('d-none');
            // $('#imgProfile').attr('src', '');
        });
        $('#btnDiscard').on('click', function () {
            // if ($('#btnDiscard').hasClass('d-none')) {
            $('#btnChangePicture').removeClass('changing');
            $('#btnChangePicture').attr('value', 'Change');
            $('#btnDiscard').addClass('d-none');
            $('#imgProfile').attr('src', imgSrc);
            $('#profilePicture').val('');
            // }
        });
    });

}


updateProfile(data)
{
    const token = localStorage.getItem('token');
    axios.put('/api/users/profile', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(res => {
		
		toast.info('You have successfully updated your information.', {
         position: toast.POSITION.BOTTOM_RIGHT
     }); 
	 
     });
}

verifyMail = () => {
    this.setState({ title: "" });

    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    axios.post('/api/users/verify-my-email', {username: username}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(res => {
             
             let err = ''
             if(res.data.status)
             {
             toast.success(res.data.message, {
                 position: toast.POSITION.TOP_CENTER
             });
         }
         else
         {
             toast.error(res.data.message, {
                 position: toast.POSITION.TOP_CENTER
             }); 
         }
             this.setState({
                 err: err
             });
         });
        
}

saveDistrict = (value) => {
    this.updateProfile({district:value});
}
saveState = (value) => {
    this.updateProfile({state:value});
}
saveEmlpymentSector = (value) => {
    this.updateProfile({employment_sector:value});
}

saveCurrentInstitute = (value) => {
    this.updateProfile({current_institute:value});
}

saveMobile = (value) => {
    this.updateProfile({mobile:value});
}


saveQualification = (value) => {
    this.updateProfile({qualification:value});
}
saveProfession = (value) => {
    this.updateProfile({profession:value});
}

savePreferredLanguage = (value) => {
    this.updateProfile({language_preference:value});
}


employer


 cancel = () => {}



render() {
  const {t} = this.props   
  
  var emailVerify=''
  
  if(this.state.profile.verifiedEmail===0)
  {
	  emailVerify=<div class="alert alert-danger">
        <a href="javascript:void(0)" onClick={this.verifyMail} class="btn btn-xs btn-danger pull-right">{this.state.title}</a>
        <strong>Email Unverified:</strong> We sent you an activation code on your email <i>{this.state.profile.username}</i> <br />Check your email and click on the link to verify.	
		</div>
  }


    return (
        <div>
   <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1 class="brcheading">{t('navigation.profile')}</h1>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('navigation.home')}</a></li>
                  <li class="active">{t('navigation.profile')}</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
   <div class="content content-bg21">
           
               <div class="container">
                
		
	{emailVerify}
	
               <div class="row">

			   
              
              

            <div class="col-7">
                <div class="profile-card">

                    <div class="card-body">
                        <div class="card-title mb-4">

                   
                            <div class="d-flex justify-content-start">
                                <div class="image-container">
                                    <img src={this.state.profile.avatar} id="imgProfile" style={{"width": "65px","height": "65px"}} class="img-thumbnail" />
                                    <div class="middle">
                                        {/* <input type="button" class="btn btn-secondary" id="btnChangePicture" value="Change" /> */}
                                        <input type="file"style={{"display": "none"}} id="profilePicture" name="file" />
                                    </div>
                                </div>
                                <div class="userData ml-3">
                                    <h2 class="d-block" style={{"font-size": "1.5rem", "font-weight": "bold"}}><a href="javascript:void(0);">{this.state.profile.firstName} {this.state.profile.lastName}</a></h2>
                                   <small><i>{t('heading.member_since')}: {Moment(this.state.profile.dateCreated).format('D MMM Y')}</i></small>
                                   
                                </div>
                                <div class="ml-auto">
                                    <input type="button" class="btn btn-primary d-none" id="btnDiscard" value="Discard Changes" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="basicInfo-tab" data-toggle="tab" href="#basicInfo" role="tab" aria-controls="basicInfo" aria-selected="true">{t('heading.profile_info')}</a>
                                    </li>
                                   
                                </ul>
                                <div class="tab-content ml-1" id="myTabContent">
                                    <div class="tab-pane fade show active" id="basicInfo" role="tabpanel" aria-labelledby="basicInfo-tab">
                                        

                                        <div class="row">
                                            <div class="col-sm-6">
                                            <label style={{"font-weight":"bold"}}>{t('heading.name')}</label>
                                            </div>
                                            <div class="col-sm-6">
                                            {this.state.profile.firstName}   {this.state.profile.lastName} 
                                            </div>
                                        </div>
                                        <hr />

                                        
                                        
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label style={{"font-weight":"bold"}}>{t('heading.email')}</label>
                                            </div>
                                           <div class="col-sm-6">
                                            {this.state.profile.username}
                                            
                                            {

                                                this.state.profile.verifiedEmail==1
                                                ? <span title="Verfied Email" > <i class="fa fa-check-circle"></i></span>
                                            : <span > <small> <a href="javascript:void(0)" onClick={this.verifyMail} style={{"color":"#dc3545"}} >{this.state.title}</a> </small> </span>
                                            }
                                            
                                            
                                            </div>
                                        </div>
                                        <hr />
										
										 <div class="row">
                                             <div class="col-sm-6">
                                            <label style={{"font-weight":"bold"}}>{t('signup.mobile')}</label>
                                            </div>
                                           <div class="col-sm-6">
                                            <EasyEdit
                                            type="text"
                                            onSave={this.saveMobile}
                                            onCancel={this.cancel}
                                            value={this.state.profile.mobile} 
                                            saveButtonLabel="Save"
                                            cancelButtonLabel="Cancel"
                                            attributes={{ name: "awesome-input", id: 1}}
                                            instructions=""
                                            />   
                                            </div>
                                        </div>
                                        <hr />
										
										
										 <div class="row">
                                             <div class="col-sm-6">
                                            <label style={{"font-weight":"bold"}}>{t('signup.institute_name')}</label>
                                            </div>
                                           <div class="col-sm-6">
                                            <EasyEdit
                                            type="text"
                                            onSave={this.saveCurrentInstitute}
                                            onCancel={this.cancel}
                                            value={this.state.profile.current_institute} 
                                            saveButtonLabel="Save"
                                            cancelButtonLabel="Cancel"
                                            attributes={{ name: "awesome-input", id: 1}}
                                            instructions=""
                                            />   
                                            </div>
                                        </div>
                                        <hr />
										
										<div class="row">
                                             <div class="col-sm-6">
                                                <label style={{"font-weight":"bold"}}>{t('signup.type_of_institution')}</label>
                                            </div>
                                            <div class="col-sm-6">
                                            <EasyEdit
  type="select"
  
  options={[
      {value: 'Government', label: 'Government'},
      {value: 'Private', label: 'Private'},
     
    ]}
      
      onSave={this.saveEmlpymentSector}
  value=  {this.state.profile.employment_sector} 
  placeholder="Type of institutions"
  instructions=""
/>

                                          
                                            </div>
                                        </div>
										
										
                                        <hr />
										
										
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label style={{"font-weight":"bold"}}>{t('signup.state')}</label>
                                            </div>
                                            <div class="col-md-6">
                                            <EasyEdit
  type="select"
  
  options={[
      {value: '1', label: 'Andhra Pradesh'},
      {value: '4', label: 'Andaman and Nicobar Islands'},
      {value: '2', label: 'Arunachal Pradesh'},
      {value: '3', label: 'Assam'},
      {value: '5', label: 'Bihar'},
      {value: '6', label: 'Chandigarh'},
      {value: '7', label: 'Chhattisgarh'},
      {value: '8', label: 'Dadar and Nagar Haveli'},
      {value: '9', label: 'Daman and Diu'},
      {value: '25', label: 'NCT OF Delhi'},
      {value: '18', label: 'Lakshadweep'},
      {value: '27', label: 'Puducherry'},
      {value: '10', label: 'Goa'},
      {value: '11', label: 'Gujarat'},
      {value: '12', label: 'Haryana'},
      {value: '13', label: 'Himachal Pradesh'},
      {value: '14', label: 'Jammu and Kashmir'},
      {value: '15', label: 'Jharkhand'},
      {value: '16', label: 'Karnataka'},
      {value: '17', label: 'Kerala'},
      {value: '19', label: 'Madhya Pradesh'},
      {value: '20', label: 'Maharashtra'},
      {value: '21', label: 'Manipur'},
      {value: '22', label: 'Meghalaya'},
      {value: '23', label: 'Mizoram'},
      {value: '24', label: 'Nagaland'},
      {value: '26', label: 'Odisha'},
      {value: '28', label: 'Punjab'},
      {value: '29', label: 'Rajasthan'},
      {value: '30', label: 'Sikkim'},
      {value: '31', label: 'Tamil Nadu'},
      {value: '32', label: 'Telangana'},
      {value: '33', label: 'Tripura'},
      {value: '34', label: 'Uttar Pradesh'},
      {value: '35', label: 'Uttarakhand'},
      {value: '36', label: 'West Bengal'}
    ]}
      
      onSave={this.saveState}
  value=  {this.state.profile.state_id} 
  placeholder="Select your state"
  instructions=""
/>

                                          
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-sm-6">
                                            <label style={{"font-weight":"bold"}}>{t('signup.district')}</label>
                                            </div>
                                            <div class="col-sm-6">
                                            <EasyEdit
                                            type="text"
                                            onSave={this.saveDistrict}
                                            onCancel={this.cancel}
                                            value={this.state.profile.district} 
                                            saveButtonLabel="Save"
                                            cancelButtonLabel="Cancel"
                                            attributes={{ name: "awesome-input", id: 1}}
                                            instructions=""
                                            />   

                                            
                                            </div>
                                        </div>
										 <hr />
										 <div class="row">
                                           <div class="col-sm-6">
                                            <label style={{"font-weight":"bold"}}>{t('signup.preferred_language')}</label>
                                            </div>
                                            <div class="col-sm-6">
                                           
										   <EasyEdit
  type="select"
  
  options={[
      {value: 'en', label: 'English'},
      {value: 'hi', label: 'हिन्दी'},
    ]}
      
      onSave={this.savePreferredLanguage}
  value=  {this.state.profile.language_preference} 
  placeholder="Type of institutions"
  instructions=""
/>

                                            
                                            </div>
                                        </div>
                                     

                                    </div>
                                   
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>


           </div>
           </div>
</div>
)
}
}
export default withTranslation()(Profile);