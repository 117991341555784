import React,{useEffect,useState} from 'react'
import {useAxios} from "../useAxios.js";
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import Spinner from "../Spinner.js";

export default function TopicIndex() {
  const { t} = useTranslation();
  var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')       
  const { response, loading, error } = useAxios({
      method: 'get',
      url: '/api/topic?language='+language,
      headers: JSON.stringify({ }),
      body: JSON.stringify({
      }),
  });
  const [data, setData] = useState([]);
  useEffect(() => {
      if (response !== null) {
          setData(response);
      }
  }, [response]);

  function loadMore(e) {
        e.preventDefault();
        $(".Issuecontent:hidden").slice(0, 4).slideDown();
        if($(".Issuecontent:hidden").length === 0) {
          $("#loadMore").text("").addClass("noContent");
        }
  }

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="App">{error.message}</div>;
  }

return (
<div className='App'>
   <p class="text-center">
      {t('portal.core_issues_desc')}      
   </p>
   <br />
   <div className="row">
      {
      data.map((lesson, i) => (
      <div class={i >
         3 ? "col-sm-3 col2 Issuecontent" : "col-sm-3 col2"}>
         <a href={"/topic/" + lesson.slug}>
            <div class="[ info-card ]">
               <img style={{"width":"75%","padding-top":"15px"}} src={lesson.image_url} alt=""  />
               <div class="[ info-card-details ] animate">
                  <div class="[ info-card-header ]">
                     <h1> {lesson.name} </h1>
                  </div>
                  <div class="[ info-card-detail ]">
                     <p class="shortDesc"> {lesson.description}</p>
                     <p> 
         <a href={"/topic/" + lesson.slug} class="btn btn-sm btn-register">{t('portal.know_more')}</a></p>
         </div>
         </div>
         </div>
         </a>
      </div>
      ))
      }
      <a href="#"  onClick={loadMore} id="loadMore">{t('portal.load_more')} <i class="fa fa-arrow-right"></i>   </a>
   </div>
</div>
  );
};