import React, {Component} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';
export default class Cobranded extends Component {
constructor(props) {
super(props);
this.state = {
tag_info:[]
};
}
 componentDidMount() {
    let tag=this.props.match.params.tag
    axios.get('/api/co_branded_tag?tag='+tag, {})
            .then(res => {
				let course_id=res.data.data.course_id
                let default_language=res.data.data.default_language
				//let partner_tag=res.data.partner_tag
				this.props.history.push('../info/course/'+course_id+'?utm_campaign='+tag+'&utm_source='+tag+'&language='+default_language)
				window.location.reload();
				
                this.setState({
                    tag_info: res.data
                });
            }); 
}



render() {
return (
<div>
   
</div>
)
}
}