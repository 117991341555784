import React, {Component} from 'react'
import { withTranslation} from 'react-i18next'
import axios from "../axios.js";
import { toast } from 'react-toastify';

class AboutUs extends Component {
constructor(props) {
super(props);
this.state = {
   districts:[],
   subjects:[],
   profile: [],
   userLoggedIn: false,
   current_institute:'',
   employment_sector:'',
   state:'',
   state_id:'',
   district:'',
   language_preference:'',
   place_of_residence:'',
   gender:'',
   age:'',
   profession:'',
   school_type:'',
   teaching_subject:'',
   teaching_subject_oth:'',
   mobile:'',
   profile_status:null
};
}
//Check if user authenticated
 componentWillMount() {

 } 

    
	componentDidMount() {
   
   const token = localStorage.getItem('token');
   const username = localStorage.getItem('username');
   axios.post('/api/users/profile', {}, {
       headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
       }
   }).then(res => {
      
        if (res.data.success)
       
            this.setState({
                profile: res.data.data,
				state:res.data.data.state,
				state_id:res.data.data.state_id,
				current_institute:res.data.data.current_institute,
				employment_sector:res.data.data.employment_sector,
				district:res.data.data.district,
				language_preference:res.data.data.language_preference,
				mobile:res.data.data.mobile,
				gender:res.data.data.gender,
            age:res.data.data.age,
            place_of_residence:res.data.data.place_of_residence,
            profession:res.data.data.profession,
				school_type:res.data.data.school_type,
				teaching_subject:(res.data.data.teaching_subject!==null && res.data.data.teaching_subject.indexOf(':') !== -1)?'Other':res.data.data.teaching_subject,
				teaching_subject_oth:(res.data.data.teaching_subject!==null && res.data.data.teaching_subject.indexOf(':') !== -1)?res.data.data.teaching_subject.split(':')[1]:'',
				profile_status:res.data.data.is_profile_complete,
               
            });
			this.loadDistricts(res.data.data.state_id)
			this.loadSubjects()
    });
    }
	

loadDistricts(state_id)
{
   axios.post("/api/getDistricts", {state_id:state_id}).then(res2 => {
      this.setState({
         districts: res2.data.data
      });
    });
}

loadSubjects()
{
   axios.get("/api/getSubjects", {}).then(res2 => {
      this.setState({
         subjects: res2.data.data
      });
    });
}

enroll= (token,name) => (e) =>{
   var rdir='/view/course/'+this.props.path;
   axios.post('/api/users/assign-course', {id: this.props.path,name:name}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + token
       }
   }).then(res => {
	 
		this.props.history.push(rdir)
		window.location.reload();		
		this.props.userLoggedIn(true, name);	
    });
   
}

handleChange = (event) => {
  this.setState({state_id: event.target.value});
   this.setState({value: event.target.value});
   this.loadDistricts(event.target.value)
}

handleSubjectChange = (event) => {
  this.setState({teaching_subject: event.target.value});
  this.setState({value: event.target.value});
  
  this.setState({teaching_subject_oth: ''});
  
  
  if(event.target.value=='Other')
  {
	  
  }
}




updateProfile = (e) => {
	
	var rdir=localStorage.getItem('rdir')==null?'/profile':localStorage.getItem('rdir');
	
    e.preventDefault();
	
	let state=e.target.state.value;
    let district=e.target.district.value;
	let current_institute=e.target.current_institute.value;
    let employment_sector=e.target.employment_sector.value;
	let mobile=e.target.mobile.value;
	let preferred_language=e.target.preferred_language.value;
	let gender=e.target.gender.value;
   let age=e.target.age.value;
   let profession=e.target.profession.value;
   let place_of_residence=e.target.place_of_residence.value;
   
	
	
	
	if(state==='')
   {
      toast.error('Please select your state of residence', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else if(district==='')
   {
      toast.error('Please select your district', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else if(current_institute==='')
   {
      toast.error('Please enter your school/institutes name', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
   else if(employment_sector==='')
   {
      toast.error('Please select your Type of Institution', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
   else if(mobile==='' )
   {
      toast.error('Please enter your mobile number', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
   else if(mobile.length!==10)
   {
      toast.error('Please enter a valid mobile number', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
    else if(gender==='' )
   {
      toast.error('Please select your gender', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }

   else if(age==='' )
   {
      toast.error('Please add your age', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }

   else if(profession==='' )
   {
      toast.error('Please select your profession', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
    
   
   
   else
   {
	   
	   
	   const token = localStorage.getItem('token');
      let data = {state:state,district:district,current_institute:current_institute,employment_sector:employment_sector,language_preference:preferred_language,mobile:mobile,gender:gender,age:age,profession:profession,place_of_residence:place_of_residence,is_profile_complete:1}

    axios.put('/api/users/profile', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(res => {
		
		this.props.history.push(rdir)
		window.location.reload();
		
     }); 
   }	
}


render() {
   const {t} = this.props
   
   const errMessage = !Array.isArray(this.state.err) ? (
	<div className='red-text center'>{this.state.err}</div>
	)
	: (this.state.err.map(err => {
	return (
	<div className='red-text center'>{err}</div>
	)
	}));
	
	
	
	
	
     return (
        <div>
        <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
          
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('heading.home')}</a></li>
                  <li class="active">{t('navigation.profile')}</li>
               </ol>
            </div>
         </div>
		 
		 <div class="row">
		 <div class="col-md-1">
      

       </div>
		 
		 <div class="col-md-10">
		 
		 <div  class="signup">
	<h2 class="signup-page-title">My Profile</h2>
   
   <p class="divider-text">
   <span class="bg-white">
  
   
   {
	   this.state.profile_status==1?<span><i class="fa fa-check-circle"></i> {t('signup.complete_profile_msg')}</span>:<span>{t('signup.incomplete_profile_msg')}</span>
   }
   </span>
   
   
   </p>
   <p> {errMessage}</p>
   <div class="row">
      <div class="col-md-4">

      <div class="text-center">
        <img style={{"border-radius": "100px","width":"100px"}} src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png" class="avatar img-circle img-thumbnail" alt="avatar" />
        
        <h3 class="signup-page-title1">{this.state.profile.firstName} {this.state.profile.lastName}</h3>
        <small>{this.state.profile.username} {
	   this.state.profile.verifiedEmail==1?<span><i class="fa fa-check-circle"></i></span>:<span></span>
   }
        </small>

      </div>

      </div>
      <div class="col-md-8">
       <form id="userForm" onSubmit = {this.updateProfile}>
     
     <div class="form-row">
    
        <div class="form-group col-md-6 ">
       <label class="reg-label">{t('signup.state')}*</label>   
           <select class="form-control reginput" id="state" value={this.state.state_id} onChange={this.handleChange} >
              <option value="">{t('signup.state')}</option>
              
           <option value="1">Andhra Pradesh</option>
              <option value="2" >Arunachal Pradesh</option>
              <option value="3">Assam</option>
              <option value="4">Andaman &amp; Nicobar Islands</option>
              <option value="5" >Bihar</option>
              <option value="6">Chandigarh</option>
              <option value="7">Chattisgarh</option>
              <option value="8">Dadra &amp; Nagar Haveli</option>
              <option value="9">Daman &amp; Diu</option>
              <option value="10">Goa</option>
              <option value="11">Gujarat</option>
              <option value="12">Haryana</option>
              <option value="13">Himachal Pradesh</option>
              <option value="14">Jammu &amp; Kashmir</option>
              <option value="15">Jharkhand</option>
              <option value="16">Karnataka</option>
              <option value="17">Kerala</option>
              <option value="18">Lakshadweep</option>
              <option value="19">Madhya Pradesh</option>
              <option value="20">Maharashtra</option>
              <option value="21">Manipur</option>
              <option value="22">Meghalaya</option>
              <option value="23">Mizoram</option>
              <option value="24">Nagaland</option>
              <option value="25">NCT OF Delhi</option>
              <option value="26">Odisha</option>
              <option value="27">Puducherry</option>
              <option value="28">Punjab</option>
              <option value="29">Rajasthan</option>
              <option value="30">Sikkim</option>
              <option value="31">Tamil Nadu</option>
              <option value="32">Telangana</option>
              <option value="33">Tripura</option>
              <option value="34">Uttar Pradesh</option>
              <option value="35">Uttarakhand</option>
              <option value="36">West Bengal</option>
           </select>
        </div>
        <div class="form-group col-md-6">
      <label class="reg-label">{t('signup.district')}*</label>  
           <select  class="form-control reginput" id="district" value={this.state.district} onChange={(e) => this.setState({ district: e.target.value })} >
              <option value="">{t('signup.district')}</option>
              {this.state.districts.map((row) => 
              <option key={row.district} value={row.district}>{row.district}</option>
              )}
           </select>
        </div>
     </div>
    
 
  
  
  <div class="form-row">
     
  <div class="form-group col-md-6">
      <label class="reg-label">{t('signup.mobile')}*</label> 
       
      
      
     <input type="text" id="mobile" class="form-control reginput" value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value })} maxlength="10"

onKeyPress={(event) => {
       if (!/[0-9]/.test(event.key)) {
         event.preventDefault();
       }
     }} 
     />
  
     </div>

     <div class="form-group col-md-6 ">
     <label class="reg-label">{t('signup.age')}*</label> 
     <input type="number" id="age" class="form-control reginput" value={this.state.age} onChange={(e) => this.setState({ age: e.target.value })} maxlength="10"

onKeyPress={(event) => {
   if (!/[0-9]/.test(event.key)) {
     event.preventDefault();
   }
 }} 
 />
  </div>   
     
  </div> 
  
   <div class="form-row">
     
  <div class="form-group col-md-6">
      <label class="reg-label">{t('signup.gender')}*</label> 
       
      
     <select class="form-control reginput" id="gender" value={this.state.gender} onChange={(e) => this.setState({ gender: e.target.value })} >
     <option value="">-Select-</option>                       
     <option value="Male">Male</option>
     <option value="Female">Female</option>
     <option value="Other">Other</option>
       </select>
  
     </div>

     <div class="form-group col-md-6 ">
     
     <label class="reg-label">{t('signup.profession')}*</label> 
       <select class="form-control reginput" id="profession" value={this.state.profession} onChange={(e) => this.setState({ profession: e.target.value })} >
    
               <option value="">{t('signup.profession')}*</option>
               <option>Educators: School teachers/social workers </option>
               <option>Counsellors: mental health/career counsellors/ child and adolescent counsellors </option>
               <option>Health care providers: doctors/nurses/community health workers/midwives</option>
               <option>NGO staff: Program officers, Project managers, Community health workers</option>
               <option>Govt officials: policy makers/health department officials</option>
               <option>Adolescents/young people</option>
               <option>Guardians: parents/caregivers</option>
               <option>Self-employed</option>
               <option>Professionals</option>
               <option>Researchers</option>
               <option>Others</option>
     
       </select>
     
     </div> 
     
  </div>  
  
  

  <div class="form-row">
     
     <div class="form-group col-md-6">
         <label class="reg-label">{t('signup.institute_name')}</label> 
          <input type="text" id="current_institute" onChange={e => this.setState({ current_institute : e.target.value })}  value={this.state.current_institute} class="form-control reginput" placeholder={t('signup.institute_name')+'*'} />
        </div>
   
        <div class="form-group col-md-6 ">
        <label class="reg-label">{t('signup.type_of_institution')}</label> 
          <select class="form-control reginput" id="employment_sector" value={this.state.employment_sector} onChange={(e) => this.setState({ employment_sector: e.target.value })} >
   
   <option value="">{t('signup.type_of_institution')}</option>
                                  
   <option>Government</option>
   <option>Private</option>
   
   
                                </select>
        </div> 
        
     </div> 
  
   
     <div class="form-row">
     <div class="form-group col-md-6">
         
         <label class="reg-label">{t('signup.place_of_residence')}</label> 
              
              <select class="form-control reginput" id="place_of_residence" value={this.state.place_of_residence} onChange={(e) => this.setState({ place_of_residence: e.target.value })} >
              <option value="">-Select-</option>                       
              <option>Urban</option>
              <option>Rural</option>
                </select>
            </div>

     <div class="form-group col-md-6">
         
     <label class="reg-label">{t('signup.preferred_language')}</label> 
          
          <select class="form-control reginput" id="preferred_language" value={this.state.language_preference} onChange={(e) => this.setState({ language_preference: e.target.value })} >
          <option value="">-Select-</option>                       
          <option value="en">English</option>
          <option value="hi">हिन्दी</option>
            </select>
        </div>
   
        <div class="form-group col-md-6 ">
        
     
        <div class={this.state.teaching_subject=='Other'?'':'dnone'}>
        <label class="reg-label">{t('signup.subject_oth')}</label> 		 
        <input type="text" id="teaching_subject_oth" class="form-control reginput" value={this.state.teaching_subject_oth} onChange={(e) => this.setState({ teaching_subject_oth: e.target.value })} maxlength="25" />
        </div>
        
        
        </div> 
        
     </div>  
    
     <div class="form-group">
     </div>
     <div class="form-group">
     </div>
     <div class="form-group">
        <button type="submit" class="btn btn-primary btn-block"> {t('heading.update')}  </button>
     </div>
     <div class="row">
        <div class="col-md-12">
          
           <div class="form-group row">
              <div class="col-md-12 text-center">
                 <table align="center">
                    
                 </table>
              </div>
           </div>
        </div>
     </div>
    
  </form>  
      </div>
   </div>

   
	</div>
		 
		 </div>
		 
		 </div>
		 <br />
		 <br />
		
      </div>
   </div>
   
    
    </div>
         )
         }
         }


export default withTranslation()(AboutUs);