import React, {Component} from 'react'

export default class NoMatch extends Component {
constructor(props) {
super(props);
this.state = {

};
}

 componentWillMount() {

}

render() {

return (
<div>
  
   <div class="content content-bg4">
            <p class="text-center header">404  <strong>Page Not Found</strong></p>
                   
                   <p align="center"><img src="/404.png" alt="" /></p> 
                   
             
              <p align="center">We can't find the page you're looking for.</p> 
              <p align="center"><a href="/" class="btn btn-primary">Back to homepage</a></p> 

           </div>
</div>
)
}
}