import React, { Component } from "react";
import Moment from "moment";
import axios from "../axios.js";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Chatbot from "../Chatbot";
import $ from "jquery";

class CourseModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: [],
      course_module: [],
      myTopics: [],
      enrollStatus: "",
      progress: 0,
      enrollmentInfo: [],
      modules: [],
      ratingDesc: "Rate your experience",
      feedback: "",
      curRating: null,
      eid: null,
      discussion: [],
      course_status: "",
      showRatingOptions:false
    };
  }

  componentDidMount() {
    $("#footerwidgets").hide();
    //$(".languageToggle").hide();
  }

  
  handleClick = (param) => e => {
    var url="https://api.educately.org/api/certificate/pdf/isrh/"+param+'/'+this.state.enrollmentInfo.certificateId;
    window.open(url);
  }

  async componentWillMount() {
    document.title =  "My Course";

    const token = localStorage.getItem("token");

    var language =
      localStorage.getItem("language") == null
        ? "en"
        : localStorage.getItem("language");

    axios
      .post(
        "/api/courses/modules/" +
          this.props.match.params.id +
          "?language=" +
          language,
        { language: language },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.status)
          this.setState({
            myTopics: res.data.moduleTopic_info,
            enrollStatus: res.data.enrollStatus,
            enrollmentInfo: res.data.enrollment_info[0],
            progress: res.data.enrollment_info[0].progress,
            eid: res.data.enrollment_info[0].id,
            curRating: res.data.enrollment_info[0].rating,
            feedback: res.data.enrollment_info[0].feedback,
            course_status: res.data.enrollment_info[0].status,

            discussion: res.data.discussion,
            course: res.data.course_info,

            modules: res.data.module_resources,
            summary_lock: res.data.summary_lock,
            discussion_tab: res.data.discussion_tab,
            resources_tab: res.data.resources_tab,
          });
      });
  }

  rating(rating) {
    this.setState({ ratingDesc: "You have rated " + rating + "/5" });
    this.setState({ curRating: rating });

    const token = localStorage.getItem("token");
    axios
      .put(
        "/api/courses/rating/" + this.props.match.params.id,
        { rating: rating },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res3) => {});
      this.setState({
        showRatingOptions: true
      })
      
  }

  userFeedback = (e) => {
    e.preventDefault();
    let feedback = e.target.review_feedback.value;


    //handle checkbox
var platform_rating='';
var checkbox = $("#feedback").find("input[type=radio]");
$.each(checkbox, function(key2, val2) {
 
	if($(val2).is(':checked'))
	{
		platform_rating=$(val2).attr('value');
	}
});



    if (feedback === "") {
      toast.warning("Feedback cannot be blank", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const token = localStorage.getItem("token");
      axios
        .put(
          "/api/courses/feedback/" + this.props.match.params.id,
          { feedback: feedback,platform_rating:platform_rating },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res4) => {
          toast.success(res4.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  requestCertificate = () => {
    console.log(this.state.enrollmentInfo);
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    axios
      .post(
        "/api/checkAuth",
        { username: username },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((resU) => {
        if (resU.data.status && resU.data.profileInfo) {
          axios
            .put(
              "/api/courses/completed_status/" + this.props.match.params.id,
              { eid: this.state.eid },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((res4) => {
              window.location.reload();
            });
        } else {
          toast.warning(
            "You have a incomplete profile. Kindly complete it to proceed further.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
      });
  };

  render() {
    const { t } = this.props;

    const disucsion = this.state.discussion.map((row) => {
      return (
        <div class="bg-white border-shadow pad15 mb10">
          <p class="comdesc">
            <span dangerouslySetInnerHTML={{ __html: row.description }} />
          </p>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <span class="comment_details">
                {" "}
                <a
                  class="color-dark comment_details"
                  href={"/course_discussions/view/" + row.slug}
                >
                  {" "}
                  {row.total_comments} {t("heading.comments")}{" "}
                </a>
              </span>
            </div>

            <div class="col-md-6 text-right">
              <span class="comment_details">
                <a
                  class="myCourses comment_details"
                  href={"/course_discussions/view/" + row.slug}
                >
                  <i class="fa fa-comment"></i> {t("heading.add_a_comment")}{" "}
                </a>
              </span>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div class="cdivh">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="float-left">
                  <ol class="breadcrumb">
                    <li>
                      <a href="/">{t("navigation.home")}</a>
                    </li>
                    <li>
                      <a href={"/dashboard"}>{t("navigation.my_courses")}</a>
                    </li>
                    <li>
                      <a href={"/info/course/" + this.props.match.params.id}>
                        {this.state.course.name}
                      </a>
                    </li>
                    <li class="active">{this.state.enrollmentInfo.status}</li>
                  </ol>
                  <p>
                    <span class="label label-large label-important arrowed-in-right arrowed-in">
                      {this.state.course_status}
                    </span>
                  </p>
                  <h1 class="heading" align="left">
                    {" "}
                    <a href={"/info/course/" + this.props.match.params.id}>
                      {this.state.course.name}
                    </a>
                  </h1>
                </div>
                <div
                  class="float-right text-right"
                  style={{ "padding-top": "15px" }}
                >
                  <a
                    class="btn btn-social social-facebook"
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=https://educately.org/info/course/" +
                      this.props.match.params.id +
                      "&quote=I am taking " +
                      this.state.course.name +
                      " programme on educately.org Check it out"
                    }
                  >
                    <i class="fa fa-facebook"></i>
                  </a>
                  <a
                    class="btn btn-social social-twitter"
                    href={
                      "https://twitter.com/intent/tweet?text=I am taking " +
                      this.state.course.name +
                      " programme with @EducatelyOrg Check it out%21%20https://educately.org/info/course/" +
                      this.props.match.params.id
                    }
                  >
                    <i class="fa fa-twitter"></i>
                  </a>
                  <a
                    class="btn btn-social social-linkedin"
                    href={
                      "https://www.linkedin.com/sharing/share-offsite/?url=https://educately.org/info/course/" +
                      this.props.match.params.id
                    }
                  >
                    <i class="fa fa-linkedin"></i>
                  </a>
                  <a
                    class="btn btn-social social-whatsapp"
                    data-action={
                      "https://educately.org/info/course/" +
                      this.props.match.params.id
                    }
                    href={
                      "whatsapp://send?text=I am taking " +
                      this.state.course.name +
                      " programme on educately.org Check it out. https://educately.org/info/course/" +
                      this.props.match.params.id
                    }
                  >
                    <i class="fa fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                {this.state.course_status === "Completed" && this.state.course.module_level_certificate==0 ? (
                  <div class="alert alert-info">
                    <a
                      href={
                        "/certificate/" +
                        this.state.enrollmentInfo.certificateId
                      }
                      class="btn btn-xs btn-primary pull-right"
                    >
                      {t("heading.view_certificate")}
                    </a>
                    {t("heading.view_certificate_msg1")}
                    <br />
                    {t("heading.view_certificate_msg2")}
                  </div>
                ) : (
                  ""
                )}
                {this.state.progress == 100 &&
                this.state.course_status !== "Completed" && this.state.course.module_level_certificate==0 ? (
                  <div class="alert alert-success">
                    {this.state.course.course_completion_review}
                    {this.state.course.course_completion_review == 1 ? (
                      <p>
                        {t("heading.view_certificate_msg1")}{" "}
                        {t("heading.assement_under_review_msg")}
                      </p>
                    ) : (
                      <p>
                        {t("heading.view_certificate_msg1")}
                        <button
                          class="btn btn-sm btn-primary pull-right"
                          onClick={this.requestCertificate}
                        >
                          {t("heading.request_certificate")}
                        </button>
                      </p>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#home">
                      {t("heading.modules_and_lessons")}
                    </a>
                  </li>
                  {this.state.discussion_tab == "Yes" ? (
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#menu1">
                        {t("navigation.discussions")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {this.state.resources_tab == "Yes" ? (
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#menu2">
                        {t("heading.resources")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <div class="tab-content bg-white border-shadow">
                  <div id="home" class="container tab-pane active">
                    <br />
                    <div class="row" id="about">
                      <div class="col-md-12 ">
                        <div class="row">
                          <div class="col-md-6"> 
                          {this.state.course_status === "Enrolled" ? (
                              <p
                                class="text-left"
                                style={{ "padding-top": "10px" }}
                              >
                                {" "}
                                <a
                                  href={
                                    "/course/module/" +
                                    this.state.enrollmentInfo.current_topic +
                                    "/primary"
                                  }
                                >
                                  « {t("heading.resume_from_where_you_left")} »
                                </a>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="col-md-6">
                            {this.state.course_status === "Enrolled" ? (
                              <p
                                class="text-right"
                                style={{ "padding-top": "10px" }}
                              >
                              
                              <a id="acollapse" href="javascript:jQuery('div .panel-collapse').collapse('hide'); jQuery('#acollapse').hide();jQuery('#aexpand').show();">Collapse All</a>

    <a id="aexpand" href="javascript:jQuery('div .panel-collapse').collapse('show'); jQuery('#aexpand').hide(); jQuery('#acollapse').show();" style={{"display": "none"}}>Expand All</a>

                              
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="panel-group" id="accordion">
                          {this.state.modules.map((item, index) => (
                            <div
                              class="panel panel-default"
                              id={index}
                              key={index}
                            >
                              <div class="panel-heading">
                                <h4 class="panel-title">
                                  <a
                                    data-toggle="collapse"
                                    data-target={"#collapse" + index}
                                    href={"#collapse" + index}
                                    class="collapse in show"
                                    aria-expanded="false"
                                  >
                                    {item.module_name}
                                  </a>
                                </h4>
                              </div>
                              <div
                                id={"collapse" + index}
                                class="panel-collapse collapse in show"
                              >
                                <div class="panel-body ModuleTopic">
                                  {this.state.myTopics.map((c, i) => (
                                    <div key={i}>
                                      {c.module_name === item.module_name ? (
                                        <div class="row">
                                          <div class="col-md-12">
                                            <h3 class="video-title">
                                              {c.slug !== null ? (
                                                <a
                                                  href={
                                                    "/course/module/" +
                                                    c.slug +
                                                    "/primary"
                                                  }
                                                >
                                                  {c.name}
                                                </a>
                                              ) : (
                                                <span class="txtLight">
                                                  {c.name}
                                                </span>
                                              )}
                                              <span class="pull-right">
                                               
                                                
&nbsp;
                                                {c.status === "Completed" &&  item.module_level_certificate==0 ? (
                                                  <i class="fa fa-check-circle icon-Completed"></i>
                                                ) : (
                                                  ""
                                                )}

                                                {c.status === "Completed" && item.module_level_certificate==1 ? (
                                                  <span><a onClick={this.handleClick(c.slug)} href="javascript:void(0)"> <i class="fa fa-certificate icon-Completed"></i> Certificate</a></span>
                                                ) : (
                                                  ""
                                                )}

                                                {c.status === "Locked" ? (
                                                  <i
                                                    title={t(
                                                      "heading.locked_module"
                                                    )}
                                                    class="fa fa-lock "
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}

                                                {c.status === "Started" ||
                                                c.status === "Pending" ? (
                                                  <a
                                                    class="btn btn-start"
                                                    href={
                                                      "/course/module/" +
                                                      c.slug +
                                                      "/primary"
                                                    }
                                                  >
                                                    <i class="fa fa-play"></i>{" "}
                                                    {t("heading.start")}
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            </h3>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="menu1" class="container tab-pane fade">
                    <br />
                    <h3>{t("navigation.discussions")}</h3>
                    <hr />
                    <p>{this.state.course.discussion_desc}</p>
                    {disucsion}

                    <br />
                  </div>

                  <div id="menu2" class="container tab-pane fade">
                    <br />
                    <h3>{t("heading.resources")}</h3>
                    <hr />
                    <p>{this.state.course.resources_desc}</p>

                    <ul class="list-group">
                      {this.state.modules.map((c, i) => (
                        <li class="list-group-item list-group-item-light">
                          {c.module_name}{" "}
                          <span class="float-right">
                            <a
                              class="btn btn-start-light"
                              href={c.resources}
                              target="_blank"
                            >
                              <i class="fa fa-download"></i> View
                            </a>
                          </span>
                        </li>
                      ))}
                    </ul>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4 padt40t">
                <div class="bg-white border-shadow">
                  <br />
                  <br />

                  <div
                    class="progress2 mx-auto"
                    data-value={this.state.progress}
                  >
                    <span class="progress-left">
                      <span class="progress-bar border-danger"></span>
                    </span>
                    <span class="progress-right">
                      <span class="progress-bar border-danger"></span>
                    </span>
                    <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                      <div class="h2 font-weight-bold text-center">
                        {" "}
                        {this.state.progress}
                        <sup class="small">%</sup>
                        <div class="small text-center">Progress</div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="ratingbox">
                        <h5>{t("heading.how_would_you_rate_this_program")}</h5>
                        <fieldset class="rating">
                          {this.state.curRating === 5 ? (
                            <input
                              type="radio"
                              id="star5"
                              checked
                              name="rating"
                              value="5"
                              onClick={() => this.rating(5)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star5"
                              name="rating"
                              value="5"
                              onClick={() => this.rating(5)}
                            />
                          )}
                          <label
                            class="full"
                            for="star5"
                            title="Awesome - 5 stars"
                          ></label>
                          {this.state.curRating === 4.5 ? (
                            <input
                              type="radio"
                              id="star4half"
                              checked
                              name="rating"
                              value="4 and a half"
                              onClick={() => this.rating(4.5)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star4half"
                              name="rating"
                              value="4 and a half"
                              onClick={() => this.rating(4.5)}
                            />
                          )}
                          <label
                            class="half"
                            for="star4half"
                            title="Pretty good - 4.5 stars"
                          ></label>
                          {this.state.curRating === 4 ? (
                            <input
                              type="radio"
                              id="star4"
                              checked
                              name="rating"
                              value="4"
                              onClick={() => this.rating(4)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star4"
                              name="rating"
                              value="4"
                              onClick={() => this.rating(4)}
                            />
                          )}
                          <label
                            class="full"
                            for="star4"
                            title="Good - 4 stars"
                          ></label>
                          {this.state.curRating === 3.5 ? (
                            <input
                              type="radio"
                              id="star3half"
                              checked
                              name="rating"
                              value="3 and a half"
                              onClick={() => this.rating(3.5)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star3half"
                              name="rating"
                              value="3 and a half"
                              onClick={() => this.rating(3.5)}
                            />
                          )}
                          <label
                            class="half"
                            for="star3half"
                            title="Okay - 3.5 stars"
                          ></label>
                          {this.state.curRating === 3 ? (
                            <input
                              type="radio"
                              id="star3"
                              checked
                              name="rating"
                              value="3"
                              onClick={() => this.rating(3)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star3"
                              name="rating"
                              value="3"
                              onClick={() => this.rating(3)}
                            />
                          )}
                          <label
                            class="full"
                            for="star3"
                            title="Okay - 3 stars"
                          ></label>
                          {this.state.curRating === 2.5 ? (
                            <input
                              type="radio"
                              id="star2half"
                              checked
                              name="rating"
                              value="2 and a half"
                              onClick={() => this.rating(2.5)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star2half"
                              name="rating"
                              value="2 and a half"
                              onClick={() => this.rating(2.5)}
                            />
                          )}
                          <label
                            class="half"
                            for="star2half"
                            title="Bad - 2.5 stars"
                          ></label>
                          {this.state.curRating === 2 ? (
                            <input
                              type="radio"
                              id="star2"
                              checked
                              name="rating"
                              value="2"
                              onClick={() => this.rating(2)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star2"
                              name="rating"
                              value="2"
                              onClick={() => this.rating(2)}
                            />
                          )}
                          <label
                            class="full"
                            for="star2"
                            title="Bad - 2 stars"
                          ></label>
                          {this.state.curRating === 1.5 ? (
                            <input
                              type="radio"
                              id="star1half"
                              checked
                              name="rating"
                              value="1 and a half"
                              onClick={() => this.rating(1.5)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star1half"
                              name="rating"
                              value="1 and a half"
                              onClick={() => this.rating(1.5)}
                            />
                          )}
                          <label
                            class="half"
                            for="star1half"
                            title="Meh - 1.5 stars"
                          ></label>
                          {this.state.curRating === 1 ? (
                            <input
                              type="radio"
                              id="star1"
                              checked
                              name="rating"
                              value="1"
                              onClick={() => this.rating(1)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="star1"
                              name="rating"
                              value="1"
                              onClick={() => this.rating(1)}
                            />
                          )}
                          <label
                            class="full"
                            for="star1"
                            title="Worst - 1 star"
                          ></label>
                          {this.state.curRating === 0.5 ? (
                            <input
                              type="radio"
                              id="starhalf"
                              checked
                              name="rating"
                              value="half"
                              onClick={() => this.rating(0.5)}
                            />
                          ) : (
                            <input
                              type="radio"
                              id="starhalf"
                              name="rating"
                              value="half"
                              onClick={() => this.rating(0.5)}
                            />
                          )}
                          <label
                            class="half"
                            for="starhalf"
                            title="Worst - 0.5 stars"
                          ></label>
                        </fieldset>
                        <br />
                        <br />
                        <p>
                          {this.state.curRating == null
                            ? t("heading.rate_your_experience")
                            : t("heading.you_have_rated") +
                              " " +
                              this.state.curRating +
                              "/5"}
                        </p>
                        <form id="feedback" onSubmit={this.userFeedback}>
                        <div class={this.state.showRatingOptions?'':'dnone'}>
                          <p>{t("heading.platform_feedback_question")}</p>
                         
                          <input  type="radio" name="platform_f1" value="Excellent"></input>
                          <label> {t("heading.excellent")}</label>

                          <div class="form-group">
                          <input type="radio"  name="platform_f1" value="Good"></input>
                          <label> {t("heading.good")}</label>
                          </div>

                          <div class="form-group">
                          <input  type="radio"  name="platform_f1" value="Average"></input>
                          <label> {t("heading.average")}</label>
                          </div>

                          <div class="form-group">
                          <input  type="radio"  name="platform_f1" value="Poor"></input>
                          <label> {t("heading.poor")}</label>
                          </div>

                          <div class="form-group">
                          <input  type="radio"  name="platform_f1" value="Extremely Poor"></input>
                          <label> {t("heading.extremely_poor")}</label>
                          </div>
                        

                        </div>
                        <div class="row" id="post-review-box">
                          <div class="col-md-12">
                            
                              <textarea
                                class="form-control animated"
                                cols="50"
                                id="review_feedback"
                                name="comment"
                                placeholder={t("heading.message")}
                                rows="4"
                                onChange={(e) =>
                                  this.setState({ feedback: e.target.value })
                                }
                                value={this.state.feedback}
                                ref={(node) => {
                                  this.textarea = node;
                                }}
                              ></textarea>
                              <div class="text-right">
                                <br />
                                <div class="stars starrr" data-rating="0"></div>
                                <button
                                  class="btn btn-info btn-lg"
                                  type="submit"
                                >
                                  {t("heading.post_review")}
                                </button>
                              </div>
                           
                          </div>
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <br />
        </div>
        <Chatbot path="" />
      </div>
    );
  }
}
export default withTranslation()(CourseModules);
