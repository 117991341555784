import React, {Component} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next'

class ResetPassword extends Component {
constructor(props) {
super(props);
this.state = {
topic: [],
course: [],
};
}
//Check if user authenticated
async componentWillMount() {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    await axios.post('/api/checkAuth', {username: username}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(res => {
        if (res.data.message === 'hey you made it')
            this.setState({
                loggedIn: true
            });
    });
}

 //Handling user log in
 resetPassword = (e) => {    
    e.preventDefault();
    let email = e.target.children[0].children[0].value;
    if(email==='')
    {
        toast.error('Please enter your registered email id', {
            position: toast.POSITION.TOP_CENTER
        });    
    }
    else
    {
        axios.post("/api/users/reset-password-link", {"email":email})
        .then(res => {
            let err = ''
            if(res.data.status)
            {
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else
        {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            }); 
        }
            this.setState({
                err: err
            });
        });

        document.getElementById("resetpwd").reset();
    }
}


redirect = () => {
    this.props.history.push('/login');
}

render() {
const {t} = this.props
    return (
        <div>
           
           <div class="content content-bg2">
              <div class="container">
              <div class="row">
            <div class="col-md-4">
            </div>
            <div class='col-md-4'>
          
            
               <div class="card">
                  <div class="card-header">
                  <h2 class="title-form text-center">{t('login.forgot_password')}?</h2>
                  <div align="center"><small> {t('login.password_reset_instruction')}</small></div>
                      <div align="center"></div></div>
                  <div class="card-body">
                     <form id="resetpwd" onSubmit = {this.resetPassword}>
                        
                     <div class="form-group">
                           
                           <input type="text" placeholder={t('login.email_address')} name="email" id="email" class="form-control input-md"  />
                       </div>

                     

                       <div class="form-group text-right">
                       <button type='submit' className='btn btn-primary'> {t('login.send_password_link')}</button>
                       </div>
                       <div class="form-group">
                       <small><a href="/login" >{t('login.i_just_remembered_password')}</a></small>
                       </div>
                     
                       
                     </form>
                     
                  </div>
               </div>
            </div>
            <div class='col-md-3'> 
            </div>
         </div>

                 
              </div>
              <br />
              <br />
              <br />
              <br />
            
           </div>
        </div>
        )
        }
        }

export default withTranslation()(ResetPassword);