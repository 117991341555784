import React from 'react'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'


import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PrivateRoute from './PrivateRoute'


import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Home from './Components/Home'
import Redirect from './Components/Redirect'

import Login from './Components/Login'
import Logout from './Components/Logout'

import Resources from './Components/Resources'
import NoMatch from './Components/NoMatch'

import Register from './Components/Register'
import ResetPassword from './Components/ResetPassword'

import Profile from './Components/Profile'
import ProfileInfo from './Components/user/ProfileInfo'

import MyCourses from './Components/course/MyCourses'
import Unenroll from './Components/course/Unenroll'

import MyCourse from './Components/course/MyCourse'

import howItWorks from './Components/howItWorks'
import deleteAccount from './Components/delete_account'
import Media from './Components/Media'
import Orientation from './Components/Orientation'
import Hclf from './Components/Hclf'


import Topic from './Components/article/Topic'
import Article from './Components/article/Article'

import ArticleList from './Components/article/ArticleList'
import SingleArticle from './Components/article/SingleArticle'
import ModuleTopic from './Components/course/ModuleTopic'
import Courses from './Components/course/Courses'
import CourseModules from './Components/course/CourseModules'
import CourseInfo from './Components/course/CourseInfo'
import ShortSlug from './Components/ShortSlug'

import CourseCertificate from './Components/course/CourseCertificate'
import Demo from './Components/course/Demo'
import Discussion from './Components/discussion/Discussion'
import SingleDiscussion from './Components/discussion/SingleDiscussion'

import CourseSingleDiscussion from './Components/course/SingleDiscussion'
import AboutUs from './Components/AboutUs'
import ContactUs from './Components/ContactUs'
import SchoolHealthProgram from './Components/SchoolHealthProgram'

import TermsOfServices from './Components/TermsOfServices'
import CookiePolicy from './Components/CookiePolicy'
import PrivacyPolicy from './Components/PrivacyPolicy'
import Accessibility from './Components/Accessibility'

import VerifyEmail from './Components/VerifyEmail'
import Unsubscribe from './Components/Unsubscribe'
import Cobranded from './Components/Cobranded'

import ResetPasswordToken from './Components/ResetPasswordToken'
import Settings from './Components/Settings'
import CourseSurvey from './Components/course/CourseSurvey'

import StateCourseInfoBH from './Components/partner_lp/StateCourseInfoBH'
import StateCourseInfoUP from './Components/partner_lp/StateCourseInfoUP'
import StateCourseInfoRJ from './Components/partner_lp/StateCourseInfoRJ'

import ResumeLearning from './Components/ResumeLearning'


import { withTranslation } from 'react-i18next'

class  App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "en"
    }
  }

  
  
  render() {
	
    return (
        <div>
		
          <BrowserRouter history={this.props.history}>
              <div className='online-course-app'>
			
			  
                <Navbar  />
				
				
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/login' component={Login} />

                  
                  
                  <Route exact path='/certdemo' component={Demo} />
                  
                  <Route exact path='/logout' component={Logout} />
                  <Route exact path='/register' component={Register} />
                  <Route exact path='/reset-password' component={ResetPassword} />
                  <Route exact path='/verify-email/:token/:id' component={VerifyEmail} />
                  <Route exact path='/unsubscribe/:token/:id' component={Unsubscribe} />
                  <Route exact path='/reset-password-token/:token/:id' component={ResetPasswordToken} />
                  

                  <Route exact path='/resume_learning/:token/:id/:page' component={ResumeLearning} />

				  <Route exact path='/about-us' component={AboutUs} />
				  <Route exact path='/contact-us' component={ContactUs} />
				  <Route exact path='/terms-and-condition' component={TermsOfServices} />
				  <Route exact path='/privacy-policy' component={PrivacyPolicy} />
				  <Route exact path='/cookie-policy' component={CookiePolicy} />
				  <Route exact path='/accessibility' component={Accessibility} />
				  <Route exact path='/school-health-program' component={SchoolHealthProgram} />
				  
				  
                  <Route exact path='/how-it-works' component={howItWorks} />
                  <Route exact path='/account/delete' component={deleteAccount} />
                  <Route exact path='/media' component={Media} />
				  
                  <Route exact path='/online-demo/:state' component={Orientation} />
				  
                  <Route exact path='/hclf' component={Hclf} />
                  <Route path='/c/:id' component={ShortSlug} />
                  <Route path='/r/:id' component={Redirect} />
                  <Route path='/courses' component={Courses} />
                  <Route path='/programme' component={Courses} />
                  <Route path='/resources' component={Resources} />
                 
				 <Route exact path='/discussions' component={Discussion} />
                  <Route exact path='/discussions/p/:page' component={Discussion} />
                  <Route exact path='/discussions/view/:id' component={SingleDiscussion} />

                  

                  <Route exact path='/bihar' component={StateCourseInfoBH} />
                  <Route exact path='/up' component={StateCourseInfoUP} />
                  <Route exact path='/rjedu' component={StateCourseInfoRJ} />
				  
                  <Route exact path='/how-it-works' component={howItWorks} />
                  <PrivateRoute path="/profile" component={ProfileInfo} />
                  <PrivateRoute path="/usr/UpdateInfo" component={ProfileInfo} />
                  <PrivateRoute path="/settings" component={Settings} />
				  <Route exact path='/p/:tag' component={Cobranded} />
                  
                  <PrivateRoute exact path='/my-course' component={MyCourse} />
                  <PrivateRoute exact path='/dashboard' component={MyCourses} />

                  <PrivateRoute exact path='/course/unenroll/:id' component={Unenroll} />


                  <PrivateRoute exact path='/my-courses/:id' component={MyCourse} />
                  <Route exact path='/certificate/:id' component={CourseCertificate} />
                  
                  <PrivateRoute path='/view/course/:id' component={CourseModules} />
  
                  <PrivateRoute exact path='/course/module/:id/:section' component={ModuleTopic} />
                  <PrivateRoute exact path='/course/module/:id/:section/:tab_id' component={ModuleTopic} />
                 
                  <PrivateRoute path='/survey/course/:id' component={CourseSurvey} />

                  <Route exact path='/topic' component={Topic} />
                  <Route exact path='/topic/:name' component={ArticleList} />
                  <Route exact path='/topic/:id/:slug' component={SingleArticle} />
				  
				  
				  				
                  <Route exact path='/course_discussions/view/:id' component={CourseSingleDiscussion} />
				  

                 
				          <Route exact path='/article' component={Article} />
				         
                 
                 
                  <Route path='/info/course/:id' component={CourseInfo} />

                  <Route component={NoMatch} />

                  

                 
               
                  
                </Switch>
				
				 <Footer history={this.props.history} />
				
              </div>
          </BrowserRouter>
          <ToastContainer hideProgressBar={true} autoClose={5000} />
		 
        </div>
    );
  };
}

export default withTranslation()(App);