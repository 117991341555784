import React, {Component} from 'react'
import axios from "../axios.js";
import $ from "jquery";
import { withTranslation} from 'react-i18next'
import { toast } from 'react-toastify';
import '../../assets/chat-style.css';
import Chatbot from '../Chatbot'
import QuizBox from '../quiz/QuizBoxNew'


import { Player } from 'video-react';
import '../../assets/video-react.css'; 

const sources = {
vidMoview: '/module3.mp4'
};
class ModuleTopic extends Component {
constructor(props,context) {
super(props,context);
this.state = {
section:'reading',
topic: [],
course: [],
source: sources.vidMoview,
myCourse:[],
reflective_journal:null,
discussion_comments:null,
primary_reading:null,
quiz_score:'',
akb_msg:'',
tab2:'',
value: '',
htmldata:'',
form_data:'',
video_url:'',
topic_name:'',
tab_id:1,
myTopic:[],
myDisc:[],
enrollmentInfo:[],
cur_page:null,
next_page:null,
prev_page:null,
total_page:null,
next_section:null,
progress:0,
subtopic:[],
topic_status:null,
module_id:null
};
}
async componentDidMount() {
    $('#footerwidgets').hide();
   // $(".languageToggle").hide();
    this.state.section = this.props.match.params.section
    this.state.tab_id = this.props.match.params.tab_id ? this.props.match.params.tab_id : 1
    const token = localStorage.getItem('token');
    var language = localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language')
    axios.get('/api/courses/topic/' + this.props.match.params.id + '?language=' + language)
        .then(res2 => {
            this.setState({
                topic: res2.data.data,
                module_id: res2.data.data.module_id,
                total_page: res2.data.data.primary_pages,
                next_section: res2.data.data.quiz == 1 ? 'quiz' : 'reflective_journal'
            });
            localStorage.setItem('course_id', this.state.topic.course_id)
            axios.get('/api/courses/' + this.props.match.params.id, {})
                .then(res2 => {
                    this.setState({
                        data: res2.data
                    });
                    axios.post('/api/users/get-my-courses-info/' + this.state.topic.course_id, {}, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(resef => {
                        console.log(resef)
                        this.setState({
                            enrollmentInfo: resef.data.data,
                        });
                    });
                   
                    axios.get('/api/courses/topicDiscussion/' + this.props.match.params.id + '?language=' + language)
                        .then(res2b => {
                            this.setState({
                                myDisc: res2b.data.data
                            });
                            var language = localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language')
                            axios.get('/api/courses/' + this.state.topic.course_id + '?language=' + language, {})
                                .then(res3 => {
                                    axios.post('/api/users/get-my-topic-info/' + this.props.match.params.id, {
                                        course: this.state.topic.course_id
                                    }, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + token
                                        }
                                    }).then(res3 => {
                                        if (res3.data.status) {
                                            this.setState({
                                                myTopic: res3.data,
                                                topic_status: res3.data.data.status,
                                                reflective_journal: res3.data.data.reflective_journal,
                                                discussion_comments: res3.data.data.discussion,
                                                quiz_score: res3.data.data.quiz,
                                                primary_reading: res3.data.data.primary_reading
                                            });
                                        } else {
                                            //create topic in user course if topic not added
                                            axios.post('/api/users/add-user-course-topic/', {
                                                course_id: this.state.topic.course_id,
                                                topicId: this.props.match.params.id,
                                                enrollmentId: this.state.enrollmentInfo.id,
                                                quiz: this.state.topic.quiz,
                                                language: language,
                                                completion_criteria: this.state.topic.completion_criteria
                                            }, {
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + token
                                                }
                                            }).then(res4 => {});
                                        }
                                    });
                                    this.setState({
                                        course: res3.data
                                    });
                                });
                            // axios.get('/html/'+this.state.topic.course_id+'/'+language+'/'+this.props.match.params.id+'/'+ this.state.tab_id+'.html', {})
                            axios.get('/api/courses/topicPrimaryReading/' + this.props.match.params.id + '/' + this.state.tab_id + '?language=' + language, {})
                                .then(htmldata => {
                                    this.setState({
                                        htmldata: htmldata.data.data.content,
                                        form_data: htmldata.data.data.form_data,
                                        video_url: htmldata.data.data.video_url,
                                        topic_name: htmldata.data.data.topic_name,
                                        cur_page: htmldata.data.data.page,
                                        next_page: htmldata.data.data.page + 1,
                                        prev_page: htmldata.data.data.page > 1 ? htmldata.data.data.page - 1 : 1,
                                    });
                                    axios.get('/api/courses/getsubTopicList/' + this.props.match.params.id + '?language=' + language)
                                        .then(res2b => {
                                            this.setState({
                                                subtopic: res2b.data.data
                                            });
                                        });
                                });
                        });
                });
        });
}

feedback_update = (e) => {
    e.preventDefault();
	let usr_feedback = {};
    let is_validate = true;
	this.props.history.push('/course/module/' + this.props.match.params.id+'/primary')


	//get radiobutton and text field data - single valued
    const formData = new FormData(e.currentTarget);
    for (let [key, value] of formData.entries()) {

        usr_feedback[key] = value
    }


//handle checkbox
var checkbox = $("#userForm").find("input[type=checkbox]");
$.each(checkbox, function(key2, val2) {
  
	if($(val2).is(':checked'))
	{
		console.log($(val2).attr('name')+':'+$(val2).attr('value'))
		if(usr_feedback[$(val2).attr('name')])
		{
			if(usr_feedback[$(val2).attr('name')].indexOf($(val2).attr('value')) === -1)
			{
				usr_feedback[$(val2).attr('name')]=usr_feedback[$(val2).attr('name')]+','+$(val2).attr('value')
			}
			
		}
		else
		{
			usr_feedback[$(val2).attr('name')]=$(val2).attr('value')
		}
	}
});	

console.log(usr_feedback)
//validation
for (let [key, value] of Object.entries(usr_feedback)) {

        if (value === '') {
            toast.error('Please respond to question '+key, {
                position: toast.POSITION.TOP_CENTER
            });
            is_validate = false;
           break;
        }
    }
	

    if (is_validate) {
        const token = localStorage.getItem('token');
        axios.post('/api/users/user_survey_data/' + this.props.match.params.id, usr_feedback, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
			//location.reload();
			
            this.props.history.replace('/course/module/' + this.props.match.params.id+'/primary')
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        });
    }
}


handleChange = e => {
    this.setState({
        value: e.target.value
    })
}


handleDiscussionSubmit = (event) => {
   event.preventDefault();
  
   let res = event.target.children[1].children[0].value;
   let discussion_id = event.target.children[1].children[1].value;
  
  console.log(discussion_id);
   
   axios.post('/api/users/update-discussion_comments/'+discussion_id, {comment: res,topicId:this.props.match.params.id}, {
     headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
 }).then(res7 => {
 
   if(res7.data.status)
   {
      //window.location.reload();
      toast.info(res7.data.message, {
         position: toast.POSITION.TOP_CENTER
     });
}
else
{
   toast.error(res7.data.message, {
      position: toast.POSITION.TOP_CENTER
  });
}
     }); 
 }


render() {
const {t} = this.props
const optional='('+t('heading.optional')+')';
const optional_field=this.state.topic.completion_criteria+'';
const discTopic = this.state.myDisc.map(row => {
return (
<p>
<form onSubmit={this.handleDiscussionSubmit}>
   <div class="faqas">
      <h6>{t('heading.your_response')}:</h6>
   </div>
   <div class="form-group">
      <textarea class="form-control" id="disctrl" placeholder={t('heading.your_response')} onChange={e => this.setState({ discussion_comments : e.target.value })} value={this.state.discussion_comments} ref={node => {this.textarea = node;}}  rows="4" cols="200"></textarea>
      <input type="hidden" name="discussion_id" id="discussion_id" value={row.slug} />
      <input type="hidden" name="topicId" id="topicId" value={this.props.match.params.id} />
   </div>
   <div class="form-group text-right">
      <input type="submit" class="btn btn-success" value={t('heading.submit')} />
   </div>
</form>
</p>
)});
return  (
<div>

<div class="page-header">
   <div class="container">
      <div class="row">
	  
         <div class="col-md-6">
		
            <span class="course_topic_heading" >
            {this.state.topic.name}  
            </span> 
            <span> {this.state.topic_status=='Completed'?  <i class="fa fa-check-circle blue fatopicstatus"></i>:''} </span>
         </div>
         <div class="col-md-6">
            <ol class="breadcrumb  pull-right">
               { this.state.topic.previous!=="" ? 
               <a title="Previous Topic" href={'/course/module/'+this.state.topic.previous+'/primary'}> « </a> 
               : '' }
               <li><a href="/"> <i class="fa fa-home"></i></a></li>
               <li><a  href={"/Dashboard"}> {t('navigation.my_courses')}</a></li>
               <li><a class="course_name_topic_page" href={"/view/course/"+this.state.topic.course_id}> {this.state.course.name}</a></li>
               <li> {this.state.topic.module_name} </li>
               { 
               this.state.topic.next!=="" ?  <a title="Next Topic" href={'/course/module/'+this.state.topic.next+'/primary'}> » </a> :'' 
               }          
            </ol>
         </div>
      </div>
   </div>
</div>

<div class="courseInfo sticky dnone">
   <ul>
      <li class="active"><a href={"/course/module/"+this.props.match.params.id+"/primary"} class={this.props.match.params.section === 'primary'?'course-active-tab':''} > {this.state.primary_reading!=null? <i class="fa fa-check-circle"></i>:''} {t('heading.primary_reading')} »</a></li>
      {
      this.state.topic.quiz===1?
      <li><a href={"/course/module/"+this.props.match.params.id+"/quiz"} class={this.props.match.params.section === 'quiz'?'course-active-tab':''} > {this.state.quiz_score>7? <i class="fa fa-check-circle"></i>:''} {t('heading.quiz')} » </a></li>
      :''
      }
      {
      <li><a href={"/course/module/"+this.props.match.params.id+"/reflective_journal"} class={this.props.match.params.section === 'reflective_journal'?'course-active-tab':''} > {this.state.reflective_journal!=null? <i class="fa fa-check-circle"></i>:''} {t('heading.reflective_journal')} » </a></li>
      }
      <li><a href={"/course/module/"+this.props.match.params.id+"/discussion"} class={this.props.match.params.section === 'discussion'?'course-active-tab':''}> {this.state.discussion_comments!=null? <i class="fa fa-check-circle"></i>:''} {t('heading.discussion')} » </a></li>
   </ul>
</div>

<div class="content content-bg4" style={{"padding": "5px 0px"}}>
<div class="container">



<div class="row">
<div class="col-md-3 order-2 order-lg-1 space-pt_20 pad5 ">

<li class="list-group-item active list-group-item-secondary "><i class="fa fa-book"></i> {t('heading.primary_reading')}</li>
<div class="panel-group" id="accordion">
                
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                            <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour"><span class="glyphicon glyphicon-file">
                            </span>{this.state.topic.name}</a>
                        </h4>
                    </div>
                    <div id="collapseFour" class="panel-collapse collapse collapse in show">
                        <div class="panel-body">
                           
      <ul class="list-group">
      
      {this.state.subtopic.map((c, i) => (
      <li class={c.page===this.state.cur_page && this.props.match.params.section === 'primary'?'list-group-item list-group-item-dark subtipic-list':'list-group-item subtipic-list'}  ><a href={"/course/module/"+this.props.match.params.id+'/primary/'+c.page}><i class={"fa fa-"+c.icon}></i> {c.topic_name}</a> </li>	 
      ))}
   </ul>
                        </div>
                    </div>
                </div>
                
            </div>
       
       




   
   
   <br />
   <ul class="list-group">
      <li class="list-group-item active list-group-item-secondary"><i class="fa fa-pencil-square-o"></i>  {t('heading.assignment')}</li>
      {
      this.state.topic.completion_criteria==='Ungraded' ?
      <li class="list-group-item"> {t('heading.ungraded_topic')}</li>
      :''
      }
      {
      this.state.topic.quiz===1 ?
      <li class={this.props.match.params.section === 'quiz'?'list-group-item list-group-item-dark':'list-group-item'}><a href={"/course/module/"+this.props.match.params.id+"/quiz"}  >  {t('heading.quiz')} {optional_field.indexOf('Quiz') === -1 ? optional:'' } </a> {this.state.quiz_score>0? <span class="pull-right blue"><i class="fa fa-check-circle"></i></span>:''}</li>
      :''
      }
      {
      this.state.topic.reflective_journal!==null  ?
      <li class={this.props.match.params.section === 'reflective_journal'?'list-group-item list-group-item-dark':'list-group-item'}><a href={"/course/module/"+this.props.match.params.id+"/reflective_journal"}  >  {t('heading.reflective_journal')} {optional_field.indexOf('Reflective Journal') === -1 ? optional:'' }  </a> {this.state.reflective_journal!=null? <span class="pull-right blue"><i class="fa fa-check-circle"></i></span>:''}</li>
      :''
      }
      {
      this.state.topic.discussion!==null   ?
      <li class={this.props.match.params.section === 'discussion'?'list-group-item list-group-item-dark':'list-group-item'}><a href={"/course/module/"+this.props.match.params.id+"/discussion"} >  {t('heading.discussion')}    {optional_field.indexOf('Discussion') === -1 ? optional:'' }  {this.state.discussion_comments!=null? <span class="pull-right blue"><i class="fa fa-check-circle"></i></span>:''}</a></li>
      :''
      }
   </ul>
</div>

<div class="col-md-9 order-1 order-lg-2 space-pt_25 ">
<div class="tabbable-panel">
   <div class="tabbable-line">
      <div class="tab-content">
         <div class="tab-pane active" id="tab_default_1">
            {this.props.match.params.section === 'primary'?
            <div class="row">
               <div class="col-md-12">
                  <div class="float-left">
				  
                     { this.state.cur_page==1 && this.state.topic.previous==="" ?
                     <a href={'/view/course/'+this.state.topic.course_id}><span class="topic_next">❮ <i class="fa fa-home"></i> {this.state.course.name} </span></a>
                     :''
                     }
                     { this.state.cur_page>1 ?
                     <div><a href={'/view/course/'+this.state.topic.course_id}><span class="topic_next"><i class="fa fa-home"></i>  </span></a> &nbsp;<a href={"/course/module/"+this.props.match.params.id+'/primary/'+this.state.prev_page}><span class="page_prev">❮ {t('heading.previous')}</span></a></div>
                     : ''
                     }
                     { this.state.cur_page==1 && this.state.topic.previous!=="" ?
                     <a href={'/course/module/'+this.state.topic.previous+'/primary'}><span class="topic_next">❮ {t('heading.previous_topic')}</span></a>
                     : ''
                     }
                  </div>
                  <div class="float-right">
                     { this.state.cur_page>=this.state.total_page &&  this.state.next_page!==null && this.state.topic.completion_criteria!=='Ungraded' && this.state.topic.completion_criteria!=='formSubmit' ? 
                     <a href={"/course/module/"+this.props.match.params.id+'/'+this.state.next_section}><span class="topic_next">{t('heading.next')} - {t('heading.assignment')} ❯</span></a>
                     :''
                     }
					 
                     {
                     this.state.topic.completion_criteria=='Ungraded' && this.state.cur_page==this.state.total_page && this.state.topic.next!==""?
                     <a href={'/course/module/'+this.state.topic.next+'/primary'}><span class="topic_next">  {t('heading.next_topic')} ❯</span></a>
                     :''
                     }
                     { this.state.topic.next==="" &&  (this.state.topic.completion_criteria=='Ungraded' || this.state.topic.completion_criteria=='formSubmit') ?
                     <a href={'/view/course/'+this.state.topic.course_id}><span class="topic_next">❮ <i class="fa fa-home"></i> {t('heading.home')} </span></a>
                     :''
                     }
                     { this.state.next_page!=='' && this.state.cur_page<this.state.total_page ?
                     <a href={"/course/module/"+this.props.match.params.id+'/primary/'+this.state.next_page}><span class="page_next">{t('heading.next')} ❯</span></a>
                     :''
                     }
                  </div>
                  <span class="pull-right" data-uw-styling-context="true">{t('heading.page')} {this.state.cur_page} / {this.state.total_page} &nbsp;</span> 
                  <div class="jumbotron">
                     <div class="row">
                        <div class="col-md-12">
                           <span class="course_topic_heading" data-uw-styling-context="true">
                           <strong>{this.state.topic_name}</strong>
                           </span>
                           <hr />
						   {
							   this.state.video_url!==null?
							   
	<div class="vide-sec"><Player
      playsInline
      poster={""}
      src={this.state.video_url}
    />
	</div>
	

							   :''
						   }
                           
						   <div dangerouslySetInnerHTML={{__html: this.state.htmldata}} />
						   
						   
						   <form id="userForm" onSubmit = {this.feedback_update}>
							
							<div dangerouslySetInnerHTML={{__html: this.state.form_data}} />
							
	  
						</form>
                           
						   
						   
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               :''
               }
               {this.props.match.params.section === 'quiz'?
               <div class="row">
                  <div class="col-md-12">
                     <div class="float-left">
                        <h4> {t('heading.quiz')}</h4>
                     </div>
                     <div class="float-right">
                        {
                        this.state.topic.reflective_journal!==null ?
                        <a href="reflective_journal"  class="cbtn_sec"> {t('heading.next')} » {t('heading.reflective_journal')}</a>
                        :''
                        }
						
						{
                        this.state.topic.reflective_journal==null && this.state.topic.discussion!==null ?
                        <a href="discussion"  class="cbtn_sec"> {t('heading.next')} » {t('heading.discussion')}</a>
                        :''
                        }
						
						{
                        this.state.topic.reflective_journal==null && this.state.topic.discussion==null && this.state.topic.next!==""  ?
                        <a class="cbtn_primary" href={'/course/module/'+this.state.topic.next+'/primary'}>{t('heading.next_topic')} »</a>
                        :''
                        }
						
						{
                        this.state.topic.reflective_journal==null && this.state.topic.discussion==null && this.state.topic.next==="" ?
                        <a class="cbtn_primary" href={'/survey/course/'+this.state.topic.course_id}>{t('heading.conclusion')} »</a>
                        :''
                        }
						
						
						
						
						
                     </div>
                     <br />
                     {
                     this.state.topic.quiz==1?
                     <div class=" jumbotron">
                        <QuizBox  path={this.props.match.params.id} next_topic={this.state.topic.next} />
                     </div>
                     :
                     <p></p>
                     }
                  </div>
               </div>
               :''
               }
               
                  {this.props.match.params.section === 'reflective_journal'?
                  <div class="row">
                     <div class="col-md-12">
                        <div class="float-left">
                           <h4>{t('heading.reflective_journal')} {this.state.akb_msg}</h4>
                        </div>
                        <div class="float-right">
                           <a href="discussion"  class="cbtn_sec"> {t('heading.next')} » {t('heading.discussion')} ({t('heading.optional')})</a>
                        </div>
                        <div class=" jumbotron">
                           <div class="bold" dangerouslySetInnerHTML={{__html: this.state.topic.reflective_journal}} />
                              <hr />
                              {
                              <div>
                                 <form onSubmit={this.handleSubmit}>
                                    <div class="form-group">
                                       <textarea class="form-control" name="reflective_journal" id="rfjctrl"  placeholder={t('heading.your_response')} onChange={e => this.setState({ reflective_journal : e.target.value })} value={this.state.reflective_journal} ref={node => {this.textarea = node;}} rows="8" cols="200"></textarea>
                                    </div>
                                    <div class="form-group text-right">
                                       <input type="submit" class="btn btn-success" value={t('heading.submit')} />
                                    </div>
                                 </form>
                              </div>
                              }
                           </div>
                        </div>
                        <div class="col-md-12 text-right">
                        </div>
                     </div>
                     :''
                     }	
                     {this.props.match.params.section === 'discussion'?
                     <div class="row">
                        <div class="col-md-12">
                           <div class="float-left">
                              <h4>{t('heading.discussion')} ({t('heading.optional')})</h4>
                           </div>
                           <div class="float-right">
                              { this.state.topic.next!=='' ?
                              <a class="cbtn_primary" href={'/course/module/'+this.state.topic.next+'/primary'}>{t('heading.next_topic')} »</a>
                              :<a class="cbtn_primary" href={'/survey/course/'+this.state.topic.course_id}>{t('heading.conclusion')} »</a>
                              }
                           </div>
                           <div class=" jumbotron">
                              <div dangerouslySetInnerHTML={{__html: this.state.topic.discussion}} /> 
                                 {discTopic}
                              </div>
                           </div>
                        </div>
                        :''
                        }	
                        
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <Chatbot  path='' />
</div>
)
}
}
export default withTranslation()(ModuleTopic);