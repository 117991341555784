import React,{useEffect,useState} from 'react'
import axios from "../axios.js";
import Spinner from "../Spinner.js";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Courses() {
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse]=useState([])
  const { t} = useTranslation();

 var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 

useEffect(() => {
   (async () => {
      try {
      const userPosts = await axios.get("/api/courses?language="+language)
      setResponse(userPosts.data);
       setLoading(false)
      } catch (err) {
         console.error(err.message);
       }
   })()
}, [])


if (isLoading) {
  return <Spinner />
}

return (
  <div>
			<Helmet>
        <title>Educately | Courses</title>
      </Helmet>
                <div class="page-header">
              <div class="container">
                
                 <div class="row">
                 <div class="col-md-8">
                    <h1 class="brcheading">{t('portal.online_courses')}</h1>
                    </div>
                    <div class="col-md-4">
                       <ol class="breadcrumb pull-right">
                            <li><a href="/">{t('navigation.home')}</a></li>
                  <li class="active">{t('navigation.courses')}</li>
                       </ol>
                    </div>
                    
                 </div>

              </div>
           </div>
           
            <div class="content content-white">
        
               <div class="container">
              
               <p align="left">{t('portal.online_programmes_desc')}</p>
              
               
               <br />
              
              
                   <div class="row">

                   
       {
       response.map(row=>(

        
        <div class="col-md-4">
   <div class="card">
      <div class="card-body">
        <div class="course-label">
         <div class="float-left">
            <span class="label label-light arrowed-in-right"><i class="fa fa-tag"></i> {t('portal.free')}</span>
         </div>
         <div class="float-right">
            {
            row.is_new===1?<span class="label label-success arrowed"><i class="fa fa-rocket"></i> New</span>
            :
            <p></p>
            }
         </div>
        </div>
         <div class="course-title-sec">
         <p class="text-center"><img class="group list-group-image img-fluid text-center" width="30%" src={row.icon} alt="" /></p>
            
         <h4 class="group card-title inner list-group-item-heading text-center">{row.name}	</h4>
           
         </div>
         <hr />
         <div class="course-bottom">
            <div class="float-left">
              <p class="lead">
                  <span class="label label-large label-yellow arrowed-in-right"><i class="fa fa-clock-o"></i> {row.duration}</span>
               </p>
            </div>
            <div class="float-right">
               <a class="btn btn-primary " href={"/info/course/"+row.slug}>{t('heading.start_now')}</a>
            </div>
         </div>
      </div>
   </div>
</div>

))

}
     
</div>     
                 
           </div>
           </div>


           
           </div>
           
  );
}
