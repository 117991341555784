import React, {Component} from 'react'
import axios from "../axios.js";

export default class Pagination extends Component {
    constructor(props,context) {
        super(props,context);
          this.state = {
              data:[]
          };
        }
    
    componentWillMount() {
        const path = this.props.cur_page;
        
        console.log(path)
       
    }

     componentDidMount() {
       
    }
    


    render() {

        let total_pages=parseInt(this.props.total_pages)
        let cur_page=parseInt(this.props.cur_page)
        let path=this.props.path

        let start=cur_page-2;
        let end=cur_page+2;
        if(end>total_pages-2){end=total_pages;start=end-4}
        if(start<1){start=1;end=start+4}

        if(end>total_pages){end=total_pages}

        
        let nextPage=parseInt(cur_page)+1;
       if(nextPage>total_pages){nextPage=total_pages}
       let prevPage=cur_page-1;
       if(prevPage<1){prevPage=1}
 
 
    const pageNumbers = [];
     for (let i = start; i <= end; i++) {
       pageNumbers.push(i);
     }
       const renderPageNumbers = pageNumbers.map(number => {
       return (
          number==cur_page?<li class="page-item active"><a class="page-link" href={path+'/'+number}>{number}</a></li>:<li class="page-item"><a class="page-link" href={path+'/'+number}>{number}</a></li>
       );
     });

        return (
            <div class="container">
            <div class="row">
               <div class="col-md-12 text-right">
       <span><small>Page {cur_page}/{total_pages} </small></span>
               <ul class="pagination">
                <li class="page-item"><a class="page-link" href={path+'/'+'1'}>«</a></li>
               <li class="page-item"><a class="page-link" href={path+'/'+prevPage}>⟨</a></li>
               {renderPageNumbers}
               <li class="page-item"><a class="page-link" href={path+'/'+nextPage}>⟩</a></li>
               <li class="page-item"><a class="page-link" href={path+'/'+total_pages}>»</a></li>
              </ul>
              </div>
              </div>
              </div>	
        )
    };
}