import React,{useEffect,useState} from 'react'
import {useAxios} from "../useAxios.js";
import { useTranslation } from 'react-i18next';
import RegisterLP from "../RegisterLP";
import RegisterForm from "../RegisterForm";
import axios from "../axios.js";
import Spinner from "../Spinner.js";

export default function HeaderSlider(props) {

    const [isLoading, setLoading] = useState(true);
    const [banners, setBanners] = useState({})
    var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
    const { t} = useTranslation();
    useEffect(() => {
        (async () => {
            const banner1 = await axios.get('/api/static_page?p=Home-Banner1&language='+language)
            const banner2 = await axios.get('/api/static_page?p=Home-Banner2&language='+language)
            const banner3 = await axios.get('/api/static_page?p=Home-Banner3&language='+language)
            setBanners({banner1, banner2, banner3})
            setLoading(false)
        })()
    }, [])


    if (isLoading) {
        return <Spinner />
      }

return (
<div class="home-bg">
	<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

	<div class="carousel-item active">
		<div className="container">
			<div className="row">
				<div class="col-md-6 order-1 order-lg-2 banner-cta">
			
        <div class="signup">
        <h2 class="signup-page-title">{t('heading.enroll_now')}</h2>
        <RegisterForm path="293ba2f655f773e00fa7a13c7d7af9a1" history={props.history} /> 
  </div>

				</div>
					<div class="col-md-6 order-2 order-lg-1 home-intro-text">

                    <div dangerouslySetInnerHTML={{__html: banners.banner3.data.data.content}} />

				</div> 
			</div>
		</div>
	</div>
	
    <div class="carousel-item">
     
	 <div className="container">
			<div className="row">
				<div class="col-md-6 order-1 order-lg-2 banner-cta">
        <div class="signup">
        <h2 class="signup-page-title">{t('signup.create_a_free_account')}</h2>
        <RegisterForm path="3dffdc3aaeadab44bab29233a4aa698d" history={props.history} /> 
  </div>
				</div>
					<div class="col-md-6 order-2 order-lg-1 home-intro-text">
                    <div dangerouslySetInnerHTML={{__html: banners.banner2.data.data.content}} />
				</div> 
			</div>
		</div>
	 
    </div>
    
    <div class="carousel-item">
     
     <div className="container">
        <div className="row">
          <div class="col-md-6 order-1 order-lg-2 banner-cta">
          <div class="signup">
          <h2 class="signup-page-title">{t('signup.create_a_free_account')}</h2>
          <RegisterForm path="5dbbf82d7a803502886a2592" history={props.history} /> 
    </div>
          </div>
            <div class="col-md-6 order-2 order-lg-1 home-intro-text">
                      <div dangerouslySetInnerHTML={{__html: banners.banner1.data.data.content}} />
          </div> 
        </div>
      </div>
     
      </div>
	
  </div>
  
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

	</div>
  );
};