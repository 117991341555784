	import React, {Component} from 'react'
import axios from "../axios.js";

export default class MyCourse extends Component {
constructor(props) {
super(props);
this.state = {

    course: [],
    topics: [],
    courseDesc: [],
    my_total_score:0,
    total_quiz_weightage:0
};
}

componentDidMount() {
   const token = localStorage.getItem('token');
   localStorage.setItem('course_id', this.props.match.params.id)
   var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
   const username = localStorage.getItem('username');
   axios.post('/api/my-courses/'+this.props.match.params.id+'?language='+language, {username: username}, {
       headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + token
       }
   }).then(res => {
        console.log(res.data.data[0].topics)
        if (res.data.message !== 'unauthorized')
       
            this.setState({
              
                course:res.data.data[0],
                topics:res.data.data[0].topics
            });
    });
    

    axios.get('/api/courses/'+this.props.match.params.id, {})
                .then(res2 => {
                    console.log(res2.data)
                    this.setState({
                        courseDesc: res2.data
                    });
                }); 

}


render() {
  
    
    const topics = this.state.topics.map(topic => {
       
        const fStyle = {
            border: '1px solid #efefef',
            margin: '5px',
            background:'#efefef'
          };

          const ufStyle = {
            border: '1px solid #efefef',
            margin: '5px',
            background:'#f9f9f9'
          };

		this.setState({ my_total_score: this.state.my_total_score+parseInt(topic.quizScore) });
		this.setState({ total_quiz_weightage: this.state.total_quiz_weightage+10 });

         
          return (
           
          
  
            <div class="col-md-6">
           <div   style={topic.status==='Completed'?fStyle:ufStyle}>
            <ul class="fa-ul list-special">
            <li>
           

        { //Check if message failed
        (topic.status === 'Completed')
        ? <span><i class="fa-li fa fa-2x fa-book text-green"></i>{topic.topicName} </span>
        : <span><i class="fa-li fa fa-2x fa-book text-gray"></i>{topic.topicName} </span>
        }  

        { //Check if message failed
        (topic.status === 'Completed')
        ?<span class="label label-large label-yellow arrowed-in">{topic.status} </span>
        :<span class="label label-large label-light arrowed-in-right arrowed-in">{topic.status}</span>
        }          
        
        <br />
                  
                  
    { //Check if message failed
        (topic.status === 'Completed')
        
    ? <p><a href={'/quiz/'+topic.topicId}><i class="fa fa-repeat"> </i> </a> Quiz score: <span class="badge badge-success">{topic.quizScore}/{this.state.courseDesc.quiz_per_topic}</span> &nbsp; | &nbsp; <a href={'/course/module/'+topic.topicId}><i class="fa fa-play"></i> Retake this lesson again</a></p> 
          : <p><a href={'/course/module/'+topic.topicId}><i class="fa fa-play"></i> Take this lesson now</a></p> 
      }
                  
               
                   
                   
                </li>
                  </ul>
                  </div>
        </div>
     
        )
    });


    return (
        <div>
           <div class="page-header">
              <div class="container">
                 <div class="row">
                    <div class="col-md-8">
                       <h1>My Courses - {this.state.course.course_name}</h1>
                    </div>
                    <div class="col-md-4">
                       <ol class="breadcrumb pull-right">
                          <li><a href="/">Home</a></li>
                          <li><a href="/my-courses">My Courses</a></li>
                          <li class="active">{this.state.course.course_name}</li>
                       </ol>
                    </div>
                 </div>
              </div>
           </div>
           <div class="content content-light  video-film">
              <div class="container">
                 <div class="row">
                 <h2 class="text-center"> Topics</h2>
                 

                
                 <h2 class="title-form text-center">(Course: {this.state.course.course_name})</h2>
                 <hr />
                 <p align="center">Course Status <span class="label label-important arrowed-in">{this.state.course.status}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Quiz Score <span class="label label-large label-pink arrowed-right">{this.state.my_total_score}  /  {this.state.total_quiz_weightage}</span></p>
                 <hr />
               
                 <br />
                
                {topics}
              </div>
           </div>
        </div>
        </div>
        )
        }
        }