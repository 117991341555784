import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import axios from "./axios.js";
class howItWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageContent:[]
        };
    }

    componentDidMount() {
      
}
async componentWillMount() {
	var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')     
    axios.get("/api/static_page?p=HowItWorks&language="+language, {}).then(res2 => {
      this.setState({
        pageContent: res2.data.data.content
      });
    });
  }
    

render() {
    const {t} = this.props
     return (
        <div>
        <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1 class="brcheading">{t('heading.how_it_works')}</h1>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('heading.home')}</a></li>
                  <li class="active">{t('heading.how_it_works')}</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
  <div dangerouslySetInnerHTML={{__html: this.state.pageContent}} />
   
    </div>
         )
         }
         }

export default withTranslation()(howItWorks);