import React,{useEffect,useState} from 'react'
import {useAxios} from "../useAxios.js";
import { useTranslation } from 'react-i18next';
import $ from "jquery";
import Spinner from "../Spinner.js";


export default function ArticleList(props) {
  const { t} = useTranslation();
  const slug = props.match.params.name;
  var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')       
  const { response, loading, error } = useAxios({
      method: 'get',
      url: '/api/topic/'+slug+'?language='+language,
      headers: JSON.stringify({ }),
      body: JSON.stringify({
      }),
  });



  const [data, setData] = useState([]);
  useEffect(() => {
      if (response !== null) {
          setData(response);
      }
  }, [response]);

  function loadMore(e) {
        e.preventDefault();
        $(".Issuecontent:hidden").slice(0, 3).slideDown();
        if($(".Issuecontent:hidden").length === 0) {
          $("#loadMore").text("").addClass("noContent");
        }
  }

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div className="App">{error.message}</div>;
  }

return (

    <div>
    <div class="page-header">
<div class="container">
<div class="row">
    <div class="col-md-7">
        <h1 class="brcheading">{data.message}</h1>
    </div>
    <div class="col-md-5">
        <ol class="breadcrumb pull-right">
          <li><a href="/">{t('navigation.home')}</a></li>
            <li><a href="/topic">{t('navigation.core_issues')}</a></li>
          
            <li class="active capitalize">{data.message}</li>
        </ol>
    </div>
</div>
</div>
</div>


<div class="content content-bg4">
<div class="container">

<div class="row">



{
    
      data.data.map((row, i) => (
      <div class={i >
         2 ? "col-md-4  Issuecontent" : "col-md-4 "}>         
        
                     <div class="card-round">
                <div class="card-content">
                    <div class="card-img">
                    <a href={"/topic/"+row.topic+"/"+row.slug} ><img src={row.icon} alt="" /></a> 
                        
                      
                    </div>
                    <div class="card-desc">
                        <div class="topic_tag float-left">{data.message}</div>
                        <div class="float-right topic_tag_type"> {row.content_type} |  {row.content_length}</div>
                        <div>
                        <br />
                        <a href={"/topic/"+row.topic+"/"+row.slug} ><h3 class="coreissueh3">{row.title}</h3></a> 
                        
                        </div>
                        
                            <a href={"/topic/"+row.topic+"/"+row.slug} class="btn-card">{t('portal.view')}</a>   
                    </div>
                </div>
                </div>
                <br />
           
      </div>
      ))
      }

</div>

{data.data.length>3?
<a href="#"  onClick={loadMore} id="loadMore">{t('portal.load_more')} <i class="fa fa-arrow-right"></i></a>
:''
}
</div>
</div>


</div>

  );
};