import React, {Component} from 'react'

class Article extends Component {
    search = (e) => {
        e.preventDefault();
        const keyword = e.target.children[0].value;
        this.props.history.push({
            pathname: '/search',
            search: '?query=' + keyword
          });
    }

    render() {
        return (
            <div className='homepage'>
                <div className='home-banner'>
                    <div className='banner-text white-text'>
                        <h2>Article Page</h2>
                        <h5>Description...</h5>
						
                        
                    </div>
                    <img width='100%' alt="" className='banner-image' src='/website-banner.jpg' />
                </div>
                <div className='my-container'>
                    <h3 className='teal-text'>Popular Articles</h3>
                   
                </div>
            </div>
        )
    };
}

export default Article;