import React, {Component} from 'react'
import axios from "../../axios.js";

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { toast } from 'react-toastify';


import {withTranslation } from 'react-i18next'
import {withRouter} from 'react-router-dom'

import SocialLogin from "../../SocialLogin";


class RegisterLPState extends Component {
constructor(props) {
super(props);
this.state = {
   districts:[],
   userLoggedIn: false,
      subjects:[],
};
}
//Check if user authenticated
 componentWillMount() {

}
 componentDidMount() {
	
	 
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        axios.post('/api/checkAuth', {username: username}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            if (res.data.status)
                this.setState({
                    userLoggedIn: true,
                    firstName: res.data.firstName
                });
        });
		
		this.loadDistricts(this.props.state_id)
		this.loadSubjects()
    }
	

loadDistricts(state_id)
{
   axios.post("/api/getDistricts", {state_id:state_id}).then(res2 => {
      this.setState({
         districts: res2.data.data
      });
    });
}

enroll= (token,name) => (e) =>{
   var rdir='/view/course/'+this.props.path;
   axios.post('/api/courses/enroll', {id: this.props.path,name:name}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + token
       }
   }).then(res => {
	 
		this.props.history.push(rdir)
		window.location.reload();		
		this.props.userLoggedIn(true, name);	
    });
   
}

loadSubjects()
{
   axios.get("/api/getSubjects", {}).then(res2 => {
      this.setState({
         subjects: res2.data.data
      });
    });
}


handleSubjectChange = (event) => {
  this.setState({teaching_subject: event.target.value});
  this.setState({value: event.target.value});
  
  this.setState({teaching_subject_oth: ''});
  
  
  if(event.target.value=='Other')
  {
	  
  }
}
	

userRegister = (e) => {
    e.preventDefault();
	var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')
	
    //let firstName=e.target.children[0].children[0].children[0].value;
    //let lastName=e.target.children[0].children[1].children[0].value;	
	//let username=e.target.children[1].children[0].children[0].value;
	//let mobile=e.target.children[1].children[1].children[0].value;
	//let state=e.target.children[2].children[0].children[0].value;
    //let district=e.target.children[2].children[1].children[0].value;
	//let current_institute=e.target.children[3].children[0].children[0].value;
    //let employment_sector=e.target.children[3].children[1].children[0].value;
    //let password=e.target.children[4].children[0].children[0].value;
    //let confirm_password=e.target.children[4].children[1].children[0].value;
	
	let utm_campaign=localStorage.getItem('utm_campaign');
   

	let firstName=e.target.firstName.value;
    let lastName=e.target.lastName.value;
	let username=e.target.ctrlEmail.value;
	let mobile=e.target.mobile.value;
	let state=this.props.state;
   let state_id=this.props.state_id;
   
   let utm_source=this.props.utm_source;
    let district=e.target.district.value;
	let current_institute=e.target.current_institute.value;
    let employment_sector='Government';
    let password=e.target.password.value;
    let confirm_password=e.target.confirm_password.value;
	
	let preferred_language=e.target.preferred_language.value;
	let gender=e.target.gender.value;
	let school_type=e.target.school_type.value;
	let teaching_subject=e.target.teaching_subject.value;
	let teaching_subject_oth=e.target.teaching_subject_oth.value;
	
	
	

   if(firstName==='' || lastName==='' || username ==='' || state === '' || district === '' || password === '' || confirm_password === '' || current_institute === '' || mobile === '')
   {
      toast.error('Please fill all required fields marked by *', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else if(password.length<5)
   {
      toast.warn('Password length cannot be less than 5 character', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   else if(password!==confirm_password)
   {
      toast.warn('Password and confirm password should be the same', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
    else if(mobile.length!==10)
   {
      toast.error('Please enter a valid mobile number ', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
   else if(gender==='' )
   {
      toast.error('Please select your gender', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
    else if(school_type==='' )
   {
      toast.error('Please select school you teach', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
    else if(teaching_subject==='' )
   {
      toast.error('Please select subject you teach', {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
    else if(teaching_subject_oth==='' && teaching_subject=='Other' )
   {
      toast.error('Please write subject you teach '+teaching_subject, {
         position: toast.POSITION.TOP_CENTER
     }); 
   }
   
   
   else
   {
	   
	   if(teaching_subject_oth!=='')
	   {
		   teaching_subject='Other:'+teaching_subject_oth
	   }
	   
	   
	   
    let isInstructor = 'true';
	 var rdir='/view/course/'+this.props.path;
    const user = {username, password, firstName, lastName,state,state_id,district,employment_sector,current_institute,language,mobile,utm_campaign,utm_source};

    axios.post("/api/users/register", user)
    .then(res => {
    if (res.data.status)
    {
			localStorage.setItem('token', res.data.token);
           localStorage.setItem('username', res.data.username);
           localStorage.setItem('firstName', res.data.firstName);
           
		   this.setState({
               loggedIn: true,
               open: false
           });
		   
        document.getElementById("register").reset();
        toast.success('You have successfully registered', {
         position: toast.POSITION.TOP_CENTER}); 
     
	//update profile
    axios.put('/api/users/profile', {state:state,district:district,current_institute:current_institute,employment_sector:employment_sector,language_preference:preferred_language,mobile:mobile,gender:gender,school_type:school_type,teaching_subject:teaching_subject,is_profile_complete:1}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + res.data.token
        }
    }).then(res => {
		
     });
	 
	 
		axios.post('/api/users/assign-course', {id: this.props.path,name:res.data.firstName,language:language}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + res.data.token
       }
   }).then(res => {
		window.conversion()
		this.props.history.push(rdir)
		window.location.reload();		
		this.props.userLoggedIn(true, res.data.firstName);	
    });
	
		
		 
    }
   
    })
	.catch(function (error) {
     console.log(error.response.status) // 401
     console.log(error.response.data.error) //Please Authenticate or whatever returned from server
   if(error.response.status==401){
     //redirect to login
	
	 toast.error('Registration Failed: '+error.response.data.message, {
         position: toast.POSITION.TOP_CENTER
     });
	 
   }
 })
	
	

   }
}

redirect = () => {
    this.props.history.push('/login');
}




rating (path) {
   this.props.history.push(path);
}

handleChange = (event) => {
   console.log(event.target.text)
   this.setState({value: event.target.value});
   this.loadDistricts(event.target.value)
}





socialLogin(name,email,accessToken,platform,signup_via)
{
	var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')
	let utm_campaign=localStorage.getItem('utm_campaign');
	 var rdir='/view/course/'+this.props.path;
   const user = {name: name, email: email,accessToken:accessToken,platform:platform,signup_via:signup_via,language:language,utm_campaign:utm_campaign}
   axios.post("/api/users/socialLogin", user)
   .then(res => {
       let err = ''
       if (res.data.token)
       {
           localStorage.setItem('token', res.data.token);
           localStorage.setItem('username', res.data.username);
           localStorage.setItem('firstName', res.data.firstName);
           this.setState({
               loggedIn: true,
               open: false
           });
		   
		
	axios.post('/api/users/assign-course', {id: this.props.path,name:res.data.firstName,language:language}, {
       headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + res.data.token
       }
   }).then(res => {
	 
	 toast.success('You have successfully enrolled', {
         position: toast.POSITION.TOP_CENTER}); 
		 
		 window.conversion()
		
		localStorage.setItem('rdir', rdir)
		this.props.history.push('/usr/UpdateInfo')
		window.location.reload();		
		this.props.userLoggedIn(true, res.data.firstName);	
    });
	

      }
       else {
           //Login failed, show error message
           localStorage.removeItem('token');
           localStorage.removeItem('username');
           err = res.data.message;
       }
       this.setState({
           err: err
       });
   });

}


render() {
const {t} = this.props
const path=this.props.path;

const responseFacebook = (response) => {
   this.socialLogin(response.name,response.email,response.accessToken,'https://graph.facebook.com/me?access_token=','Facebook')
 }

 const responseGoogle = (response) => {
   this.socialLogin(response.profileObj.name,response.profileObj.email,response.accessToken,'https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=','Google')
 }

const errMessage = !Array.isArray(this.state.err) ? (
	<div className='red-text center'>{this.state.err}</div>
	)
	: (this.state.err.map(err => {
	return (
	<div className='red-text center'>{err}</div>
	)
	}));

var code=<div><div class="card"><div class="card-body video-card"><div class="video-container-home"><iframe src="https://www.youtube.com/embed/8Wd2_d3FIeA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe></div></div></div></div>
if(!this.state.userLoggedIn)
{
	code=<div  class="signup">
	<h2 class="signup-page-title">ENROLL NOW</h2>
   
   <p class="divider-text"><span class="bg-white">{t('signup.alrady_have_an_account')}? <a class="signup-log-in" href="javascript:void(0)" onClick={this.redirect}> {t('signup.login')} »</a> </span></p>
   <p> {errMessage}</p>
   <div class="frm-popup">
   <form id="register" onSubmit = {this.userRegister}>
      <div class="form-row">
         <div class="col-md-6 form-group">
            <input type="text" id="firstName" class="form-control reginput" placeholder={t('signup.first_name')+'*'} />
         </div>
         <div class="col-md-6 form-group">
            <input type="text" id="lastName" class="form-control reginput" placeholder={t('signup.last_name')+'*'} />
         </div>
      </div>
       <div class="form-row">
	 <div class="col-md-6 form-group">
         <input type="email" id="ctrlEmail" class="form-control reginput" placeholder={t('signup.email')+'*'} />
      </div>
	  
	  <div class="col-md-6 form-group">
	  
	   <input type="text" id="mobile" class="form-control reginput"  maxlength="10"  placeholder={t('signup.mobile')+'*'}
		  onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }} 
		 />
		 
       
      </div>
	  
	  </div>
	  
	   <div class="form-row">
         <div class="form-group col-md-6 ">
            <input class="form-control reginput" id="password" type="password" placeholder={t('signup.create_password')+'*'} />
         </div>
         <div class="form-group col-md-6 ">
            <input class="form-control reginput" id="confirm_password" type="password" placeholder={t('signup.confirm_password')+'*'} />
         </div>
      </div>
	  
      <div class="form-row">
        
         <div class="form-group col-md-6">
            <select  class="form-control reginput" id="district" >
               <option value="">{t('signup.district')}*</option>
               {this.state.districts.map((row) => 
               <option key={row.district} value={row.district}>{row.district}</option>
               )}
            </select>
         </div>
		 <div class="form-group col-md-6">
		 
        <input type="text" class="form-control reginput" id="current_institute" placeholder={t('signup.institute_name')+'*'} />
		</div>
      </div>
	   
	 
	 <div class="form-row">
	 
	 <div class="form-group col-md-6 ">
        <select class="form-control reginput" id="school_type" value={this.state.school_type} onChange={(e) => this.setState({ school_type: e.target.value })} >
		<option value="">{t('signup.school_type')}*</option>                       
		<option value="Primary School">Primary School</option>
		<option value="Secondary School">Secondary School</option>
		<option value="NA">Not Applicable</option>
		
		
        </select>
		
		</div> 
	 <div class="form-group col-md-4">
		
     
		<select class="form-control reginput" id="preferred_language" value={this.state.language_preference} onChange={(e) => this.setState({ language_preference: e.target.value })} >
		<option value="">{t('signup.preferred_language')}*</option>                       
		<option value="en">English</option>
		<option value="hi">हिन्दी</option>
        </select>
		
      
	</div> 
		
   <div class="form-group col-md-2">
		
		<select class="form-control reginput" id="gender" value={this.state.gender} onChange={(e) => this.setState({ gender: e.target.value })} >
		<option value="">{t('signup.gender')}*</option>                       
		<option value="Male">Male</option>
		<option value="Female">Female</option>
		<option value="Other">Other</option>
        </select>
	
		</div>

		
		
		
      
	</div>  
	
	  
    <div class="form-row">
		
   <div class="form-group col-md-6">
		
		 
		<select class="form-control reginput" id="teaching_subject" value={this.state.teaching_subject} onChange={this.handleSubjectChange} >
		<option value="">{t('signup.subject')}*</option>                       
		
		 {this.state.subjects.map((row) => 
               <option key={row.subject} value={row.subject}>{row.subject}</option>
               )}
			   <option value="Not Applicable">Not Applicable</option>	
			<option value="Other">Other</option> 
					
        </select>
	
		</div>

		<div class="form-group col-md-6 ">
		
	
		<div class={this.state.teaching_subject=='Other'?'':'dnone'}>
     	 
		<input type="text" placeholder= {t('signup.subject_oth')+'*'} id="teaching_subject_oth" class="form-control reginput" value={this.state.teaching_subject_oth} onChange={(e) => this.setState({ teaching_subject_oth: e.target.value })} maxlength="25" />
		</div>
		
		
		</div> 
      
	</div>   
	  
	  
      <div class="form-group">
      </div>
      <div class="form-group">
      </div>
      <div class="form-group">
         <button type="submit" class="btn btn-primary btn-block"> {t('signup.create_account_and_enroll')}  </button>
      </div>
      <div class="row">
         <div class="col-md-12">
            <p class="divider-text">
               <span class="bg-white">{t('signup.or_create_an_with_enroll')}</span>
            </p>
            <div class="form-group row">
               <div class="col-md-12 text-center">
                 
               <SocialLogin path="bihar" history={this.props.history} />
                 
               </div>
            </div>
         </div>
      </div>
      <p class="small">  {t('signup.registration_disclaimer1')}  
         <a href="terms-and-condition" target="_blank"> {t('navigation.terms_of_services')} </a>  {t('portal.and')} 
         <a href="privacy-policy" target="_blank"> {t('navigation.privacy_policy')} </a>.
		 
		 
      </p>
   </form>
	</div>
	</div>
}


return (
<div>

{code}
   
</div>
)
}
}
//export default withTranslation()(RegisterLP);
export default withRouter(withTranslation()(RegisterLPState));