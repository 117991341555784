import React, {Component} from 'react'
import axios from "./axios.js";
import { toast } from 'react-toastify';
import $ from 'jquery'
import Moment from "moment";
import EasyEdit from 'react-easy-edit';
import { withTranslation } from 'react-i18next'

class Logout extends Component {
constructor(props) {
super(props);

}



componentDidMount() {

    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.setState({
        loggedIn: false,
        open: false
    });
  //  this.props.history.push('/')
  this.props.history.push('/login')
  window.location.reload();
    this.props.userLoggedIn(false, '');
}
render() {
    return (
        <div>
</div>
)
}
}
export default Logout