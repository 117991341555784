import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from "./Spinner.js";
import SocialLogin from "./SocialLogin";
import { toast } from 'react-toastify';
import { LoginSvc,setSession } from '../services/userService.js';
import {emailInFormat } from '../utils/validators';

function Login(props) {
const { t} = useTranslation();

const [data,setData] = useState({ username:"", password:""});
const [error,setError] = useState({ username:"Email is required!", password:"Password is required!"});
const [isLoading, setLoading] = useState(false);
const {username,password} = data;

const changeHandler = e => {
  setData({...data,[e.target.name]:[e.target.value][0]});
}

const submitHandler = e => {
  e.preventDefault();

  var rdir=localStorage.getItem('rdir')==null?'/dashboard':localStorage.getItem('rdir');
  if (data.username=='' && data.password=='') {
 }

 else if (!emailInFormat(data.username)) {
   setError({username:"Invalid Email"})
}

 else
 {
   setLoading(true);
   const user = {username: username, password: password}
   LoginSvc(user)
   .then(res => {
      if (res.data.token)
      {
         if(res.data.profileInfo==false)
      {
         rdir='/usr/UpdateInfo'	
      }
    
    setSession({token:res.data.token,username:res.data.username,firstName:res.data.firstName,_id:res.data._id})
   
    console.log(rdir)
    props.history.push(rdir)
   window.location.reload();
      }
    }).catch(function (error) {
      setLoading(false)
      if(error.response.status==401){
        
        toast.error('Login Failed: '+error.response.data.message, {
             position: toast.POSITION.TOP_CENTER
         });
        
       }
})
}
}


 if (isLoading) {
   return <Spinner />;
 }
  return (
    <div>
      <div class="content register-bg">
      <div class="container">

  
      <div class="row">
    <div class="col-md-4">
    </div>

    <div class='col-md-4 signup'>
   
<h2 class="signup-page-title">{t('login.welcome_back')}!</h2>
<p class="signup-page-p">
 {t('login.first_time_user')} <a href="/register" > {t('login.create_an_account')}
 »</a>
</p>
<p class="divider-text"><span class="bg-white">{t('login.login')}</span></p>
<form id="register" class="requires-validation" onSubmit = {submitHandler} novalidate="true">
 <div class="form-group">
    <label class="reg-label">{t('login.email_address')}</label>
    <input type="email" required id="ctrlEmail" name="username" value={username} onChange={changeHandler} class="form-control reginput" placeholder="" />
   <div class="invalid-feedback">
  {error.username}
 </div>

 </div>
 <div class="form-group">
    <label class="reg-label">{t('login.password')}</label>
    <input type="password" required id="ctrlPassword" name="password" value={password} onChange={changeHandler} class="form-control reginput" placeholder="" />
    <div class="invalid-feedback">
    {error.password}
 </div>
 </div>
 <div class="form-row">
    <div class="col form-group">
       <small><a href="reset-password" >
       {t('login.forgot_password')}?
       </a></small>
    </div>
    <div class="col form-group">
       <button type="submit" class="btn btn-primary btn-block"> {t('login.login')}  </button>
    </div>
 </div>

</form>
<div class="row">
    <div class="col-md-12">
       <p class="divider-text">
          <span class="bg-white"> {t('login.or_signin_with')}</span>
       </p>
       <SocialLogin path="" history={props.history} />
    </div>
 </div>
    <br />
</div>

    
<div class='col-md-3'> 
</div>
</div>

     
  </div>
 
</div>
</div>

  );
}

export default Login;