import axios from 'axios';
var instance = axios.create({
  //baseURL: 'https://api.educately.org/',
  baseURL: '/',
  //baseURL: 'https://educately.herokuapp.com/',
  timeout: 1000,
  headers: {'X-Custom-Header': 'foobar'}
});


export default instance