import React, { Component } from "react";
import Moment from "moment";
import axios from "../axios.js";
import { withTranslation, Trans } from 'react-i18next'
import { toast } from 'react-toastify';
import $ from "jquery";

class CourseSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: []
    
      
    };
  }



   
  async componentWillMount() {
   document.title = "Conclusion and Survey"
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language') 
    axios.get('/api/courses/'+this.props.match.params.id+'?language='+language, {})
            .then(res2 => {
                this.setState({
                  course_module: res2.data.module,
                  course: res2.data,
                  
                });
                   
                    });


                    
                
}



feedback_update = (e) => {
    e.preventDefault();
	let usr_feedback = {};
    let is_validate = true;
	


	//get radiobutton and text field data - single valued
    const formData = new FormData(e.currentTarget);
    for (let [key, value] of formData.entries()) {

        usr_feedback[key] = value
    }


//handle checkbox
var checkbox = $("#userForm").find("input[type=checkbox]");
$.each(checkbox, function(key2, val2) {
  
	if($(val2).is(':checked'))
	{
		console.log($(val2).attr('name')+':'+$(val2).attr('value'))
		if(usr_feedback[$(val2).attr('name')])
		{
			if(usr_feedback[$(val2).attr('name')].indexOf($(val2).attr('value')) === -1)
			{
				usr_feedback[$(val2).attr('name')]=usr_feedback[$(val2).attr('name')]+','+$(val2).attr('value')
			}
			
		}
		else
		{
			usr_feedback[$(val2).attr('name')]=$(val2).attr('value')
		}
	}
});	


//validation
for (let [key, value] of Object.entries(usr_feedback)) {

        if (value === '') {
            toast.error('Please respond to question '+key, {
                position: toast.POSITION.TOP_CENTER
            });
            is_validate = false;
           break;
        }
    }
	

    if (is_validate) {
        const token = localStorage.getItem('token');
        axios.post('/api/users/feedback/' + this.props.match.params.id, usr_feedback, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {

            this.props.history.push('/view/course/' + this.props.match.params.id)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        });
    }
}



render() {
const {t} = this.props
   

     

     return (
       <div>
       <div class="page-header">
          <div class="container">
             <div class="row">
                <div class="col-md-6">
                   <ol class="breadcrumb pull-left">
                      <li><a href="/">{t('navigation.home')}</a></li>
                      <li><a href={"/dashboard"}>{t('navigation.my_courses')}</a></li>
                      <li><a href={"/view/course/"+this.state.course.slug}>{this.state.course.name}</a></li>
                      <li class="active">{t('heading.conclusion')} – {this.state.course.name}</li>
                   </ol>
                </div>
                <div class="col-md-6 pull-right">



                </div>
             </div>
             <div class="row">
                <div class="col-md-8">
                  
               
                 
                </div>
                <div class="col-md-4 text-right">
              
              </div>

             </div>

             <div class="row">
                <div class="col-md-8">
                <h3 align="left">{t('heading.conclusion')} – {this.state.course.name}</h3>
                   <br />
                </div>
              

                </div>

          </div>
       </div>
       
      


       <div class="content content-white">
          <div class="container">
            

<div class="row">
              <div class="col-md-11">
                  <div dangerouslySetInnerHTML={{__html: this.state.course.conclusion}} />
              </div>
          </div>
		  
         
		 
		 <form id="userForm" onSubmit = {this.feedback_update}>
     
     
	
	  
	  <div dangerouslySetInnerHTML={{__html: this.state.course.feedback_form }} />
	  
	  
	  </form>
          

             
          </div>
       </div>
       




    
       </div>
     )
 };
}
export default withTranslation()(CourseSurvey);
