import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import axios from "./axios.js";
class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageContent:[]
        };
    }

    componentDidMount() {
      
}
async componentWillMount() {
	var language=localStorage.getItem('language')==null?'en':localStorage.getItem('language')     
    axios.get("/api/static_page?p=HowItWorks&language="+language, {}).then(res2 => {
      this.setState({
        pageContent: res2.data.data.content
      });
    });
  }
    

render() {
    const {t} = this.props
     return (
        <div>
        <div class="page-header">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1 class="brcheading">{t('navigation.media')}</h1>
            </div>
            <div class="col-md-4">
               <ol class="breadcrumb pull-right">
                  <li><a href="/">{t('heading.home')}</a></li>
                  <li class="active">{t('navigation.media')}</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
  

<div class="content content-white" >
   <div class="container">
      <div class="row">
         <div class="col-md-12">
				
	<div class="row">
			<div class="col-md-4">
    		<div class="card mb">
              <img class="card-img-top media-card-img-top" src="https://images.yourstory.com/cs/5/a09f22505c6411ea9c48a10bad99c62f/Imagenugm-1640194483636.jpg?fm=auto&ar=2:1&mode=crop&crop=faces&w=1270" alt="Card image cap" />
              <div class="card-body mb">
			  <div style={{"height":"250px"}}>
                <h5 class="card-title">Sex education 101: How this NGO is equipping teachers, educators to talk to adolescents about their sexual health</h5>
                <p class="card-text" >The Population Foundation of India’s course on adolescent reproductive and sexual health aims at training educators to provide accurate, age-appropriate and culturally suitable information to students..</p>
               </div> 
			  <div class="float-left">
				<p class="small">
				<img src="https://images.yourstory.com/logos/svg/logo_socialstory.svg" height="20px" /><br />
				Published: December 23, 2021
				
				
					</p>
				</div>
				<div class="float-right">
				<a target="_blank" href="https://yourstory.com/socialstory/2021/12/sex-education-population-foundation-teacher-training-ngo/amp"  class="btn btn-primary btn-sm">Read Article</a>
              
				</div>
				
			  
			  </div>
            </div>
            </div>
			
			<div class="col-md-4">
    		<div class="card mb">

              <img class="card-img-top media-card-img-top"  src="https://media.assettype.com/TNIE%2Fimport%2F2021%2F12%2F15%2Foriginal%2FTurning1.jpg" alt="Card image cap" />
              <div class="card-body mb">
			  <div style={{"height":"250px"}}>
                <h5 class="card-title">Turning the tide on sex education</h5>
                <p class="card-text" style={{"min-height": "250px"}}>Educator Sathish Kumar opens up about the importance of good sexual education and how the Population Foundation of India’s platform, Educately, can help educators with this..
				</p>
				</div>
				<div class="float-left">
				<img src="https://images.assettype.com/newindianexpress/2024-01/513ad66b-9f6c-4c96-a3d5-ea0e785580a6/Long_Light_Mode_500_Height.png" height="20px" /><br />
				<p class="small">Published: December 15, 2021
				
					</p>
				</div>
				<div class="float-right">
				<a target="_blank" href="https://www.newindianexpress.com/cities/chennai/2021/dec/15/turning-the-tide-on-sexeducation-2395551.html"  class="btn btn-primary btn-sm">Read Article</a>
				</div>
                
              </div>
            </div>
            </div>
			
			
			<div class="col-md-4">
    		<div class="card mb">
              <img class="card-img-top media-card-img-top" src="https://static.theprint.in/wp-content/uploads/2018/08/242618264-e1536138673952.jpg" alt="Card image cap" />
              <div class="card-body mb">
			  <div style={{"height":"250px"}}>
                <h5 class="card-title">Sex education is not family planning. That’s why we built a tool for Indian teachers first</h5>
                <p class="card-text" >The new National Education Policy has the sex education chapter missing. And Indian textbooks don’t go beyond reproductive organs and puberty..</p>
               </div> 
			  <div class="float-left">
				<p class="small">
				<img src="https://static.theprint.in/wp-content/uploads/2017/08/logo_800x149_transp-1.png" height="20px" /><br />
				Published:May 01, 2021
				
				
					</p>
				</div>
				<div class="float-right">
				<a target="_blank" href="https://theprint.in/opinion/sex-education-is-not-family-planning-thats-why-we-built-a-tool-for-indian-teachers-first/648914/"  class="btn btn-primary btn-sm">Read Article</a>
              
				</div>
			  </div>
            </div>
            </div>

            <div class="col-md-4">
    		<div class="card mb">
              <img class="card-img-top media-card-img-top" src="https://th-i.thgim.com/public/incoming/hd3yff/article67269000.ece/alternates/LANDSCAPE_1200/17GNRAO-DIGITAL%20CLASS-01%20%281%29.JPG" alt="Card image cap" />
              <div class="card-body mb">
			  <div style={{"height":"250px"}}>
                <h5 class="card-title">Educately: A program to teach teachers about sexual health</h5>
                <p class="card-text" >Three decades later, the subject of sex remains taboo for many teachers in the country. Adolescents still have many questions unanswered — for instance, the changes they undergo during puberty. Unlike 30 years ago, the digital age has..</p>
               </div> 
			  <div class="float-left">
				<p class="small">
				<img src="https://www.thehindu.com/theme/images/th-online/h-circle-black-white-new.svg" height="20px" /><br />
				Published: September 05, 2023
				
				
					</p>
				</div>
				<div class="float-right">
				<a target="_blank" href="https://www.thehindu.com/education/educately-teachers-day-sex-education/article67268988.ece"  class="btn btn-primary btn-sm">Read Article</a>
              
				</div>
			  </div>
            </div>
            </div>


</div>	
			
			
				
				
				 

		   </div>
      </div>
   </div>
</div>  
  

  
   
   
    </div>
         )
         }
         }

export default withTranslation()(Media);